import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { gapi } from "gapi-script";
import * as Yup from "yup";
import { loginApi, signupApi, singupGoogleApi } from "./api";
import { useFormik } from "formik";

export const initDataSignup = {
  email: "",
  password: "",
  isRemember: false,
  registerClick: false,
};

export const validationSchemaSignup = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const CLIENT_ID =
  "590637473940-uh4sjrhpbhb170dsifkbv0em4k2q5jb4.apps.googleusercontent.com";

export default function useSignupController({
  setLoginShow,
  setForgotShow,
  setShow,
  setShowMenu
}) {
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.auth);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const formikprops = useFormik({
    enableReinitialize: true,
    initialValues: initDataSignup,
    validationSchema: validationSchemaSignup,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (values.registerClick) {
        signupHandler(values, resetForm, setForgotShow);
      } else {
        loginHandler(values, resetForm);
      }
      // resetForm(initData);
    },
  });

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const responseGoogle = (response, values) => {
    if (!profileData?.isAuth) {
      singupGoogleApi(response?.profileObj, dispatch, setShow, setShowMenu);
    }
  };

  const signupHandler = (values, resetForm, setForgotShow) => {
    setLoading(true);
    const payload = {
      email: values?.email,
      password: values?.password,
    };

    signupApi(payload, setLoading, () => {
      setShow(false);
      setShowMenu(false)
      setForgotShow({
        title: "Email Verification Sent",
        body: "Please check email inbox (including spam folder) for verification link to verify your account.",
      });
      resetForm();
      setLoading();
    });
  };

  const loginHandler = (values, resetForm) => {
    setLoading(true);
    const payload = {
      email: values?.email,
      password: values?.password,
    };

    loginApi(payload, dispatch, setShow, setLoading,setShowMenu);
  };

  return {
    dispatch,
    history,
    responseGoogle,
    signupHandler,
    loginHandler,
    loading,
    formikprops,
  };
}
