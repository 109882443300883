import {
  faArrowLeft,
  faArrowRight,
  faArrowRightLong,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // FiberManualRecord,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import SectionTitle from "../../common/SectionTitle";
import { TimeLineSection } from "../../common/timeLine/timelineSection";
import {
  // filterSearchAction,
  scrollHeightAction,
} from "../../commonRedux/reduxForLocalStorage/action";
import {
  colorCodeList,
  colorPalate,
  colorShape,
  serviceStyle,
} from "../../utility/customColor";
import {
  expertProfessorList,
  featureList,
  gerFilterDistinctDataStat,
  scholarsList,
  serviceIconList,
  // serviceList,
  studntReviewList,
  topInstituteList,
} from "./helper";

// images
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import groupe01 from "../../assets/images/group01.png";
import groupe02 from "../../assets/images/group02.png";
import groupe03 from "../../assets/images/group03.png";
import groupe04 from "../../assets/images/group04.png";
import heroChatting from "../../assets/images/hero-chatting.png";
import heroPerson from "../../assets/images/hero-person.png";
import heroShape from "../../assets/images/hero-shape.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import quotationIcon from "../../assets/images/icon/quotation.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import reviewImg from "../../assets/images/review.png";
import footerRightShape from "../../assets/images/rightShape.png";
import scholershipLogo from "../../assets/images/scholership-logo.png";
import hmAboutImg from "../../assets/images/section01.png";
import reviewShapeImg from "../../assets/images/shape/shape02.png";
import reviewShape from "../../assets/images/shape/shape03.png";
import instituteShape from "../../assets/images/shape/shape04.png";
// import titleShape from "../../assets/images/title-shape.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";
import ViewModal from "../../common/ViewModal";
import SignIn from "../auth/SignIn";
import AutoSugession from "./autoSugession";

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const [serviceList, setServiceList] = useState([]);

  // sign in modal
  const [show, setShow] = useState(false);
  const [, setLoginShow] = useState(false);
  const [, setForgotShow] = useState(false);

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  useEffect(() => {
    const cb = (data) => setServiceList((oldData) => [...oldData, data]);

    gerFilterDistinctDataStat(cb);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const leadingScholarshipData = [
    {
      name: "PHD",
      route: "/filter?degree=phd",
      img: groupe01,
      // description:
      //   "If you are a graduate and willing to proceed furthermore, one of the best things you can do is to pursue an overseas master's degree...",
    },
    {
      name: "Masters",
      route: "/filter?degree=master",
      img: groupe02,
      // description:
      //   "If you are a graduate and willing to proceed furthermore, one of the best things you can do is to pursue an overseas master's degree...",
    },
    {
      name: "Bachelor's",
      route: "/filter?degree=bachelor",
      img: groupe03,
      // description:
      //   "As a citizen of a global village who doesn’t want to expand their horizon with a bachelor's degree from abroad...",
    },
    {
      name: "Diploma and Others",
      route: "/filter?attendance=online",
      img: groupe04,
      // description:
      //   "Have you ever considered any other degrees apart from bachelors and masters...",
    },
  ];

  return (
    <>
      <form>
        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* hero section */}
          <div className="hero-section bgOne">
            <div className="hero-section-content">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="hero-section-body">
                    <h2 className="h1 darkText">
                      Find Your Desired Program Or University
                    </h2>

                    <div className="hero-form-wrapper-section ">
                      <div className="hero-form-wrapper">
                        <AutoSugession />
                      </div>
                    </div>

                    <ul className="hero-checklist">
                      <li>
                        <div className="icon one">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <div className="txt">IELTS preparation</div>
                      </li>
                      <li>
                        <div className="icon two">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <div className="txt three">24/7 Support</div>
                      </li>
                      <li>
                        <div className="icon three">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <div className="txt">Essential Guidelines</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-scholership-img">
                      <img src={scholershipLogo} alt="scholify" />
                    </div>
                    <div className="hero-section-shape">
                      <img src={heroShape} alt="scholify" />
                    </div>
                    <div className="hero-section-person-img">
                      <img src={heroPerson} alt="scholify" />
                    </div>
                    <div className="hero-section-chatting-img">
                      <img src={heroChatting} alt="scholify" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* services section */}
          <div className="service-card-info res-app-width">
            <div className="card-info-body app-width">
              <ul className="service-card-list">
                {serviceList?.length > 0 && (
                  <>
                    {serviceList?.map((item, index) => {
                      return (
                        <li
                          className="single-service-list"
                          style={serviceStyle[index]}
                          key={index}
                        >
                          <div
                            className="single-service-img"
                            style={{
                              background: `${colorCodeList[index]}`,
                            }}
                          >
                            <img src={serviceIconList[index]} alt="scholify" />
                          </div>
                          <div className="single-service-body">
                            <h2>{item.title}</h2>
                            <p>{item.subtitle}</p>
                          </div>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
          {/* top scholership institute section */}
          <div className="top-scholership-institute-section">
            <div className="top-scholership-institute app-width">
              <SectionTitle title="Top Admission Opportunity" />
              <div className="top-scholership-institute-list default-slick-arrow">
                <div className="">
                  <Slider {...settings}>
                    {topInstituteList?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="single-profile-info single-topics-info">
                            <div className="single-topics-info-link">
                              <a href={`${item?.url}`}> </a>
                            </div>
                            <div className="profile-info-img scholarship-info-img profile-info-img-radius">
                              <img src={item?.img} alt="scholiguide" />
                            </div>
                            <div className="profile-info-txt text-default">
                              <h2 className="h4 h4-bold greenDark">
                                {item?.instituteName}
                              </h2>
                              <h3 className="button-type">
                                Acceptance Rate
                                <b>
                                  {item?.acceptanceRate
                                    ? ` ${item?.acceptanceRate}%`
                                    : "N/A"}
                                </b>
                              </h3>
                              <div className="more-info-link">
                                <Link to={item?.url}>Learn More</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="top-scholership-institute-list-shape-right">
                  <img src={instituteShape} alt="scholiguide" />
                </div>
                <div className="top-scholership-institute-list-shape-left">
                  <img src={instituteShape} alt="scholiguide" />
                </div>
              </div>
            </div>
          </div>
          {/* latest scholership section */}
          <div className="latest-scholership-section">
            <div className="latest-scholership-content app-width">
              <SectionTitle title="Potential Scholarship Opportunity" />
              <div className="latest-scholership-list">
                <div className="row">
                  {leadingScholarshipData?.map((item, index) => {
                    return (
                      <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                        <div className="single-profile-info single-topics-info">
                          <div className="profile-info-img profile-info-img-radius">
                            <img src={item?.img} alt="scholiguide" />
                          </div>
                          <div className="profile-info-txt">
                            <h2 className="h4 h4-bold">{item?.name}</h2>
                          </div>
                          <div className="single-topics-info-backpart bgBlue">
                            <div className="single-topics-info-backpart-content">
                              <div></div>
                              <div className="single-topics-info-backpart-txt">
                                <h2>{item?.name}</h2>
                                {/* <p>{item?.description}</p> */}
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    history.push(item?.route);
                                  }}
                                  className="btn btn-large btn-white"
                                >
                                  See All
                                  <span>
                                    <FontAwesomeIcon icon={faArrowRightLong} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* hm-about-section */}
          <div className="hm-about-section bgOne">
            <div className="hm-about-content app-width">
              <div className="row align-items-center">
                <div className="col-lg-6 order-lg-1 order-2">
                  <div className="timeline-scholership-txt section-text">
                    <h2 className="title">Welcome To “Scholiguide”</h2>
                    <h3 className="sub-title">Meet your personal guide</h3>
                    <p className="para hm-about-para">
                      Getting anxious about your academic future? We are here
                      for you! Our goal is to guide you through all the way to
                      your desired destination. Regardless of the hour, you are
                      always welcome to knock on our door.
                    </p>
                    <h3 className="sub-title">Substantial data source</h3>
                    <p className="para">
                      Be advised to explore our website for every updated
                      information and guidelines regarding scholarship,
                      admission, cost, intake schedule and so on for free of any
                      charges.
                    </p>
                    <div>
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-mid btn-ligtGreen"
                          style={{ marginRight: "20px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("/contact");
                          }}
                        >
                          Get Free Consultation
                          <span>
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-mid btn-blackWhite"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(true);
                          }}
                        >
                          Sign Up Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-1">
                  <div className="hm-about-content-img">
                    <img src={hmAboutImg} alt="scholiguide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* hm-student-review section */}
          <div className="hm-student-review-section d-none">
            <div className="hm-student-review-content app-width">
              <SectionTitle title="Feedback From Our Successful Users" />
              <div className="hm-student-review-inner bgOne">
                <div className="hm-student-review-quotation">
                  <img src={quotationIcon} alt="scholiguide" />
                </div>
                <div className="row">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="hm-student-review-inner-txt-content">
                      <div
                        id="sReviewSlider"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {studntReviewList.map((itm, idx) => {
                            return (
                              <div
                                className={
                                  itm?.isActive
                                    ? "carousel-item active"
                                    : "carousel-item"
                                }
                                key={idx}
                              >
                                <div className="single-review">
                                  <div className="single-review-img">
                                    <img src={itm?.img} alt="scholiguide" />
                                  </div>
                                  <div className="single-review-comment">
                                    <h2>{itm?.title}</h2>
                                    <p>{itm?.comment}</p>
                                  </div>
                                  <div className="single-review-comment-author">
                                    <h3>{itm?.sName}</h3>
                                    <p>{itm?.university}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#sReviewSlider"
                          role="button"
                          data-slide="prev"
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#sReviewSlider"
                          role="button"
                          data-slide="next"
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1">
                    <div className="hm-student-review-inner-img">
                      <img src={reviewImg} alt="scholiguide" />
                      <div className="hm-student-review-inner-img-shape">
                        <img src={reviewShapeImg} alt="scholiguide" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hm-student-review-inner-shape">
                  <img src={reviewShape} alt="scholiguide" />
                </div>
              </div>
            </div>
          </div>
          {/* hm-feature section */}
          <div className="hm-feature-section">
            <div className="hm-feature-content app-width">
              <SectionTitle title="Let Us Help You Even More" />
              <div className="hm-feature-list">
                <div className="row">
                  {featureList.map((itm, index) => {
                    return (
                      <div
                        key={index}
                        className={`single-profile-info feature-profile-info col-md-6 ${
                          itm?.id === 1 || itm?.id === 2 ? "padTopRemove" : " "
                        } ${itm?.id % 2 === 0 ? "even" : "odd"} ${
                          itm?.id === featureList?.length ||
                          itm?.id === featureList?.length - 1
                            ? "borderRemove"
                            : " "
                        }`}
                      >
                        <div className="profile-info-img feature-info-img">
                          <img src={itm?.img} alt="scholiguide" />
                        </div>
                        <div className="profile-info-txt feature-info-text">
                          <h2>{itm?.title}</h2>
                          <h3 className="greenDark">{itm?.subtitle}</h3>
                          <p>{itm?.describe}</p>
                          {itm?.isBtn && (
                            <div>
                              <Link
                                to={itm?.url}
                                className="btn btn-mid btn-blackWhite"
                              >
                                {itm?.btnTitle}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* scholars-list section */}
          <div className="scholars-list-section bgOne">
            <div className="scholars-list-section-content app-width">
              <SectionTitle title="Our Successful Advisors" />
              <div className="scholars-list">
                <ul>
                  {scholarsList.map((itm, index) => {
                    return (
                      <li key={index} className="single-profile-info">
                        <div className="profile-info-img">
                          <img src={itm?.img} alt="scholiguide" />
                          <div
                            className="profile-info-img-colorbox"
                            style={{
                              background: `${colorPalate[index]}`,
                            }}
                          ></div>
                          <div className="profile-info-img-shape">
                            <img src={colorShape[index]} alt="scholiguide" />
                          </div>
                        </div>
                        <div className="profile-info-txt">
                          <h2 className="h4 h4-bold greenDark">{itm?.name}</h2>
                          <p className="darkBlack">{itm?.education}</p>
                          <p className="darkBlack">{itm?.email}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="scholars-list-footer">
                <button
                  type="button"
                  className="btn btn-large btn-default"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Be One Of Them
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* timeline-scholership section */}
          <div className="timeline-scholership-section">
            <div className="timeline-scholership-content app-width">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="timeline-scholership-txt section-text">
                    <h2 className="title">Intake Schedule Around The World</h2>
                    <h3 className="sub-title">When should you apply</h3>
                    <p className="para">
                      It is quite essential to know when you should start the
                      process for your admission as different countries have
                      different intake schedules. Here you can easily sort the
                      timeline for any particular country.
                    </p>
                    <div className="btn-group">
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-mid btn-ligtGreen"
                          style={{ marginRight: "20px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("/contact");
                          }}
                        >
                          Get Free Consultation
                          <span>
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-mid btn-blackWhite"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(true);
                          }}
                        >
                          Sign Up Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="timeline-scholership-graph">
                    <TimeLineSection />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* expert-professor section */}
          <div className="expert-professor-section">
            <div className="expert-professor-content app-width">
              <SectionTitle title="Renowned professors around the world" />
              <div className="expert-professor-list">
                <div className="row">
                  {expertProfessorList.map((itm, index) => {
                    return (
                      <div
                        key={index}
                        className="single-profile-info col-lg-3 col-md-4 col-sm-6"
                      >
                        <div className="profile-info-img">
                          <img src={itm?.img} alt="scholiguide" />
                        </div>
                        <div className="profile-info-txt">
                          <h2 className="h4 h4-bold greenDark">{itm?.name}</h2>
                          <p className="darkBlack">{itm?.education}</p>
                          {/* <p className="darkBlack">{itm?.email}</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* contact with counselor section */}
          <div style={{ marginTop: "16px" }}  className="connect-counselor-section app-width">
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Get in touch with our experienced counselors to make your dream
                come true
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-large btn-darkOrange"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Get your Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* sign in modal */}
        <ViewModal
          size="lg"
          title={` `}
          backdrop="true"
          classes="default-modal preview-modal auth-modal"
          show={show}
          onHide={() => setShow(false)}
          isCloseIcon={false}
          centered={true}
        >
          <SignIn
            setLoginShow={setLoginShow}
            setForgotShow={setForgotShow}
            setShow={setShow}
          />
        </ViewModal>
      </form>
    </>
  );
}
