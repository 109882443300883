import { createSlice } from "@reduxjs/toolkit";

const initState = {
  appName: "Scholify",
  scrollHeight: 0,
  isDropdown: false,
  filterSearch: {
    search: "",
    courseType: "",
    department: "",
    subject: "",
    course: "",
    city: "",
    institute: "",
    tutions: "",
    courseTime: "",
  },
  locationPath: "",
  myList: {},
  myNotifications: {},
  notificationsCount: 0,
};

export const localStorageSlice = createSlice({
  name: "localStorage",
  initialState: initState,
  reducers: {
    setAppName: (state, action) => {
      const { payload } = action;
      state.appName = payload;
    },
    setScrollHeight: (state, action) => {
      const { payload } = action;
      state.scrollHeight = payload;
    },
    setFilterSearch: (state, action) => {
      const { payload } = action;
      state.filterSearch = payload;
    },
    setIsDropdown: (state, action) => {
      const { payload } = action;
      state.isDropdown = payload;
    },
    setLocationPath: (state, action) => {
      const { payload } = action;
      state.locationPath = payload;
    },
    setMyList: (state, action) => {
      const { payload } = action;
      state.myList = payload;
    },
    setMyNotifications: (state, action) => {
      const { payload } = action;
      state.myNotifications = payload;
    },
    setNotificationsCount: (state, action) => {
      const { payload } = action;
      state.notificationsCount = payload;
    },
  },
});
