import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoginAction } from "../commonRedux/auth/action";

export default function EmailVerification() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const verifyMail = async (token, dispatch, setLoading, history) => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://scholify-backend.herokuapp.com/api/auth/verify-mail",
        {
          token,
        }
      );

      if (res?.status === 200) {
        dispatch(setLoginAction(res?.data?.user));
        history.push("/");
        toast.success(res?.data?.message || "Signup successfully", {
          toastId: 1234,
        });
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.warn(err?.response?.data?.message, {
          toastId: 1234,
        });
      }

      history.push("/");
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = search.split("?_v=")[1];
    if (token) {
      verifyMail(token, dispatch, setLoading, history);
    }
  }, [search, dispatch, history]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
