import axios from "axios";

export const postUnseenMessage = async (payload, setter, setLoading) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.post(
      "/api/notification/notificationListing",
      payload
    );

    if (res?.status === 200) {
      setLoading && setLoading(false);
      setter(res?.data?.data || 0);
    }
  } catch (err) {
    setLoading && setLoading(false);
    setter(0);
  }
};
