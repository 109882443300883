import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Instagram, Twitter, YouTube } from "@mui/icons-material";
import { Backdrop, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import FormikSelect from "../../common/FormikSelect";
import SectionTitle from "../../common/SectionTitle";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";
import {
  visProcessingBgColor,
  visProcessingColor,
} from "../../utility/customColor";
import { customStyles } from "../../utility/selectCustomStyle";
import { capitalizedWord } from "../../utility/utilityFunc";
import {
  getVisaProcessingData,
  getVisaProcessingDistintCountry,
} from "./helper";
import SingleVisaProcessing from "./singleVisaProcessing";

// images
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
import heroShape from "../../assets/images/shape/shape06.png";
import twitterImg from "../../assets/images/twitter.png";
import heroVisa from "../../assets/images/welcome-banner/06.png";
import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";

export default function VisaProcessing() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const [coursePlace, setCoursePlace] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [visaProcessingData, setVisaProcessingData] = useState();

  // const { filterSearch } = useSelector((state) => {
  //   return state.localStorage;
  // }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    getVisaProcessingDistintCountry((data) => {
      setCoursePlace(
        data?.map((item, index) => {
          return {
            value: item,
            label: capitalizedWord(item),
          };
        })
      );
    });
  }, []);

  const initData = {
    courseType: "",
    coursePlace: "",
    language: "",
    emailSubscribe: "",
  };

  const { values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      resetForm(initData);
    },
  });

  const easyStepProcedure = [
    {
      title: "Book Appointment And Complete Online Application",
      txt: "Booking an appointment in time is an important step. You will need to make an appointment via the online system at the visa service center. Our team will guide you tracking the appropriate visa section. Following that fFill out a comprehensive application form with your personal, academic, and other imperative details",
    },
    {
      title: "Upload Essential Documents",
      txt: "Submit crucial documents like your passport, acceptance letter, financial statements, and any specific requirements for your chosen country.",
    },
    {
      title: "Get Ready for Your Visa",
      txt: "If needed, prepare for the visa interview confidently using our expert tips and guidance.",
    },
  ];

  return (
    <>
      <form>
        {loading ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}

        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* visa-procee-hero section */}
          <div className="hero-section about-hero-section  faq-hero-section visa-hero-section bgOne">
            <div className="hero-section-content visa-hero-content">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <div className="hero-section-body visa-process-hero-section-body">
                    <h2 className="h1">Check Your Visa Requirement</h2>
                    <div className="hero-form-wrapper">
                      <ul className="hero-form-list">
                        <li>
                          <div className="input-field-main">
                            <label className="label">WHAT TO STUDY</label>
                            <FormikSelect
                              classes="input-sm"
                              styles={{
                                ...customStyles,
                                control: (provided, state) => ({
                                  ...provided,
                                  minHeight: "50px",
                                  height: "50px",
                                  borderRadius: "4px",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  height: "50px",
                                  padding: "0 20px",
                                }),
                                indicatorsContainer: (provided, state) => ({
                                  ...provided,
                                  height: "50px",
                                }),
                              }}
                              name="courseType"
                              options={[
                                { value: 1, label: "Bachelor's degree" },
                                { value: 2, label: "Master's degree" },
                                { value: 3, label: "PHD degree" },
                              ]}
                              value={values?.courseType}
                              onChange={(valueOption) => {
                                setIsCheck(false);
                                setFieldValue("courseType", valueOption);
                              }}
                              errors={errors}
                              touched={touched}
                              menuPosition={"fixed"}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="input-field-main">
                            <label className="label">WHERE TO STUDY</label>
                            <FormikSelect
                              classes="input-sm"
                              styles={{
                                ...customStyles,
                                control: (provided, state) => ({
                                  ...provided,
                                  minHeight: "50px",
                                  height: "50px",
                                  borderRadius: "4px",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  height: "50px",
                                  padding: "0 20px",
                                }),
                                indicatorsContainer: (provided, state) => ({
                                  ...provided,
                                  height: "50px",
                                }),
                              }}
                              name="coursePlace"
                              options={coursePlace || []}
                              value={values?.coursePlace}
                              onChange={(valueOption) => {
                                setIsCheck(false);
                                setFieldValue("coursePlace", valueOption);
                              }}
                              errors={errors}
                              touched={touched}
                              menuPosition={"fixed"}
                            />
                          </div>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="btn btn-medium btn-orange"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!values?.courseType || !values?.coursePlace) {
                                return toast.warning(
                                  "Please Provide Valid Information"
                                );
                              }

                              getVisaProcessingData(
                                { country: values?.coursePlace?.value },
                                (data) => {
                                  console.log("Data", data);
                                  if (data[0]) {
                                    setVisaProcessingData(data[0]);
                                    setIsCheck(true);
                                  }
                                },
                                setLoading
                              );
                            }}
                          >
                            Check Requirements
                          </button>
                        </li>
                      </ul>
                      {isCheck && (
                        <>
                          <div className="single-visa-process-title-section">
                            <div className="row">
                              <div className="col-xl-8">
                                <h2 className="h2">
                                  {values?.coursePlace?.label} Student Visa
                                  Requirement
                                </h2>
                              </div>
                              <div className="col-xl-4">
                                <div className="apply-button">
                                  <button
                                    type="button"
                                    className="btn btn-large btn-neoGreen"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsCheck(false);
                                    }}
                                  >
                                    Apply Online
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-shape">
                      <img src={heroShape} alt="scholiguide" />
                    </div>
                    {/* hero-section-person-img visa-hero-img */}
                    <div className="hero-section-person-img about-section-person-img visa-hero-img">
                      <img src={heroVisa} alt="scholiguide" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isCheck ? (
            <>
              <div className="visa-process-step">
                <div className="visa-process-step-content app-width">
                  <div className="visa-process-step-content">
                    <SingleVisaProcessing
                      values={values}
                      visaProcessingData={visaProcessingData}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* visa process step */}
              <div className="visa-process-step">
                <div className="visa-process-step-content app-width">
                  <SectionTitle title="3 Easy Steps to Secure Your Visa:" />
                  <div className="visa-process-step-content">
                    <div className="row visa-process-step-list">
                      {easyStepProcedure.length > 0 &&
                        easyStepProcedure.map((item, index) => {
                          return (
                            <div className="col-md-4" key={index}>
                              <div
                                className="single-service-list single-visa-process-step"
                                style={{
                                  background: `${visProcessingBgColor[index]}`,
                                }}
                              >
                                <div
                                  className="single-service-img single-visa-process-step-img"
                                  style={{
                                    background: `${visProcessingColor[index]}`,
                                  }}
                                >
                                  {index + 1}
                                </div>
                                <div className="single-service-body single-visa-process-step-body">
                                  <h3 className="h3 h3-bold">{item?.title}</h3>
                                  <p>{item?.txt}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              {/* faq-card-section */}
              <div className="faq-card-section">
                <div className="faq-card-section-content app-width">
                  <div className="card-info-heading">
                    <SectionTitle title="Answers to Common Queries:" />
                  </div>
                  <div className="card-info-body">
                    <ul className="faq-list">
                      <li>
                        <h2 className="h4">
                          Query: What are the essential documents needed for a
                          Canada student visa application?
                        </h2>
                        <p>
                          Answer: For a Canada student visa, you'll typically
                          need your passport, acceptance letter from a Canadian
                          institution, proof of funds, passport-sized
                          photographs, and a completed application form.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: Can Scholiguide assist with the UK Tier 4
                          student visa process?
                        </h2>
                        <p>
                          Answer: Absolutely! Scholiguide offers comprehensive
                          guidance for the UK Tier 4 student visa process,
                          including visa application submission, CAS
                          (Confirmation of Acceptance for Studies) issuance, and
                          more.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: What's the process for obtaining an Australian
                          student visa?
                        </h2>
                        <p>
                          Answer: To secure an Australia student visa, you'll
                          need to submit your Confirmation of Enrolment (CoE),
                          Genuine Temporary Entrant (GTE) statement, financial
                          evidence, health insurance, and complete the online
                          application form.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: How long does it typically take to process a
                          Germany student visa?
                        </h2>
                        <p>
                          Answer: The processing time for a Germany student visa
                          varies, but it's advisable to apply at least 3 months
                          before your intended travel date. Scholiguide can help
                          you keep track of the application's progress.
                        </p>
                        <p className="d-none">
                          Answer: Mozambique provides an on-arrival visa
                          facility for Bangladeshis. Detailed information can be
                          found on our website-
                          <Link to="/">www.scholiguide.com</Link>
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: Are there any language requirements for a
                          France student visa application?
                        </h2>
                        <p>
                          Answer: Yes, some universities in France may require
                          you to demonstrate proficiency in French or English.
                          Ensure you check the language requirements of your
                          chosen program.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: What's the visa application process for
                          studying medicine in the USA?
                        </h2>
                        <p>
                          Answer: The USA student visa process involves
                          obtaining the I-20 form from your chosen medical
                          school, paying the SEVIS fee, completing the DS-160
                          form, and attending a visa interview at the U.S.
                          Embassy or Consulate.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: What's the visa application process for
                          studying medicine in the USA?
                        </h2>
                        <p>
                          Answer: The USA student visa process involves
                          obtaining the I-20 form from your chosen medical
                          school, paying the SEVIS fee, completing the DS-160
                          form, and attending a visa interview at the U.S.
                          Embassy or Consulate.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: Can Scholiguide assist with obtaining a
                          scholarship to help with visa-related expenses?
                        </h2>
                        <p>
                          Answer: While Scholiguide primarily focuses on visa
                          processing, we can provide information about
                          scholarship opportunities available for international
                          students to ease the financial burden of studying
                          abroad.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: Are there any specific health requirements for
                          a Japan student visa?
                        </h2>
                        <p>
                          Answer: Japan may require a medical examination
                          certificate to ensure you're in good health. Consult
                          with your local Japanese Embassy or Consulate to
                          understand the exact health requirements.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: What's the process for extending a student visa
                          in Ireland?
                        </h2>
                        <p>
                          Answer: To extend your student visa in Ireland, you
                          usually need to apply through the Irish Naturalisation
                          and Immigration Service (INIS) and provide updated
                          enrollment details and proof of financial sufficiency.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Query: Can I work part-time on a student visa in New
                          Zealand? Query: What's the process for extending a
                          student visa in Ireland?
                        </h2>
                        <p>
                          Answer: Yes, New Zealand generally allows
                          international students to work part-time during their
                          studies. Check the specific visa conditions and work
                          hour limitations set by Immigration New Zealand.
                        </p>
                      </li>
                      <li>
                        <h2 className="h4">
                          Discover the Ideal Place for You:
                        </h2>
                        <p>
                          Explore your educational journey with ScholiGuide's
                          comprehensive visa assistance. We're here to help you
                          succeed in your international studies.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* contact with counselor section */}
          <div
            style={{ marginTop: "16px" }}
            className="connect-counselor-section app-width"
          >
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Discover The Ideal Place For You With The Assist Of Our
                Counselor
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-large btn-neoGreen"
                  style={{ fontSize: "15px !important" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Connect With Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>

          {/* footer */}
          <div className="res-footer-inner d-xl-none d-block">
            <div className="res-footer-menu">
              <div className="res-footer-menu-img">
                <Link to="/">
                  <img src={schoify} alt="scholify" />
                </Link>
              </div>
              <div
                className="input-field-main"
                style={{
                  margin: "0 auto 20px",
                  maxWidth: "178px",
                }}
              >
                <FormikSelect
                  classes="input-sm"
                  styles={customStyles}
                  name="language"
                  options={[
                    { value: 1, label: "English" },
                    { value: 2, label: "Bangla" },
                  ]}
                  value={values?.language}
                  onChange={(valueOption) => {
                    setFieldValue("language", valueOption);
                  }}
                />
              </div>
              <ul className="res-footer-menu-list">
                <li>
                  <Link to="/">Full Funding Scholarship</Link>
                </li>
                <li>
                  <Link to="/">Latest Scholarship</Link>
                </li>
                <li>
                  <Link to="/">Get Your SOP</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul>
            </div>
            <ul className="footer-social-list">
              <li>
                <Link to="/">
                  <img src={facebookImg} alt="scholify" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={instagramImg} alt="scholify" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={twitterImg} alt="scholify" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={linkedinImg} alt="scholify" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={youtubeImg} alt="scholify" />
                </Link>
              </li>
            </ul>
            <div className="footer-web-duration">
              &copy; 2022 www.scholiguide.com
            </div>
          </div>

          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>

          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
