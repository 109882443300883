import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Instagram, Twitter, YouTube } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../common/SectionTitle";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";

//  images
import blogFeature from "../../assets/images/blog/blog-feature.png";
import blogImg from "../../assets/images/blog02.png";
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";

export default function Blog() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const [index, setIndex] = useState(0);

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  const tabName = [
    { name: "Masters" },
    { name: "Bachelors" },
    { name: "PHD" },
    { name: "Engineering" },
    { name: "Technology" },
  ];

  return (
    <>
      <form>
        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* blog hero section */}
          <div className="hero-section about-hero-section faq-hero-section bgOne">
            <div className="hero-section-content">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="hero-section-body about-section-body">
                    <h2 className="h1 darkText">Blog</h2>
                    <p className="para2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Convallis adipiscing consectetur ornare.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-person-img faq-section-person-img"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* blog-main-info */}
          <div className="blog-main-info">
            <div className="blog-main-info-content app-width">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="blog-main-info-txt">
                    <h3 className="sub-title">Featured Post</h3>
                    <h2>
                      <Link to={"/blog"}>
                        Fully Funded Scholarships for bright and intelligent
                        students
                      </Link>
                    </h2>
                    <p className="h4">
                      Students who are planning to continue their higher
                      education in Australia, UK, the USA, and Canada should
                      apply...
                    </p>
                    <div>
                      <button
                        type="button"
                        className="btn btn-medium btn-orange"
                      >
                        Read More
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blog-main-info-img">
                    <img src={blogFeature} alt="scholiguide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* blog-card section */}
          <div className="blog-card-section">
            <div className="blog-card-section-content app-width">
              <div className="blog-card-section-content-tab">
                <SectionTitle title="Latest Articles" />
                <div>
                  <div className="couse-section-tab blog-section-tab">
                    <div className="course-tab-heading blog-tab-heading">
                      {tabName.map((item, i) => {
                        return (
                          <button
                            key={i}
                            type="button"
                            className={`btn btn-tab btn-blog-tab ${
                              i === index ? "active" : "btn-blackWhite"
                            }`}
                            onClick={() => setIndex(i)}
                          >
                            {item.name}
                          </button>
                        );
                      })}
                    </div>
                    <div className="bolg-tab-body">
                      <div className="row">
                        {index === 0 &&
                          [1, 2, 3]?.map((item, index) => {
                            return (
                              <div className="col-lg-4" key={index}>
                                <div className="single-blog-card">
                                  <div className="single-blog-card-img">
                                    <img src={blogImg} alt="scholiguide" />
                                  </div>
                                  <div className="single-blog-txt">
                                    <ul>
                                      <li className="blog-topics-chip">
                                        Masters
                                      </li>
                                      <li className="blog-topics-chip">PHD</li>
                                    </ul>
                                    <h2>
                                      <Link to={`/blog/${index}`}>
                                        Step-by-step guide to choosing great
                                        font pairs
                                      </Link>
                                    </h2>
                                    <p>
                                      Duis aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur. Excepteur sint
                                      occaecat cupidatat non proident. . . . . .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {index === 1 &&
                          [1, 2]?.map((item, index) => {
                            return (
                              <div className="col-lg-4" key={index}>
                                <div className="single-blog-card">
                                  <div className="single-blog-card-img">
                                    <img src={blogImg} alt="scholiguide" />
                                  </div>
                                  <div className="single-blog-txt">
                                    <ul>
                                      <li className="blog-topics-chip">
                                        Masters
                                      </li>
                                      <li className="blog-topics-chip">PHD</li>
                                    </ul>
                                    <h2>
                                      <Link to={`/blog/${index}`}>
                                        Step-by-step guide to choosing great
                                        font pairs
                                      </Link>
                                    </h2>
                                    <p>
                                      Duis aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur. Excepteur sint
                                      occaecat cupidatat non proident. . . . . .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {index === 2 &&
                          [1, 2, 3]?.map((item, index) => {
                            return (
                              <div className="col-lg-4" key={index}>
                                <div className="single-blog-card">
                                  <div className="single-blog-card-img">
                                    <img src={blogImg} alt="scholiguide" />
                                  </div>
                                  <div className="single-blog-txt">
                                    <ul>
                                      <li className="blog-topics-chip">
                                        Masters
                                      </li>
                                      <li className="blog-topics-chip">PHD</li>
                                    </ul>
                                    <h2>
                                      <Link to={`/blog/${index}`}>
                                        Step-by-step guide to choosing great
                                        font pairs
                                      </Link>
                                    </h2>
                                    <p>
                                      Duis aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur. Excepteur sint
                                      occaecat cupidatat non proident. . . . . .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {index === 3 &&
                          [1, 2, 3, 4]?.map((item, index) => {
                            return (
                              <div className="col-lg-4" key={index}>
                                <div className="single-blog-card">
                                  <div className="single-blog-card-img">
                                    <img src={blogImg} alt="scholiguide" />
                                  </div>
                                  <div className="single-blog-txt">
                                    <ul>
                                      <li className="blog-topics-chip">
                                        Masters
                                      </li>
                                      <li className="blog-topics-chip">PHD</li>
                                    </ul>
                                    <h2>
                                      <Link to={`/blog/${index}`}>
                                        Step-by-step guide to choosing great
                                        font pairs
                                      </Link>
                                    </h2>
                                    <p>
                                      Duis aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur. Excepteur sint
                                      occaecat cupidatat non proident. . . . . .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {index === 4 &&
                          [1, 2, 3, 4, 5]?.map((item, index) => {
                            return (
                              <div className="col-lg-4" key={index}>
                                <div className="single-blog-card">
                                  <div className="single-blog-card-img">
                                    <img src={blogImg} alt="scholiguide" />
                                  </div>
                                  <div className="single-blog-txt">
                                    <ul>
                                      <li className="blog-topics-chip">
                                        Masters
                                      </li>
                                      <li className="blog-topics-chip">PHD</li>
                                    </ul>
                                    <h2>
                                      <Link to={`/blog/${index}`}>
                                        Step-by-step guide to choosing great
                                        font pairs
                                      </Link>
                                    </h2>
                                    <p>
                                      Duis aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur. Excepteur sint
                                      occaecat cupidatat non proident. . . . . .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* contact with counselor section */}
          <div
            style={{ marginTop: "16px" }}
            className="connect-counselor-section app-width"
          >
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Discover The Ideal Place For You With The Assist Of Our
                Counselor
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-large btn-darkOrange"
                  style={{ fontSize: "15px !important" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Connect With Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
