import { localStorageSlice } from "../reduxForLocalStorage/slice";
import { authSlice } from "./slice";

const { actions: slice } = authSlice;
const { actions: localStageslice } = localStorageSlice;

export const setLoginAction = (email, password,cb) => (dispatch) => {
  dispatch(slice.setLogin(email, password,cb));
};

export const setLogoutAction = () => (dispatch) => {
  dispatch(slice.setLogout());
  dispatch(localStageslice.setMyList({}));
  dispatch(localStageslice.setNotificationsCount(0));
};
