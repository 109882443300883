import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FormikSelect from "../../common/FormikSelect";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";
import { customStyles } from "../../utility/selectCustomStyle";
// images
import {
  ArrowForwardOutlined,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import * as Yup from "yup";
import DefaultInput from "../../common/DefaultInput";
import { gray, white } from "../../utility/customColor";

// images
import { useState } from "react";
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";
import { postSubmitContactForm } from "./api";

export const validationSchemaContactForm = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
  contatcNo: Yup.string().required("Contact Number is required"),
  education: Yup.object().shape({
    value: Yup.string().required("Education is required"),
    label: Yup.string().required("Education is required"),
  }),
  // message: Yup.string().required("Message is required"),
});

const DEGREE = [
  {
    value: 1,
    label: "Bachelor",
  },

  {
    value: 2,
    label: "Masters",
  },
  { value: 3, label: "PHD" },
];

const initData = {
  name: "",
  email: "",
  contatcNo: "",
  education: DEGREE[0],
  message: "",
};

export default function Contact() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const [loading, setLoading] = useState(false);
  // const { filterSearch } = useSelector((state) => {
  //   return state.localStorage;
  // }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const { values, errors, touched, setFieldValue, submitForm } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    validationSchema: validationSchemaContactForm,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      postSubmitContactForm(values, setLoading, () => {
        resetForm(initData);
      });
    },
  });

  return (
    <>
      <form>
        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* single-blog-wrapper */}
          <div className="single-blog-section app-width">
            <div className="single-blog-wrapper">
              <div className="single-bolg-content">
                <div className="row">
                  <div className="col-12">
                    <div className="single-blog-title">
                      <h2 className="h1">Get Connected with Counselor</h2>
                    </div>
                    <div className="contact-form">
                      <div className="row">
                        <div className="col-6">
                          <div className="input-field-main">
                            <label>Name:</label>
                            <DefaultInput
                              classes="input-sm"
                              value={values?.name}
                              name="name"
                              type="text"
                              placeholder=" "
                              errors={errors}
                              touched={touched}
                              onChange={(e) =>
                                setFieldValue("name", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-field-main">
                            <label>Email:</label>
                            <DefaultInput
                              classes="input-sm"
                              value={values?.email}
                              name="email"
                              type="email"
                              placeholder=" "
                              errors={errors}
                              touched={touched}
                              onChange={(e) =>
                                setFieldValue("email", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-field-main">
                            <label>Contact Number:</label>
                            <DefaultInput
                              classes="input-sm"
                              value={values?.contatcNo}
                              name="contatcNo"
                              type="text"
                              placeholder=" "
                              errors={errors}
                              touched={touched}
                              onChange={(e) =>
                                setFieldValue("contatcNo", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-field-main">
                            <label>Education:</label>
                            <FormikSelect
                              isClearable={false}
                              classes="input-sm"
                              styles={{
                                ...customStyles,
                                control: (provided, state) => ({
                                  ...provided,
                                  minHeight: "46px",
                                  height: "46px",
                                  borderRadius: "4px",
                                  borderColor: gray,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  height: "46px",
                                  padding: "0 10px",
                                }),
                                indicatorsContainer: (provided, state) => ({
                                  ...provided,
                                  height: "46px",
                                }),
                              }}
                              name="education"
                              options={DEGREE}
                              value={values?.education}
                              onChange={(valueOption) => {
                                setFieldValue("education", valueOption);
                              }}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-field-main">
                            <label>Give us a message(Optional)</label>
                            <DefaultInput
                              isTextArea={true}
                              classes="input-sm textarea-field"
                              value={values?.message}
                              name="message"
                              type="text"
                              placeholder="Write here . . . "
                              errors={errors}
                              touched={touched}
                              onChange={(e) =>
                                setFieldValue("message", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 center">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => submitForm()}
                            className="btn btn-contact btn-orange"
                            style={{ margin: "15px 0 0" }}
                          >
                            Submit Now
                            <span>
                              <ArrowForwardOutlined
                                sx={{
                                  color: white,
                                  marginLeft: "10px",
                                  fontSize: "20px",
                                }}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>

          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
