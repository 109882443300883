import { useState, useEffect, useCallback } from "react";
import axios from "axios";

/* 
  
  This function is only used for pagination api's
  Source: https://codesandbox.io/s/infinite-scroll-intersectionobserver-ydfjo?file=/src/App.js:1156-1179

*/

export function useFetchInfiniteScroll(api, payload) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [extraObj, setExtraObj] = useState({});
  const [noMoreData, setNoMoreData] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      let res;
      if (payload) {
        res = await axios.post(api, payload);
      } else {
        res = await axios.get(api);
      }

      if (res?.data?.data?.length === 0) {
        setNoMoreData(true);
      }

      await setList((prev) => [...new Set([...prev, ...res?.data?.data])]);
      setExtraObj({ ...res?.data, data: undefined });
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  }, [api, payload]);

  useEffect(() => {
    if (!noMoreData) {
      fetchData(api);
    }
  }, [api, fetchData, noMoreData]);

  return { loading, error, list, extraObj, noMoreData };
}
