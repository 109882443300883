import axios from "axios";
import { toast } from "react-toastify";

export const postSubmitContactForm = async (payload, setLoading, cb) => {
  const id = toast.loading("Please wait...");

  setLoading(true);
  const _payload = {
    responseType: "contact",
    responseText: payload?.message || "-",
    userType: payload?.education?.label,
    name: payload?.name,
    email: payload?.email,
    organization: payload?.contatcNo,
  };

  try {
    const res = await axios.post("/api/userResponse/create", _payload);
    if (res?.status === 200) {
      toast.update(id, {
        render: "Submited successfully! We will notify you soon",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
      cb();
      setLoading(false);
    }
  } catch (err) {
    toast.update(id, {
      render: "Something went worng! try again later",
      type: "errror",
      isLoading: false,
      autoClose: 4000,
    });
    setLoading(false);
  }
};
