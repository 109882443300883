import { IconButton } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import crossIcon from "../assets/images/icon/cross.svg";

export default function ViewModal({
  show,
  onHide,
  size,
  children,
  title,
  btnText,
  backdrop,
  classes,
  isVisibleHeading = true,
  fullscreen,
  isCloseIcon = true,
  centered = false,
}) {
  return (
    <div className="viewModal">
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        backdrop={backdrop}
        aria-labelledby="example-modal-sizes-title-xl"
        className={classes}
        fullscreen={fullscreen && fullscreen}
        centered={centered}
      >
        {isVisibleHeading && (
          <Modal.Header className="bg-custom">
            <Modal.Title className="text-center">{title}</Modal.Title>
            {isCloseIcon && (
              <div className="d-flex">
                <div>
                  <IconButton
                    type="button"
                    onClick={() => onHide()}
                    className="btn btn-load btn-modal-cross"
                  >
                    {btnText ? btnText : <img src={crossIcon} alt="scholify" />}
                  </IconButton>
                  <> </>
                </div>
              </div>
            )}
          </Modal.Header>
        )}

        <Modal.Body id="example-modal-sizes-title-xl">{children}</Modal.Body>
      </Modal>
    </div>
  );
}
