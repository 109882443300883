import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MailOutlined,
  SearchOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { useFormik } from "formik";
import React from "react";
import DefaultInput from "../../common/DefaultInput";
import FormikCheckBox from "../../common/FormikCheckbox";
import { sky } from "../../utility/customColor";

export default function ComponentUI({ index, tabIndex }) {
  const initData = {
    search: "",
    email: "",
  };

  const { values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      resetForm(initData);
    },
  });

  return (
    <>
      {index === tabIndex && (
        <form>
          <div className="single-search-item-body">
            <div className="row">
              <div className="col-12">
                {/* button */}
                <div>
                  <h2 className="h1">Button</h2>
                  <ul
                    className="box-list flex-column"
                    style={{ marginBottom: "20px" }}
                  >
                    <li style={{ marginBottom: "10px" }}>
                      <button
                        type="button"
                        className="btn btn-large btn-sky mr-2"
                      >
                        Search Now
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-large btn-default"
                      >
                        Search Now
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <button
                        type="button"
                        className="btn btn-medium btn-blue mr-2"
                      >
                        Search Now
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-medium btn-default"
                      >
                        Search Now
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <button
                        type="button"
                        className="btn btn-small btn-blue mr-2"
                      >
                        Search Now
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-small btn-default"
                      >
                        Search Now
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-tab btn-sky mr-2"
                      >
                        Overview
                      </button>
                      <button type="button" className="btn btn-tab btn-default">
                        Overview
                      </button>
                    </li>
                  </ul>
                  <div className="hr-bottom-line"></div>
                </div>
                {/* field */}
                <div>
                  <h2 className="h1">Field</h2>
                  <ul
                    className="box-list flex-column"
                    style={{ marginBottom: "20px" }}
                  >
                    <li style={{ marginBottom: "10px" }}>
                      <DefaultInput
                        classes="input-large lead-icon-padding"
                        value={values?.search}
                        name="search"
                        type="text"
                        placeholder="Search"
                        leadicon={<SearchOutlined sx={{ fontSize: "16px" }} />}
                        errors={errors}
                        touched={touched}
                        onChange={(e) =>
                          setFieldValue("search", e.target.value)
                        }
                      />
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <label className="label">Email Address: *</label>
                      <DefaultInput
                        classes="input-sm"
                        value={values?.email}
                        name="email"
                        type="email"
                        placeholder="saikatkr034@gmail.com"
                        trailicon={<MailOutlined sx={{ fontSize: "16px" }} />}
                        errors={errors}
                        touched={touched}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                      />
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <DefaultInput
                        classes="input-sm lead-icon-padding"
                        value={values?.search}
                        name="search"
                        type="text"
                        placeholder="Search"
                        leadicon={<SearchOutlined sx={{ fontSize: "16px" }} />}
                        errors={errors}
                        touched={touched}
                        onChange={(e) =>
                          setFieldValue("search", e.target.value)
                        }
                      />
                    </li>
                    <li>
                      <div>
                        <FormikCheckBox
                          styleObj={{
                            color: sky,
                            labelFontSize: "14px",
                          }}
                          name="isRemember"
                          checked={values?.isRemember}
                          onChange={(e) => {
                            setFieldValue("isRemember", e.target.checked);
                          }}
                          label="Remember Me"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                {/* component */}
                <div>
                  <h2 className="h1">Component</h2>
                  <ul
                    className="box-list flex-column"
                    style={{ marginBottom: "20px" }}
                  >
                    <li style={{ marginBottom: "10px" }}>
                      <div className="chip-info">
                        <TodayOutlined
                          sx={{ fontSize: "16px", marginRight: "3px" }}
                        />
                        <span>11 June 2022</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
