import { CheckCircle } from "@mui/icons-material";
import React from "react";
import { blueTwo, deepSkyBlue } from "../../utility/customColor";

export default function SingleVisaProcessing({ values, visaProcessingData }) {
  const openTab = (link) => {
    window.open(link);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="single-visa-processing-card-section">
          <div className="card-info-heading">
            <h2 className="h3 h3-bold">
              {values?.coursePlace?.label} Students Visa Requirement
            </h2>
          </div>
          <div className="card-info-body">
            <ul className="faq-list">
              <li>
                <h2 className="h4">1. Passport</h2>
                <p>
                  Original Passport with a validity of at least 12 months after
                  the intended date of departure and minimum of two blank pages
                  for visa stamp. If you want a passport, please click here.
                </p>
              </li>
              <li>
                <h2 className="h4">2. Visa Application Form</h2>
                <p>Completed and signed national visa application form</p>
              </li>
              <li>
                <h2 className="h4">3. Photo Specification</h2>
                <p>Color photograph (35mm*45mm) with white background</p>
              </li>
              <li>
                <h2 className="h4">4. Covering-Letter</h2>
                <p>
                  Covering letter mentioning details of applicants, passport
                  details, travel details, and who will bear expenses details.
                </p>
              </li>
              <li>
                <h2 className="h4">5. Confirmation of Enrolment</h2>
                <p>
                  Original letter from the University or Recognized Educational
                  Institution confirming admission and duration of the course
                  (Scholarship certificate if applicable) with more than one
                  copy
                </p>
              </li>
              <li>
                <h2 className="h4">6. Tuition Fees Payment Receipt</h2>
                <p>
                  A payment receipt is proof of payment that you have made
                  payment to the institution (If required)
                </p>
              </li>
              <li>
                <h2 className="h4">7. Academic Documents:</h2>
                <p>Please submit your all academic documents to Embassy</p>
              </li>
              <li>
                <h2 className="h4">8. Financial Means</h2>
                <p>
                  Proof of financial means to cover the costs for the time of
                  your studies.
                </p>
              </li>
              <li>
                <h2 className="h4">9. Medical Report</h2>
                <p>
                  You must assess your health from France embassy recognized
                  medical center.
                </p>
              </li>
              <li>
                <h2 className="h4">10. Health Insurance</h2>
                <p>
                  A proof of health insurance coverage whole period of study.
                </p>
              </li>
              <li>
                <h2 className="h4">11. Birth Certificate</h2>
                <p>An original copy of the Birth Registration Certificate.</p>
              </li>
              <li>
                <h2 className="h4">12. Police Clearance</h2>
                <p>
                  An original copy of the Police Clearance Certificate. If you
                  want a Police Clearance Certificate
                </p>
              </li>
              <li>
                <h2 className="h4">13. Accommodation Details</h2>
                <p>
                  Once you have confirmed where you will be studying, you just
                  need to show accommodation details.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-5 d-none">
        <div className="single-visa-processing-card-section">
          <div className="card-info-heading">
            <h2 className="h3 h3-bold">Basic Information</h2>
          </div>
          <div className="card-info-body">
            <ul className="faq-list">
              <li>
                <h2 className="h4 text-capitalize">
                  For {values?.coursePlace?.label}, Scholiguide provides
                </h2>
                <p>
                  <span style={{ marginRight: "8px" }}>
                    <CheckCircle sx={{ color: blueTwo, fontSize: "18px" }} />
                  </span>
                  Visa Consultancy
                </p>
                <div className="single-visa-check-list">
                  <p>
                    For Bangladeshi visa seekers who want to travel to{" "}
                    {values?.coursePlace?.label} are issued visas in varies
                    categories such as:
                  </p>
                  <ul className="row">
                    <li className="col-xl-4">
                      <span style={{ marginRight: "8px" }}>
                        <CheckCircle
                          sx={{ color: blueTwo, fontSize: "15px" }}
                        />
                      </span>
                      Visit visa
                    </li>
                    <li className="col-xl-4">
                      <span style={{ marginRight: "8px" }}>
                        <CheckCircle
                          sx={{ color: blueTwo, fontSize: "15px" }}
                        />
                      </span>
                      Tourist visa
                    </li>
                    <li className="col-xl-4">
                      <span style={{ marginRight: "8px" }}>
                        <CheckCircle
                          sx={{ color: blueTwo, fontSize: "15px" }}
                        />
                      </span>
                      Family visa
                    </li>
                    <li className="col-xl-4">
                      <span style={{ marginRight: "8px" }}>
                        <CheckCircle
                          sx={{ color: blueTwo, fontSize: "15px" }}
                        />
                      </span>
                      Business visa
                    </li>
                    <li className="col-xl-4">
                      <span style={{ marginRight: "8px" }}>
                        <CheckCircle
                          sx={{ color: blueTwo, fontSize: "15px" }}
                        />
                      </span>
                      Others
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <h2 className="h4 text-capitalize">
                  For {values?.coursePlace?.label}, Scholiguide provides
                </h2>
                <div className="single-visa-check-list">
                  <p>
                    Embassy of the {visaProcessingData?.embassyInfo?.name}{" "}
                    {visaProcessingData?.embassyInfo?.address}
                  </p>
                </div>
                <div className="single-visa-check-list">
                  <p>
                    Tel: {visaProcessingData?.embassyInfo?.phone} Fax:{" "}
                    {visaProcessingData?.embassyInfo?.fax}
                  </p>
                </div>
                <div className="single-visa-check-list">
                  <p>Email: {visaProcessingData?.embassyInfo?.email}</p>
                </div>

                <div className="single-visa-check-list">
                  <p>
                    Website:{" "}
                    <span
                      onClick={() =>
                        openTab(visaProcessingData?.embassyInfo?.website)
                      }
                      className="pointer"
                      style={{ color: deepSkyBlue }}
                    >
                      click here..
                    </span>{" "}
                  </p>
                </div>
              </li>
              <li>
                <h2 className="h4 text-capitalize">Information about visa</h2>
                <div className="single-visa-check-list">
                  <p>{visaProcessingData?.visaInfo?.description}</p>
                </div>
                <div className="single-visa-check-list">
                  <p style={{ fontStyle: "italic", fontWeight: "500" }}>
                    {visaProcessingData?.visaInfo?.address}
                  </p>
                </div>

                <div className="single-visa-check-list">
                  <p>
                    Tel: {visaProcessingData?.visaInfo?.phone} Fax:{" "}
                    {visaProcessingData?.visaInfo?.fax}
                  </p>
                </div>
                <div className="single-visa-check-list">
                  <p>Email: {visaProcessingData?.visaInfo?.email}</p>
                </div>

                <div className="single-visa-check-list">
                  <p>
                    Website:{" "}
                    <span
                      onClick={() =>
                        openTab(visaProcessingData?.visaInfo?.website)
                      }
                      className="pointer"
                      style={{ color: deepSkyBlue }}
                    >
                      click here..
                    </span>{" "}
                  </p>
                </div>
              </li>
              <li>
                <h2 className="h4 text-capitalize">
                  {values?.coursePlace?.label} visa fee
                </h2>
                <p>{visaProcessingData?.visaFee}</p>
              </li>
              <li>
                <h2 className="h4 text-capitalize">Processing time</h2>
                <p>{visaProcessingData?.processingTime}</p>
              </li>
              <li>
                <h2 className="h4 text-capitalize">Visa consultacy fee</h2>
                <p>
                  15% VAT is applicable on Scholiguide Express' service
                  (consultancy) charge.
                </p>
                {/* <div className="single-visa-check-list">
                  <p style={{ fontWeight: "600" }}>Total - 10, 000 BDT</p>
                </div> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
