import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Instagram, Twitter, YouTube } from "@mui/icons-material";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DefaultInput from "../../common/DefaultInput";
import SectionTitle from "../../common/SectionTitle";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";

// images
import facebookImg from "../../assets/images/facebook.png";
import faqAskPerson from "../../assets/images/faq.png";
import schoify from "../../assets/images/footer-logo.png";
import heroShape from "../../assets/images/hero-shape.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";

export default function FAQ() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  const initData = {
    language: "",
    emailSubscribe: "",
    question: "",
  };

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  const { values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      resetForm(initData);
    },
  });

  return (
    <>
      <form>
        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* faq hero section */}
          <div className="hero-section about-hero-section  faq-hero-section bgOne">
            <div className="hero-section-content">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="hero-section-body about-section-body">
                    <h2 className="h1 darkText">FAQ</h2>
                    <p className="para2">
                      Here are some queries and answers that might be helpful
                      for you to study abroad
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-shape">
                      <img src={heroShape} alt="scholiguide" />
                    </div>
                    <div className="hero-section-person-img faq-section-person-img"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* faq-card-section */}
          <div className="faq-card-section">
            <div className="faq-card-section-content app-width">
              <div className="card-info-heading">
                <SectionTitle title="Frequently asked questions." />
              </div>
              <div className="card-info-body">
                <ul className="faq-list">
                  <li>
                    <h2 className="h4">How do I get started?</h2>
                    <p>
                      Answer: Scholiguide provides complete visa processing
                      service for Vietnam visa including immigration approval.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      What are the entry requirements for studying abroad?
                    </h2>
                    <p>
                      Answer: The entry requirement is different for specific
                      countries and universities. However, the most common
                      requirement is CGPA 3.00 and IELTS total band of 6.5.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      Do I need to take the IELTS exam to study abroad?
                    </h2>
                    <p>
                      Answer: Yes you do as it is one of the basic requirements
                      for admission. However, there are some institutions that
                      accept students without IELTS but it'll be wise to take
                      the IELTS exam for expanding your options and several
                      other reasons.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      How much time is needed to take IELTS preparation?
                    </h2>
                    <p>
                      Answer: Actually it depends on your grasp of the English
                      language. If you know the basics then with proper guidance
                      it'll take no more than 2 to 4 months.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      How much does it cost to study abroad?
                    </h2>
                    <p>
                      Answer: There is no fixed cost as it differs from
                      university to university. You can check it on the
                      university website you want to study or our councilors
                      will find it for you.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">Can I work part time while studying?</h2>
                    <p>
                      Answer: Absolutely you can but there is a time limit per
                      week which is different in different countries. However,
                      20 hours per week is the majority.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      How much time is needed for visa processing?
                    </h2>
                    <p>
                      Answer: It takes 4 to 24 weeks depending on the country
                      you are applying for.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">How can I get a scholarship?</h2>
                    <p>
                      Answer: Every year hundreds of scholarships are offered
                      but there are some criteria you must meet to obtain them.
                      Visit our services to learn more.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      Can I get financial aid to study abroad?
                    </h2>
                    <p>
                      Answer: Yes you can but in order to do that you must
                      convince your supervisor that you are worthy of it. Get
                      help from our councilors to know how to communicate with
                      your supervisor.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      How long does the study abroad programme take?
                    </h2>
                    <p>
                      Answer: Generally you need 4 years for a bachelor degree,
                      1-2 years for masters, 1-5 years for diploma and other
                      degrees. When it comes to PHD, it depends on your research
                      and supervisor.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">When should I apply for admission?</h2>
                    <p>
                      Answer: Check on your website, there is a timeline for
                      intake schedules of specific countries.
                    </p>
                  </li>
                  <li>
                    <h2 className="h4">
                      What are the things I should consider when choosing a
                      programme and where to study?
                    </h2>
                    <p>
                      Answer: You must consider how this programme will add
                      value to your career when choosing one also the future
                      prospect for jobs, research opportunity and down to PR if
                      you are interested. In terms of institutions, check their
                      ranking, contribution, location and other convenient
                      factors for you.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* faq-question-card-section */}
          <div className="faq-question-card-section bgOne">
            <div className="faq-question-card-section-content app-width">
              <div className="row align-items-center">
                <div className="col-md-7">
                  <div className="faq-ask-form">
                    <div className="card-info-heading">
                      <h2 className="h3 h3-bold">Add Your Question</h2>
                    </div>
                    <div className="card-info-body">
                      <div className="subscribe-from footer-form">
                        <DefaultInput
                          classes="input-sm default-input"
                          value={values?.question}
                          name="question"
                          type="text"
                          placeholder="What is the Yellow Fever Vaccine?"
                          errors={errors}
                          touched={touched}
                          onChange={(e) =>
                            setFieldValue("question", e.target.value)
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-medium btn-orange"
                        >
                          Submit
                          <span>
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="faq-ask-form-img">
                    <img src={faqAskPerson} alt="scholiguide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* contact with counselor section */}
          <div
            style={{ marginTop: "16px" }}
            className="connect-counselor-section app-width"
          >
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Discover The Ideal Place For You With The Assist Of Our
                Counselor
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-large btn-darkOrange"
                  style={{ fontSize: "15px !important" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Connect With Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
