import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import imgOne from "../../assets/images/notification1.png";
import bellLarge from "../../assets/images/notificationBell.png";
import footerRightShape from "../../assets/images/rightShape.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";
import SectionTitle from "../../common/SectionTitle";
import {
  scrollHeightAction,
  setNotificationsCountAction,
} from "../../commonRedux/reduxForLocalStorage/action";
import { getAllNotificationsCountAndNotification } from "../../router/helper";
import { postUnseenMessage } from "./helper";

export default function Notification() {
  const history = useHistory();
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState();
  const { profileData } = useSelector((state) => {
    return state.auth;
  }, shallowEqual);

  useEffect(() => {
    if (!profileData?.id) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [profileData?.id]);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    getAllNotificationsCountAndNotification(
      {
        type: "get",
        userId: profileData?.id,
        skip: 0,
        limit: 100,
      },
      (data) => {
        setNotificationList(data);

        let filterUnseen = data?.filter((item) => !item?.seen);

        if (filterUnseen?.length > 0) {
          postUnseenMessage(
            {
              type: "unseen",
              // userId: profileData?.id,
              list: filterUnseen?.map?.((item) => {
                return item?._id;
              }),
            },
            () => {
              getAllNotificationsCountAndNotification(
                {
                  type: "count",
                  userId: profileData?.id,
                },
                (data) => {
                  dispatch(setNotificationsCountAction(data));
                }
              );

              getAllNotificationsCountAndNotification(
                {
                  type: "get",
                  userId: profileData?.id,
                  skip: 0,
                  limit: 100,
                },
                (data) => {
                  setNotificationList(data);
                }
              );
            },
            setLoading
          );
        }
      },
      setLoading
    );

    // eslint-disable-next-line
  }, [profileData?.id]);

  const openLink = (link) => {
    window.open(link);
  };

  return (
    <>
      <form>
        {loading ? (
          <div className="text-center mt-4">
            <CircularProgress size={20} color="inherit" />
          </div>
        ) : null}

        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* notification hero section */}
          <div className="hero-section about-hero-section bgOne">
            <div className="hero-section-content">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="hero-section-body about-section-body">
                    <h2 className="h1 darkText">Notifications</h2>
                    <p className="para2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Convallis adipiscing consectetur ornare.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-person-img about-section-person-img">
                      <img src={bellLarge} alt="scholiguide" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* notification list */}
          <div className="notication-wrapper app-width">
            <div className="notication-head">
              <SectionTitle title="Headline" />
            </div>
            <div className="row">
              <div className="col-xl-9 col-12">
                <div className="notification-list">
                  <ul>
                    {notificationList &&
                      notificationList?.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="notification-list-body">
                              <img src={imgOne} alt="scholify" />
                              <div className="notification-list-txt">
                                <h2>
                                  <Link
                                    onClick={() =>
                                      openLink(item?.notificationLink)
                                    }
                                    to="/notification"
                                  >
                                    {item?.notificationText
                                      ? item?.notificationText
                                      : "University Of Oxford published a new full funded scholarship"}
                                  </Link>
                                </h2>
                                {item?.notifyDate ? (
                                  <p>{moment(item?.notifyDate).fromNow()}</p>
                                ) : null}
                              </div>
                            </div>

                            {!item?.seen ? (
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "8px",
                                    color: "#FF842A",
                                  }}
                                />
                              </div>
                            ) : null}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* contact with counselor section */}
          <div
            style={{ marginTop: "16px" }}
            className="connect-counselor-section app-width"
          >
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Discover The Ideal Place For You With The Assist Of Our
                Counselor
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-large btn-darkOrange"
                  style={{ fontSize: "15px !important" }}
                >
                  Connect With Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
