import { LockOutlined, MailOutlineOutlined } from "@mui/icons-material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/logos/logo.png";
import FormikCheckBox from "../../common/FormikCheckbox";
import LoginInput from "../../common/LoginInput";
import { setLoginAction } from "../../commonRedux/auth/action";
import { deepSkyBlue } from "../../utility/customColor";

const initData = {
  eamil: "",
  password: "",
  isRemember: false,
};

const validationSchema = Yup.object().shape({});

export default function Login({ setLoginShow, setForgotShow, setShowMenu }) {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initData}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          dispatch(setLoginAction("email", "phone",()=>setShowMenu(false)));
        }}
      >
        {({
          handleSubmit,
          resetForm,
          values,
          errors,
          touched,
          setFieldValue,
          isValid,
          setValues,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Form>
              <div className="auth-wrapper-page flex-center">
                <div className="auth-login-wrapper">
                  <div className="logo-img">
                    <img src={logo} alt="scholify" />
                  </div>
                  <div className="auth-login-body">
                    <h2 className="auth-title">Login to Scholiguide!</h2>
                    <p className="auth-subtitle">OR LOGIN WITH EMAIL</p>
                    <div className="row">
                      <div className="col-12">
                        <div className="auth-form-input">
                          <div className="auth-label">
                            <label>Email Address: *</label>
                          </div>
                          <LoginInput
                            value={values?.email}
                            name="email"
                            type="email"
                            trailicon={<MailOutlineOutlined />}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="auth-form-input">
                          <div className="auth-label">
                            <label>Password: *</label>
                          </div>
                          <LoginInput
                            value={values?.password}
                            name="password"
                            type="password"
                            passwordicon={<LockOutlined />}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="flex-between auth-forget-section">
                          <FormikCheckBox
                            styleObj={{
                              color: deepSkyBlue,
                              labelFontSize: "14px",
                            }}
                            name="isRemember"
                            checked={values?.isRemember}
                            onChange={(e) => {
                              setFieldValue("isRemember", e.target.checked);
                            }}
                            label="Remember Me"
                          />
                          <p
                            style={{ cursor: "pointer" }}
                            className="o-cursor-pointer auth-forget"
                            onClick={(e) => {
                              e.stopPropagation();
                              setForgotShow(true);
                              setLoginShow(false);
                            }}
                          >
                            Forget Password ?
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn default-button">
                          Log In To Your Account
                        </button>
                      </div>
                    </div>
                    <p className="auth-footer-para">
                      By login in you agree to our{" "}
                      <span onClick={() => history.push("/")}>
                        Privacy Policy
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
