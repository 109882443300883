import { localStorageSlice } from "./slice";
const { actions: slice } = localStorageSlice;

// this redux is for store data to local storage by redux persist
export const setAppNameAction = (payload) => (dispatch) => {
  dispatch(slice.setAppName(payload));
};

export const scrollHeightAction = (data) => (dispatch) => {
  dispatch(slice.setScrollHeight(data));
};

export const filterSearchAction = (data) => (dispatch) => {
  dispatch(slice.setFilterSearch(data));
};

export const isDropdownAction = (data) => (dispatch) => {
  dispatch(slice.setIsDropdown(data));
};

export const setLocationPathAction = (payload) => (dispatch) => {
  dispatch(slice.setLocationPath(payload));
};

export const setMylistAction = (payload) => (dispatch) => {
  dispatch(slice.setMyList(payload));
};

export const setMyNotifications = (payload) => (dispatch) => {
  dispatch(slice.setMyNotifications(payload));
};
export const setNotificationsCountAction = (payload) => (dispatch) => {
  dispatch(slice.setNotificationsCount(payload));
};
