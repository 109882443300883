import { Popover } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setLogoutAction } from "../commonRedux/auth/action";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toast } from "react-toastify";

const ProfilePopUp = ({ propsObj }) => {
  const { profileData } = useSelector((state) => state.auth);
  const { id, open, anchorEl, handleClose } = propsObj;
  const dispatch = useDispatch();
  const history = useHistory();

  const date = new Date();
  const year = date.getFullYear();
  return (
    <Popover
      sx={{
        "& .MuiPaper-root": {
          minWidth: "350px",
          padding: "15px",
        },
        "@media screen and (max-width: 1199px)": {
          "& .MuiPaper-root": {
            minWidth: "250px",
          },
        },
      }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="top-user-popup">
        <ul className="top-user-popup-list">
          <li>
            <div className="single-top-user-popup-list-icon-wrapper">
              {profileData?.imageUrl ? (
                <img
                  src={profileData?.imageUrl}
                  alt={"Profile-img"}
                  height="40"
                  width="40"
                />
              ) : (
                <AccountCircleIcon className="mr-3" sx={{ fontSize: "40px" }} />
              )}
            </div>
            <div className="single-top-user-popup-list-txt">
              <h4>
                {profileData?.name
                  ? profileData?.name
                  : profileData?.email?.length > 0
                  ? profileData?.email.split("@")[0]
                  : null}
              </h4>
              <p>{profileData?.email}</p>
            </div>
          </li>

          <li
            onClick={() => {
              history.push("/");
              handleClose();
              dispatch(setLogoutAction());
              toast.success("Logout successfully", { toastId: 13214 });
            }}
          >
            <button type="button" class="btn btn-view-all blue-bg">
              Log Out
            </button>
          </li>
        </ul>

        <div className="footer-web-duration">
          &copy; {year} www.scholiguide.com
        </div>
      </div>
    </Popover>
  );
};

export default ProfilePopUp;
