import {
  BookmarkBorderOutlined,
  Instagram,
  Twitter,
  YouTube
} from "@mui/icons-material";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  scrollHeightAction,
  setMylistAction,
} from "../../commonRedux/reduxForLocalStorage/action";
import { getAddToMyListApi } from "./helper";

// images
import { CircularProgress } from "@mui/material";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import footerRightShape from "../../assets/images/rightShape.png";
import footerLogo from "../../assets/logos/logo.png";
import { deepSky } from "../../utility/customColor";
import SchoarshipComponent from "../search/schoarshipComponent";

export default function MyListPage() {
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const { profileData } = useSelector((state) => {
    return state.auth;
  }, shallowEqual);

  if (!profileData?.isAuth) {
    history.push("/");
  }

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
  }, [dispatch]);

  useEffect(() => {
    if (profileData?.id) {
      getAddToMyListApi(
        {
          type: "get",
          skip: 0,
          limit: 100,
          payload: {
            userId: profileData?.id,
          },
        },
        (data) => {
          let obj = {};
          for (let i of data) {
            if (!obj[i?._id]) {
              obj[i?._id] = true;
            }
          }
          dispatch(setMylistAction(obj));
          setSearchData(data);
        },
        setLoading
      );
    }
    // eslint-disable-next-line
  }, [profileData?.id]);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {loading ? (
        <div className="text-center mt-4">
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : null}

      <div className="d-flex justify-content-center">
        <div className="col-xl-9 col-12 m-0 p-0">
          <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
            {/* list-item */}
            <div className="my-list-section app-width">
              {!loading ? (
                <div className="d-flex align-items-center mt-3">
                  <BookmarkBorderOutlined
                    sx={{
                      color: deepSky,
                      fontSize: "24px",
                    }}
                  />
                  <h4>My List</h4>
                </div>
              ) : null}

              <SchoarshipComponent data={searchData} loading={loading} />
            </div>
          </div>
        </div>
      </div>

      {/* desktop footer */}
      <div className="footer-section bgOne d-xl-block d-none">
        <div className="footer-top app-width">
          <div className="row footer-logo-content align-items-center">
            <div className="col-lg-8">
              <div className="footer-logo">
                <img src={footerLogo} alt="scholify" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-social-network">
                <ul>
                  <li>
                    <Link to="/">
                      <img src={faFacebook} alt="scholify" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <Twitter sx={{ fontSize: "20px" }} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <YouTube sx={{ fontSize: "20px" }} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={faSnapchat} alt="scholify" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <Instagram sx={{ fontSize: "20px" }} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-menu">
            <div className="footer-menu-left row">
              <div className="col-lg-3">
                <h3 className="h4 footer-menu-title">Degrees</h3>
                <ul>
                  <li>
                    <Link to="/filter?degree=phd" className="h5">
                      Phd
                    </Link>
                  </li>
                  <li>
                    <Link to="/filter?degree=master" className="h5">
                      Masters
                    </Link>
                  </li>
                  <li>
                    <Link to="/filter?degree=bachelor" className="h5">
                      Bachelors
                    </Link>
                  </li>
                  <li>
                    <Link to="/filter?attendance=online" className="h5">
                      Online
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2">
                <h3 className="h4 footer-menu-title">Pages</h3>
                <ul>
                  <li>
                    <Link to="/" className="h5">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="h5">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="h5">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="h5">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3">
                <h3 className="h4 footer-menu-title">Take Help</h3>
                <ul>
                  <li>
                    <Link to="/sop" className="h5">
                      Get Your SOP
                    </Link>
                  </li>
                  <li>
                    <Link to="/ielts" className="h5">
                      IELTS
                    </Link>
                  </li>
                  <li>
                    <Link to="/apply" className="h5">
                      Apply
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" className="h5">
                      Faq
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3">
                <h3 className="h4 footer-menu-title">Contact Us</h3>
                <ul>
                  <li>
                    <p className="h5">
                      Palbari, Jashore
                    </p>
                  </li>
                  <li>
                    <p className="h5">
                      sunnymirza13@gmail.com
                    </p>
                  </li>
                  <li>
                    <p className="h5">
                      saikatkr034@gmail.com
                    </p>
                  </li>
                  <li>
                    <p className="h5">
                      +8801682175983
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="fotter-divider"></div>
        <div className="footer-bottom app-width">
          <p>
            &copy; 2022 All rights reserved by scholiguide.com
            {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
          </p>
          {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
        </div>
        <div className="fotter-left-shape">
          <img src={footerLeftShape} alt="scholify" />
        </div>
        <div className="fotter-right-shape">
          <img src={footerRightShape} alt="scholify" />
        </div>
      </div>
    </>
  );
}
