import axios from "axios";

export const gerFilterDistinctData = async (cb) => {
  try {
    const { data: country } = await axios.get(
      `/api/scholarship/distinct?field=country`
    );

    if (country?.data?.length > 0) {
      cb(
        country?.data?.map((item) => {
          return {
            name: item,
            category: "country",
          };
        })
      );
    }
    const { data: fullDegree } = await axios.get(
      `/api/scholarship/distinct?field=fullDegree`
    );
    if (fullDegree?.data?.length > 0) {
      cb(
        fullDegree?.data?.map((item) => {
          return {
            name: item,
            category: "degree",
          };
        })
      );
    }
    const { data: discipline } = await axios.get(
      `/api/scholarship/distinct?field=discipline`
    );
    if (discipline?.data?.length > 0) {
      cb(
        discipline?.data?.map((item) => {
          return {
            name: item,
            category: "discipline",
          };
        })
      );
    }
    const { data: university } = await axios.get(
      `/api/scholarship/distinct?field=university`
    );
    if (university?.data?.length > 0) {
      cb(
        university?.data?.map((item) => {
          return {
            name: item,
            category: "university",
          };
        })
      );
    }
    const { data: language } = await axios.get(
      `/api/scholarship/distinct?field=language`
    );
    if (language?.data?.length > 0) {
      cb(
        language?.data?.map((item) => {
          return {
            name: item,
            category: "language",
          };
        })
      );
    }
  } catch (err) {
    cb([]);
  }
};
