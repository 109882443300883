import React from "react";
import {
  blue,
  darkText,
  deepSkyBlue,
  deepSkyBlueLight,
  grayText,
  green,
  lightText,
  mediumPurple,
  meritimeBlue,
  midText,
  neoGreen,
  orange,
  orangeLight,
  purple,
  sky,
  skyBlue,
  white,
} from "../../utility/customColor";

export default function ColorCode({ index, tabIndex }) {
  return (
    <>
      {index === tabIndex && (
        <div className="single-search-item-body">
          <div className="row">
            <div className="col-12">
              {/* text color */}
              <div>
                <h2>Text Color</h2>
                <ul className="box-list">
                  <li className="box" style={{ background: darkText }}>
                    darkText
                  </li>
                  <li className="box" style={{ background: midText }}>
                    midText
                  </li>
                  <li className="box" style={{ background: lightText }}>
                    lightText
                  </li>
                  <li className="box" style={{ background: white }}>
                    white
                  </li>
                </ul>
                <div className="hr-bottom-line"></div>
              </div>

              {/* border color */}
              <div style={{ margin: "15px 0" }}>
                <h2>Border Color</h2>
                <ul className="box-list">
                  <li className="box" style={{ background: grayText }}>
                    grayText
                  </li>
                </ul>
                <div className="hr-bottom-line"></div>
              </div>

              {/* primay color */}
              <div style={{ margin: "15px 0" }}>
                <h2>Primary Color</h2>
                <ul className="box-list">
                  <li className="box" style={{ background: blue }}>
                    blue
                  </li>
                  <li className="box" style={{ background: sky }}>
                    sky
                  </li>
                </ul>
                <div className="hr-bottom-line"></div>
              </div>

              {/* secondary color */}
              <div style={{ margin: "15px 0" }}>
                <h2>Secondary Color</h2>
                <ul className="box-list">
                  <li className="box" style={{ background: orange }}>
                    orange
                  </li>
                  <li className="box" style={{ background: skyBlue }}>
                    skyBlue
                  </li>
                  <li className="box" style={{ background: purple }}>
                    purple
                  </li>
                  <li className="box" style={{ background: deepSkyBlue }}>
                    deepSkyBlue
                  </li>
                  <li className="box" style={{ background: neoGreen }}>
                    neoGreen
                  </li>
                  <li className="box" style={{ background: mediumPurple }}>
                    mediumPurple
                  </li>
                  <li className="box" style={{ background: green }}>
                    green
                  </li>
                </ul>
                <div className="hr-bottom-line"></div>
              </div>

              {/* tertiary color */}
              <div style={{ margin: "15px 0" }}>
                <h2>Tertiary Color</h2>
                <ul className="box-list">
                  <li className="box" style={{ background: orangeLight }}>
                    orangeLight
                  </li>
                  <li className="box" style={{ background: deepSkyBlueLight }}>
                    deepSkyBlueLight
                  </li>
                  <li className="box" style={{ background: meritimeBlue }}>
                    meritimeBlue
                  </li>
                </ul>
                <div className="hr-bottom-line"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
