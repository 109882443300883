import { MailOutlineOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import logo from "../../assets/logos/logo.png";
import LoginInput from "../../common/LoginInput";
import { resetPasswordApi } from "./controller/api";

const initData = {
  eamil: "",
};

const validationSchema = Yup.object().shape({});

export default function ForgotPassword({
  singupVerification,
  setForgotShow,
  setShow,
}) {
  const [isSent, setIsSent] = useState(false);
  const [loadingSendResetPassMsg, setLoadingSendResetPassMsg] = useState();

  useEffect(() => {
    if (singupVerification?.title) {
      setIsSent(true);
    }
  }, [singupVerification?.title]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initData}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {}}
      >
        {({
          handleSubmit,
          resetForm,
          values,
          errors,
          touched,
          setFieldValue,
          isValid,
          setValues,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Form>
              <div className="auth-wrapper-page flex-center">
                <div className="auth-login-wrapper">
                  <div className="logo-img">
                    <img src={logo} alt="scholify" />
                  </div>
                  <div className="auth-login-body auth-sign-body">
                    {isSent ? (
                      <h2 className="auth-title h2 blue">
                        {singupVerification?.title || "Instruction Sent"}
                      </h2>
                    ) : (
                      <h2 className="auth-title h2 blue">Reset Password</h2>
                    )}

                    {isSent ? (
                      <p className="auth-subtitle-para para3">
                        {singupVerification?.body ||
                          "Please check email inbox (including spam folder) for an email with password reset instructions"}
                      </p>
                    ) : (
                      <p className="auth-subtitle-para para3">
                        Enter your email address below. We'll send you
                        instructions to reset your password.
                      </p>
                    )}

                    <div className="row">
                      {!isSent && (
                        <div className="col-12">
                          <div className="auth-form-input">
                            <div className="auth-label">
                              <label className="label">Email Address: *</label>
                            </div>
                            <LoginInput
                              classes="input-sm"
                              value={values?.email}
                              name="email"
                              type="email"
                              trailicon={<MailOutlineOutlined />}
                              placeholder="Email..."
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                      )}

                      {!singupVerification?.title ? (
                        <>
                          {isSent ? (
                            <div className="col-12 auth-footer-button">
                              <button
                                type="button"
                                className="btn btn-medium btn-sky w-100"
                                onClick={() => {
                                  setForgotShow(false);
                                  setShow(true);
                                }}
                              >
                                Go Back To Login
                              </button>
                            </div>
                          ) : (
                            <div className="col-12 auth-footer-button">
                              <button
                                type="button"
                                className="btn btn-medium btn-sky w-100"
                                onClick={() => {
                                  console.log("email", values?.email);
                                  if (values?.email) {
                                    resetPasswordApi(
                                      {
                                        email: values?.email,
                                      },
                                      setLoadingSendResetPassMsg,
                                      () => {
                                        setIsSent(true);
                                      }
                                    );
                                  }
                                }}
                              >
                                Send Reset Instructions
                                {loadingSendResetPassMsg ? (
                                  <CircularProgress size={15} color="inherit" />
                                ) : null}
                              </button>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>

                    {!isSent && (
                      <p
                        className="auth-footer-btn sky"
                        onClick={() => {
                          setForgotShow(false);
                          setShow(true);
                        }}
                      >
                        Back To Login
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
