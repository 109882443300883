import { LockOutlined, MailOutlined } from "@mui/icons-material";
import { Backdrop, CircularProgress } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import gmailLogin from "../../assets/images/gmailLogin.png";
import logo from "../../assets/logos/logo.png";
import DefaultInput from "../../common/DefaultInput";
import FormikCheckBox from "../../common/FormikCheckbox";
import { sky } from "../../utility/customColor";
import useSignupController from "./controller/signupController";

export default function SignIn({ setLoginShow, setForgotShow, setShow, setShowMenu }) {
  const { responseGoogle, CLIENT_ID, loading, formikprops } =
    useSignupController({
      setLoginShow,
      setForgotShow,
      setShow,
      setShowMenu
    });

  const { values, errors, touched, setFieldValue, handleSubmit } = formikprops;

  return (
    <>
      {/* Loading */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit}>
        <div className="auth-wrapper-page flex-center">
          <div className="auth-login-wrapper">
            <div className="logo-img">
              <img src={logo} alt="scholify" />
            </div>
            <div className="auth-login-body auth-sign-body">
              <h2 className="auth-title h2 blue">Login to Scholiguide!</h2>

              {/* Google Auth Button */}
              <GoogleLogin
                clientId={CLIENT_ID}
                render={(renderProps) => (
                  <button
                    type="button"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="auth-signin-btn"
                  >
                    <img src={gmailLogin} alt="scholify" />
                  </button>
                )}
                onSuccess={(res) => responseGoogle(res, values)}
                onFailure={(res) => responseGoogle(res, values)}
                cookiePolicy={"single_host_origin"}
              />

              <p className="auth-subtitle h5 h5-bold">OR SIGNUP WITH EMAIL</p>
              <div className="row">
                <div className="col-12">
                  <div className="auth-label">
                    <label className="label">Email Address: *</label>
                  </div>
                  <DefaultInput
                    classes="input-sm"
                    value={values?.email}
                    name="email"
                    type="email"
                    placeholder="saikatkr034@gmail.com"
                    trailicon={<MailOutlined sx={{ fontSize: "16px" }} />}
                    errors={errors}
                    touched={touched}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <div className="auth-label">
                    <label className="label">Password: *</label>
                  </div>
                  <DefaultInput
                    classes="input-sm"
                    value={values?.password}
                    name="password"
                    type="password"
                    placeholder=" "
                    passwordicon={<LockOutlined sx={{ fontSize: "16px" }} />}
                    errors={errors}
                    touched={touched}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <div className="flex-between auth-forget-section">
                    <FormikCheckBox
                      styleObj={{
                        color: sky,
                        labelFontSize: "14px!important",
                      }}
                      name="isRemember"
                      checked={values?.isRemember}
                      onChange={(e) => {
                        setFieldValue("isRemember", e.target.checked);
                      }}
                      label="Remember Me"
                    />
                    <p
                      style={{ cursor: "pointer" }}
                      className="o-cursor-pointer auth-forget"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow(false);
                        setForgotShow(true);
                      }}
                    >
                      Forget Password ?
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-medium btn-sky w-100"
                    onClick={() => {
                      setFieldValue("registerClick", false);
                      handleSubmit();
                    }}
                  >
                    Log In To Your Account{" "}
                  </button>
                </div>
                <div className="col-12 mt-2">
                  <button
                    type="button"
                    className="btn btn-outline btn-medium btn-sky-outline w-100"
                    onClick={() => {
                      setFieldValue("registerClick", true);
                      handleSubmit();
                    }}
                  >
                    Register a new account{" "}
                  </button>
                </div>
              </div>
              {/* <p className="para3 para-bold auth-footer-para auth-footer-sign-para">
                Haven't had a Scholify account?
                <span
                  onClick={() => {
                    setFieldValue("registerClick", true);
                    handleSubmit();
                  }}
                >
                  {" "}
                  Register now
                </span>
              </p> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
