import React, { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";

export default function Degree() {
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  return (
    <>
      <div
        className="layout-body layout-scroll scrollbar-remove blank-left-side"
        ref={scrollRef}
      >
        <h2>Degree</h2>
      </div>
    </>
  );
}
