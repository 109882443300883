import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Bookmark,
  BookmarkBorderOutlined,
  NotificationsActive,
  NotificationsNoneOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import schoolIcon from "../../assets/images/school.png";
import {
  setLocationPathAction,
  setMylistAction,
  setMyNotifications,
} from "../../commonRedux/reduxForLocalStorage/action";
import { getAllNotifications, getNotificationData } from "../../router/helper";
import { black, deepSky, gray } from "../../utility/customColor";
import { postAddToMyListApi, postNotificationGererate } from "./helper";

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export default function SchoarshipComponent({ data, loading }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loadingMyList, setLoadingMyList] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);

  const { myList, myNotifications } = useSelector(
    (state) => state.localStorage
  );

  const { profileData } = useSelector((state) => state.auth);

  const myListHandler = (item) => {
    if (!profileData?.id) {
      return toast.warning("Please signin first");
    }

    if (loadingMyList) return;

    if (myList[`${item?._id}`]) {
      postAddToMyListApi(
        {
          type: "remove",
          payload: {
            userId: profileData?.id,
            scholarshipId: item?._id,
          },
        },
        setLoadingMyList,
        () => {
          dispatch(
            setMylistAction({
              ...myList,
              [`${item?._id}`]: undefined,
            })
          );
        }
      );
    } else {
      postAddToMyListApi(
        {
          type: "add",
          payload: {
            userId: profileData?.id,
            scholarshipId: item?._id,
          },
        },
        setLoadingMyList,
        () => {
          dispatch(
            setMylistAction({
              ...myList,
              [`${item?._id}`]: true,
            })
          );
        }
      );
    }
  };

  const notificaionGeneratorHandler = (item) => {
    if (myNotifications[`${item?._id}`]) {
      const payload = {
        type: "remove",
        scholarshipId: item?._id,
        userId: profileData?.id,
      };
      getAllNotifications(
        payload,
        () => {
          toast.success(
            "You will not get any notifications from this scholarship"
          );
          dispatch(
            setMyNotifications({
              ...myNotifications,
              [`${item?._id}`]: undefined,
            })
          );
          getNotificationData(profileData, dispatch); // Refetch all data
        },
        setLoadingNotification
      );
      return;
    }

    let applyDate = item?.others?.applyDate;
    let startDate = item?.others?.startDate;

    if (applyDate?.length > 10) {
      applyDate = applyDate.split("  ")[1];
    }

    if (startDate?.length > 10) {
      startDate = startDate.split("  ")[1];
    }

    // Regex For All String and all number
    const regex = /[A-Za-z]+/g;
    const regexNumber = /[0-9]+/g;

    /* Finding Month and Year */
    let startYear = startDate.match(regexNumber);
    let applyYear = applyDate.match(regexNumber);
    // Finding Year
    startYear = startYear ? startYear.join("") : startYear;
    applyYear = applyYear ? applyYear.join("") : applyYear;

    /* This is Commented for university notification generate */
    // if (!startYear && !applyYear) {
    //   return;
    // }

    let startMonth = startDate.match(regex);
    let applyMonth = applyDate.match(regex);

    startMonth = startMonth ? startMonth.join("") : startMonth;
    applyMonth = applyMonth ? applyMonth.join("") : applyMonth;

    let startFullDateGen;
    let applyFullDateGen;

    if (months[`${startMonth}`]) {
      startFullDateGen = [
        `${months[`${startMonth}`]}-${10}-${startYear}`,
        `${months[`${startMonth}`]}-${20}-${startYear}`,
        `${months[`${startMonth}`]}-${25}-${startYear}`,
        `${moment(`${months[`${startMonth}`]}`, "MM")
          .endOf("month")
          .format("MM-DD-YYYY")}`,
      ];
    }
    if (months[`${applyMonth}`]) {
      applyFullDateGen = [
        `${months[`${applyMonth}`]}-${11}-${applyYear}`,
        `${months[`${applyMonth}`]}-${21}-${applyYear}`,
        `${months[`${applyMonth}`]}-${26}-${applyYear}`,
        // `${moment(`${months[`${applyMonth}`]} ${applyYear}`, "MM YYYY")
        //   .endOf("month")
        //   .format("MM-DD-YYYY")}`, // This will be the last day of a month
      ];
    }
    let currentDYM = new Date();
    console.log(currentDYM.getMonth() + 1);
    const payload = {
      scholarshipId: item?._id,
      userId: profileData?.id,
      versityName: item?.university,
      degree: item?.degree,
      deadlineNotify: applyFullDateGen,
      universityNotify: [
        `${months[`${applyMonth}`] || currentDYM.getMonth() + 1}-${11}-${applyYear || currentDYM.getFullYear()
        }`,
        `${months[`${applyMonth}`] || currentDYM.getMonth() + 1}-${21}-${applyYear || currentDYM.getFullYear()
        }`,
        `${months[`${applyMonth}`] || currentDYM.getMonth() + 1}-${26}-${applyYear || currentDYM.getFullYear()
        }`,
        // `${moment(`${months[`${applyMonth}`]} ${applyYear}`, "MM YYYY")
        //   .endOf("month")
        //   .format("MM-DD-YYYY")}`, // This will be the last day of a month
      ],
      // start: startFullDateGen,
    };

    console.log("Payload", payload, startFullDateGen);

    postNotificationGererate(payload, setLoadingNotification, () => {
      dispatch(
        setMyNotifications({
          ...myNotifications,
          [`${item?._id}`]: true,
        })
      );
    });
  };

  return (
    <>
      {data?.length > 0 && !loading ? (
        <div className="search-section-body">
          <div className="row">
            {data?.map((item, index) => {
              return (
                <div
                  className="col-md-6"
                  key={index}
                  style={{ padding: "0 7px", cursor: "pointer" }}
                >
                  <div
                    className="single-search-item"
                    key={index}
                  >
                    <div className="single-search-item-heading">
                      <ul
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`course/${item?._id}/${item?.title}`);
                          dispatch(setLocationPathAction("/"));
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <li>
                          <div className="single-search-item-heading-icon">
                            <img src={schoolIcon} alt="scholify" />
                          </div>
                        </li>
                        <li>
                          <h2 className="h3 h3-bold text-capitalize">
                            {item?.title}
                          </h2>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="single-search-item-body"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`course/${item?._id}/${item?.title}`);
                        dispatch(setLocationPathAction("/"));
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row single-search-item-inner-body">
                        <div className="col-lg-6 single-search-item-inner-body-left">
                          <div className="single-search-item-feature-wrapper">
                            <div className="single-search-item-feature">
                              <h3 className="h5 h5-bold">University</h3>
                              <p className="para4 text-capitalize">
                                {item?.university}
                              </p>
                            </div>

                            {item?.country ? (
                              <div className="single-search-item-feature">
                                <h3 className="h5 h5-bold">Country</h3>
                                <p className="para4 text-capitalize">
                                  {`${item?.country}${item?.continent
                                    ? `, ${item?.continent}`
                                    : ""
                                    }`}
                                </p>
                              </div>
                            ) : null}

                            <div className="single-search-item-feature">
                              <h3 className="h5 h5-bold">Course Fee</h3>
                              <p className="para4 text-capitalize">
                                {item?.others?.tutionValue}
                              </p>
                            </div>

                            <div className="single-search-item-feature">
                              <h4 className="half-funding text-capitalize">
                                {" "}
                                {item?.attendance}
                              </h4>
                            </div>
                            {/* <div className="single-search-item-feature">
                              {item?.isFullFunding ? (
                                <h4>{item?.remarks}</h4>
                              ) : (
                                <h4 className="half-funding">
                                  {item?.remarks}
                                </h4>
                              )}
                            </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-search-item-feature-wrapper">
                            <div className="single-search-item-feature">
                              <h3 className="h5 h5-bold">Language</h3>
                              <p className="para4 text-capitalize">
                                {item?.language}
                              </p>
                            </div>

                            {/* <div className="single-search-item-feature">
                                <h3 className="h5 h5-bold">Language Test</h3>
                                <p className="para4">{item?.languageTest}</p>
                              </div> */}

                            {item?.others?.applyDate ? (
                              <div className="single-search-item-feature">
                                <h3 className="h5 h5-bold">Apply Date</h3>
                                <p className="para4">
                                  {item?.others?.applyDate?.length > 10
                                    ? item?.others?.applyDate?.split("  ")[1]
                                    : item?.others?.applyDate}
                                </p>
                              </div>
                            ) : null}

                            {item?.others?.startDate ? (
                              <div className="single-search-item-feature">
                                <h3 className="h5 h5-bold">Start Date</h3>
                                <p className="para4">
                                  {item?.others?.startDate?.length > 10
                                    ? item?.others?.startDate?.split("  ")[1]
                                    : item?.others?.startDate}
                                </p>
                              </div>
                            ) : null}

                            {item?.others?.durationValue ? (
                              <div className="single-search-item-feature">
                                <h3 className="h5 h5-bold">Duration</h3>
                                <p className="para4">
                                  {item?.others?.durationValue}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-search-item-footer">
                      <ul>
                        <li>
                          <a
                            href={item?.others?.applyLink}
                            className="btn btn-medium btn-sky "
                            style={{ marginRight: "10px" }}
                          >
                            Visit Scholarship Website
                            <span>
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </span>
                          </a>
                          <a
                            href={`course/${item?._id}/${item?.title}`}
                            className="btn btn-mid btn-blackWhite "
                          >
                            View details
                          </a>
                        </li>
                        <li>
                          <div className="single-search-icon-list">
                            <ul>
                              <li>
                                <div
                                  onClick={() =>
                                    notificaionGeneratorHandler(item)
                                  }
                                  className="ssi-box pointer"
                                >
                                  {loadingNotification === item?._id ? (
                                    <CircularProgress
                                      size={10}
                                      color="inherit"
                                    />
                                  ) : (
                                    <>
                                      {myNotifications &&
                                        myNotifications[`${item?._id}`] ? (
                                        <span className="animate-bounce">
                                          <NotificationsActive
                                            sx={{
                                              color: deepSky,
                                              fontSize: "16px",
                                            }}
                                          />
                                        </span>
                                      ) : (
                                        <span>
                                          <NotificationsNoneOutlined
                                            sx={{
                                              color: black,
                                              fontSize: "16px",
                                            }}
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    myListHandler(item);
                                  }}
                                  className="ssi-box pointer"
                                >
                                  {loadingMyList === item?._id ? (
                                    <CircularProgress
                                      size={10}
                                      color="inherit"
                                    />
                                  ) : (
                                    <>
                                      {myList && myList[`${item?._id}`] ? (
                                        <Bookmark
                                          sx={{
                                            color: deepSky,
                                            fontSize: "16px",
                                          }}
                                        />
                                      ) : (
                                        <BookmarkBorderOutlined
                                          sx={{
                                            color: black,
                                            fontSize: "16px",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {data?.length === 0 && !loading ? (
        <>
          <h4 style={{ color: gray }} className="text-center">
            No scholarship found
          </h4>
        </>
      ) : null}
    </>
  );
}
