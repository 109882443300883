import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";
// images
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Instagram, Twitter, YouTube } from "@mui/icons-material";
import DefaultInput from "../../common/DefaultInput";
import { midText, shadeDark } from "../../utility/customColor";

// images
import blogOne from "../../assets/images/blog/01.png";
import blogTwo from "../../assets/images/blog/02.png";
import blogImg from "../../assets/images/blog02.png";
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import sFacebook from "../../assets/images/icon/sFacebook.png";
import shareIcon from "../../assets/images/icon/share-alt.png";
import sLinkedin from "../../assets/images/icon/sLinkedin.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
import sTwitter from "../../assets/images/icon/sTwitter.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import personalImg from "../../assets/images/personalInfo.png";
import footerRightShape from "../../assets/images/rightShape.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";

const initData = {
  language: "",
  emailSubscribe: "",
};

export default function SingleBlog() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  const { values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      resetForm(initData);
    },
  });

  return (
    <>
      <form>
        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* blog hero section */}
          <div className="hero-section about-hero-section faq-hero-section bgOne">
            <div className="hero-section-content">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="hero-section-body about-section-body">
                    <h2 className="h1 darkText">Blog Details</h2>
                    <p className="para2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Convallis adipiscing consectetur ornare.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-person-img faq-section-person-img"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* single-blog-wrapper */}
          <div className="single-blog-section app-width">
            <div className="single-blog-wrapper">
              <div className="single-bolg-content">
                <div className="row">
                  <div className="col-12">
                    <div className="single-blog-title">
                      <h2 className="h1">
                        Fully Funded Scholarships for bright and intelligent
                        students
                      </h2>
                    </div>
                    <div className="blog-main-info-txt blog-share-groupe">
                      <div className="single-person">
                        {/* <div className="single-person-img">
                          <img src={personalImg} alt="scholify" />
                        </div>
                        <div className="single-person-body">
                          <h3>
                            <Link to="/blog" className="h4 h4-bold">
                              Mirza Sunny
                            </Link>
                          </h3>
                          <h6 className="para4">
                            Managing Director & Co-Founder
                          </h6>
                        </div> */}
                      </div>
                      <div className="share-icon-list">
                        <div className="share-icon-list-head">
                          <span>
                            <img src={shareIcon} alt="scholify" />
                          </span>
                          <span className="para4" style={{ marginLeft: "8px" }}>
                            Share This
                          </span>
                        </div>
                        <ul>
                          <li>
                            <Link to="/blog">
                              <img src={sFacebook} alt="scholify" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog">
                              <img src={sTwitter} alt="scholify" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog">
                              <img src={sLinkedin} alt="scholify" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog-main-info-img">
                      <img src={blogOne} alt="scholify" />
                    </div>
                    <div className="blog-main-info-txt">
                      <h2 className="h2 sky">Expand Your Portfolio</h2>
                      <p className="para3">
                        Microsoft Office skills, such as Word, Excel, and
                        Outlook are considered essential to working in a modern
                        office environment. However, there are a number of other
                        apps out there that you can use to differentiate
                        yourself from the rest of the job market by displacing
                        proficiency in them.
                      </p>
                      <p className="para3">
                        Apps like Slack, Trello, and even Powerpoint are all
                        useful tools that are used by a myriad of businesses and
                        organizations and you're being proficient in their use
                        can make you more valuable.
                      </p>
                      <h3 className="h3 h3-bold">
                        Be A Master Of the Office Equipment
                      </h3>
                      <p className="para3">
                        For most business administrative assistants, the office
                        equipment is their toolbox and a master always keeps
                        their tools in the best condition possible.
                      </p>
                      <h3 className="h3 h3-bold">
                        Maintain A Professional Demeanor
                      </h3>
                      <p className="para3">
                        As a business administrative assistant, you’ll be in
                        contact with both the general public, customers and
                        clients of your company, and your coworkers. While being
                        friendly and open is part of good communication,
                        remember than it is part of your role to be the face and
                        voice of the company.
                      </p>
                    </div>
                    <div className="blog-main-info-img">
                      <img src={blogTwo} alt="scholify" />
                    </div>
                    <div className="blog-main-info-txt">
                      <h2 className="h2 sky">Expand Your Portfolio</h2>
                      <p className="para3">
                        Microsoft Office skills, such as Word, Excel, and
                        Outlook are considered essential to working in a modern
                        office environment. However, there are a number of other
                        apps out there that you can use to differentiate
                        yourself from the rest of the job market by displacing
                        proficiency in them.
                      </p>
                      <p className="para3">
                        Apps like Slack, Trello, and even Powerpoint are all
                        useful tools that are used by a myriad of businesses and
                        organizations and you're being proficient in their use
                        can make you more valuable.
                      </p>
                    </div>
                    <div className="blog-main-info-txt">
                      <h2 className="h2 sky">Italitc/Quotation</h2>
                      <p className="para3">
                        As such, maintaining a professional demeanor, dressing
                        to suit your role, and developing a professional
                        telephone manner is a key part of being a fantastic
                        business administrative assistant.
                      </p>
                    </div>
                    <div className="blog-main-info-txt">
                      <h2 className="h2 sky">Bullets</h2>
                      <div className="single-search-item-feature">
                        <ul>
                          <li className="course-list">
                            <div className="bullet">
                              <Circle
                                sx={{
                                  fontSize: "8px",
                                  color: midText,
                                }}
                              />
                            </div>
                            <p className="para2">Trello, and even Powerpoint</p>
                          </li>
                          <li className="course-list">
                            <div className="bullet">
                              <Circle
                                sx={{
                                  fontSize: "8px",
                                  color: midText,
                                }}
                              />
                            </div>
                            <p className="para2">Trello, and even Powerpoint</p>
                          </li>
                          <li className="course-list">
                            <div className="bullet">
                              <Circle
                                sx={{
                                  fontSize: "8px",
                                  color: midText,
                                }}
                              />
                            </div>
                            <p className="para2">Trello, and even Powerpoint</p>
                          </li>
                          <li className="course-list">
                            <div className="bullet">
                              <Circle
                                sx={{
                                  fontSize: "8px",
                                  color: midText,
                                }}
                              />
                            </div>
                            <p className="para2">Trello, and even Powerpoint</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog-main-info-txt">
                      <h2 className="h2 sky">
                        Become A Critical Part of a Business Today
                      </h2>
                      <p className="para3">
                        Microsoft Office skills, such as Word, Excel, and
                        Outlook are considered essential to working in a modern
                        office environment. However, there are a number of other
                        apps out there that you can use to differentiate
                        yourself from the rest of the job market by displacing
                        proficiency in them.
                      </p>
                      <p className="para3">
                        Apps like Slack, Trello, and even Powerpoint are all
                        useful tools that are used by a myriad of businesses and
                        organizations and you're being proficient in their use
                        can make you more valuable.
                      </p>
                    </div>
                    <div className="blog-main-info-txt">
                      <div className="single-person">
                        <div className="single-person-img">
                          <img src={personalImg} alt="scholify" />
                        </div>
                        <div className="single-person-body">
                          <h3>
                            <Link to="/blog" className="h4 h4-bold">
                              Mirza Sunny
                            </Link>
                          </h3>
                          <h6 className="para4">
                            Managing Director & Co-Founder
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="blog-main-info-txt">
                      <ul className="tag-list">
                        <li className="single-tag">
                          <h5 className="h5">Learn Design</h5>
                        </li>
                        <li className="single-tag">
                          <h5 className="h5">Criminal Justice</h5>
                        </li>
                        <li className="single-tag">
                          <h5 className="h5">ESL Courses</h5>
                        </li>
                        <li className="single-tag">
                          <h5 className="h5">Dental Assisting</h5>
                        </li>
                        <li className="single-tag">
                          <h5 className="h5">CPR</h5>
                        </li>
                        <li className="single-tag">
                          <h5 className="h5">Dental</h5>
                        </li>
                        <li className="single-tag">
                          <h5 className="h5">Business</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="subscriber-newsletter">
                <h2 className="h3 h3-bold">Subscribe To Our Newsletter</h2>
                <p className="para2">
                  Don't miss any updates of our new blog post.
                </p>
                <div className="subscribe-from footer-form">
                  <DefaultInput
                    classes="input-large newsletter-input"
                    value={values?.emailSubscribe}
                    name="emailSubscribe"
                    type="text"
                    placeholder="Email address..."
                    errors={errors}
                    touched={touched}
                    onChange={(e) =>
                      setFieldValue("emailSubscribe", e.target.value)
                    }
                  />
                  <button type="button" className="btn btn-large btn-orange">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* blog-card section */}
          <div className="blog-card-section">
            <div className="blog-card-section-content app-width">
              <div className="row">
                {[1, 2, 3]?.map((item, index) => {
                  return (
                    <div className="col-lg-4 col-md-6">
                      <div className="single-blog-card">
                        <div className="single-blog-card-img">
                          <img src={blogImg} alt="scholify" />
                        </div>
                        <div className="single-blog-txt">
                          <div className="single-blog-txt-info">
                            <p className="para4">
                              Written by
                              <Link to="/blog" className="para4">
                                Mirza Sunny
                              </Link>
                              <Circle
                                sx={{
                                  color: shadeDark,
                                  margin: "0 5px 0 0",
                                  fontSize: "6px",
                                }}
                              />
                              <Circle
                                sx={{
                                  color: shadeDark,
                                  margin: "0 5px 0 0",
                                  fontSize: "6px",
                                }}
                              />
                              <span className="time para4">
                                <b>5 min</b> read
                              </span>
                              <Circle
                                sx={{
                                  color: shadeDark,
                                  margin: "0 5px 0 0",
                                  fontSize: "6px",
                                }}
                              />
                              <span className="date para4">18 July 2022</span>
                            </p>
                          </div>
                          <h2 className="h4">
                            <Link to={`/blog/${index}`} className="h4">
                              Famous Abroad Study Scholarships: Dream Catcher!
                            </Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* contact with counselor section */}
          <div
            style={{ marginTop: "16px" }}
            className="connect-counselor-section app-width"
          >
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Discover The Ideal Place For You With The Assist Of Our
                Counselor
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-large btn-darkOrange"
                  style={{ fontSize: "15px !important" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Connect With Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
