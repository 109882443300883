import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";

export const postChangedPasswordApi = async (payload, setLoading, cb) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.post(
      "/api/auth/verify-reset-password-mail",
      payload
    );
    if (res?.status === 200) {
      toast.success(res?.data?.message, {
        toastId: 1231124112,
      });
      setLoading && setLoading(false);
      cb();
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, {
      toastId: 1231124112,
    });
    setLoading && setLoading(false);
  }
};

export const validationSchemaResetPassword = Yup.object({
  password: Yup.string()
    .required("Please enter your password.")
    .min(6, "Your password must be at least 6 characters long."),
  confirmPassword: Yup.string()
    .required("Please retype your password.")
    .oneOf([Yup.ref("password")], "Your passwords do not match."),
});
