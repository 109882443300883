import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { midText } from "../utility/customColor";

function FormikCheckBox(props) {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          sx={{
            "&.MuiFormControlLabel-root .MuiTypography-root": {
              fontSize: props?.styleObj?.labelFontSize || "1rem",
              color: props?.styleObj?.labelColor || midText,
              margin: props?.styleObj?.labelMargin || "-4px",
            }
          }}
          control={
            <Checkbox
              name={props?.name}
              onChange={props?.onChange}
              checked={props?.checked ? props?.checked : false}
              disabled={props?.disabled ? props?.disabled : false}
              sx={{
                color: props?.styleObj?.color,
                margin: props?.styleObj?.margin,
                width: props?.styleObj?.width,
                height: props?.styleObj?.height,
                padding: props?.styleObj?.padding,
                "&.Mui-checked": {
                  color: `${props?.styleObj?.color}!important`,
                  "&.Mui-disabled": {
                    color: props?.styleObj?.color,
                    opacity: 0.38,
                  },
                },
                "&.MuiCheckbox-root .MuiSvgIcon-root": {
                  height: props.height || "16px"
                }
              }}
            />
          }
          label={props?.label ? props?.label : ""}
        />
      </FormGroup>
    </>
  );
}

export default FormikCheckBox;

/*
   <FormikCheckBox
      styleObj={{
         margin: '0 auto!important',
         color: greenColor
      }}
      name="checkboxTwo"
      label="One"
      checked={values?.checkboxTwo}
      onChange={(e) => {
         setFieldValue("checkboxTwo", e.target.checked);
      }}
      disabled={true}
   />
*/
