import About from "../modules/about";
import Blog from "../modules/blog";
import SingleBlog from "../modules/blog/singleBlog";
import Contact from "../modules/contact";
import CourseDetails from "../modules/course";
import Design from "../modules/design";
import FAQ from "../modules/faq";
import FilterScholarship from "../modules/filter";
import Home from "../modules/home";
import IeltsLanding from "../modules/ielts";
import MyListPage from "../modules/myList";
import Notification from "../modules/notification";
import ResetPassword from "../modules/resetPassword";
import SearchPage from "../modules/search";
import Sop from "../modules/sop";
import VisaProcessing from "../modules/visaProcessing";
import Degree from "./../modules/degree/index";

export const routingList = [
  { path: "/", component: Home },
  { path: "/about", component: About },
  { path: "/sop", component: Sop },
  { path: "/blog", component: Blog },
  { path: "/blog/:id", component: SingleBlog },
  { path: "/faq", component: FAQ },
  { path: "/phd", component: Degree },
  { path: "/apply", component: VisaProcessing },
  { path: "/course/:id/:title", component: CourseDetails },
  { path: "/notification", component: Notification },
  { path: "/design", component: Design },
  { path: "/search", component: SearchPage },
  { path: "/reset-password", component: ResetPassword },
  { path: "/my-list", component: MyListPage },
  { path: "/filter", component: FilterScholarship },
  { path: "/ielts", component: IeltsLanding },
  { path: "/contact", component: Contact },
];
