import { Circle } from "@mui/icons-material";
import React from "react";

export default function Requirements({ index, tabIndex, singleCourse }) {
  return (
    <>
      {index === tabIndex && (
        <div className="single-search-item-body">
          <div className="row single-search-item-inner-body">
            <div className="col-md-6 single-search-item-inner-body-left">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Score requirements</h3>
                {/* <p>
                  A-Level English skills are a requirement. They can be
                  demonstrated either by providing certificates or by having
                  taken English courses in previous studies. Accepted
                  certificates:
                </p> */}
              </div>
              <div className="single-search-item-feature">
                <ul>
                  {singleCourse?.others?.ieltsScore ? (
                    <li className="course-list">
                      <div className="bullet">
                        <Circle
                          sx={{
                            fontSize: "8px",
                            color: "#1c2b3c",
                          }}
                        />
                      </div>
                      <p>IELTS {singleCourse?.others?.ieltsScore}</p>
                    </li>
                  ) : null}

                  {singleCourse?.others?.toeflScore ? (
                    <li className="course-list">
                      <div className="bullet">
                        <Circle
                          sx={{
                            fontSize: "8px",
                            color: "#1c2b3c",
                          }}
                        />
                      </div>
                      <p>
                        TOEFL Internet-based: minimum{" "}
                        {singleCourse?.others?.toeflScore} points
                      </p>
                    </li>
                  ) : null}

                  {singleCourse?.others?.pteScore ? (
                    <li className="course-list">
                      <div className="bullet">
                        <Circle
                          sx={{
                            fontSize: "8px",
                            color: "#1c2b3c",
                          }}
                        />
                      </div>
                      <p>
                        PTE Score:
                        {singleCourse?.others?.pteScore} points
                      </p>
                    </li>
                  ) : null}

                  {singleCourse?.others?.gpaScore ? (
                    <li className="course-list">
                      <div className="bullet">
                        <Circle
                          sx={{
                            fontSize: "8px",
                            color: "#1c2b3c",
                          }}
                        />
                      </div>
                      <p>
                        GPA Score:
                        {singleCourse?.others?.gpaScore} points
                      </p>
                    </li>
                  ) : null}
                </ul>
              </div>

              {/* <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Application deadline</h3>
                <p>15 July for the following winter semester</p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Submit application to</h3>
                <p>Technische Universität Chemnitz</p>
                <p> c/o uni-assist e.V.</p>
                <p>11507 Berlin</p>
                <p>Germany</p>
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Other's requirements</h3>
                <p>{singleCourse?.others?.otherReqirements?.title}</p>
              </div>
              <div className="single-search-item-feature">
                <ul>
                  {singleCourse?.others?.otherReqirements?.list?.map((item) => (
                    <li className="course-list">
                      <div className="bullet">
                        <Circle
                          sx={{
                            fontSize: "8px",
                            color: "#1c2b3c",
                          }}
                        />
                      </div>
                      <p>{item}</p>
                    </li>
                  ))}
                </ul>
              </div>

              {/* <div className="single-search-item-feature">
                <p>
                  Applicants from countries with English as the official or
                  native language are not required to submit a proof of English
                  language proficiency with their applications
                </p>
              </div>
              <div className="single-search-item-feature">
                <p>
                  German: Courses to obtain the required level of German
                  language (A2 by the end of the third semester) are included in
                  the curriculum for non-native speakers. Students providing a
                  certificate of A2 level (CEFR) may choose advanced language
                  courses or subject studies.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
