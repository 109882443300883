import React from "react";

export default function SectionTitle({ title, img }) {
  return (
    <div className="section-title">
      <h2>{title}</h2>
      {img && (
        <div>
          <img src={img} alt="Scholiguide" />
        </div>
      )}
    </div>
  );
}
