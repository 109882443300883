import axios from "axios";

export const getVisaProcessingDistintCountry = async (cb, setLoading) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.get("/api/visaProcess/distinct?field=country");
    if (res?.status === 200) {
      setLoading && setLoading(false);
      cb(res?.data?.data);
    }
  } catch (err) {
    setLoading && setLoading(false);
  }
};

export const getVisaProcessingData = async (payload, cb, setLoading) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.post(
      "/api/visaProcess/filter?skip=0&limit=10",
      payload
    );
    if (res?.status === 200) {
      setLoading && setLoading(false);
      cb(res?.data?.data);
    }
  } catch (err) {
    setLoading && setLoading(false);
  }
};
