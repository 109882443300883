import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Instagram, Twitter, YouTube } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../common/SectionTitle";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";
import { midText } from "../../utility/customColor";

// images
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import heroShape from "../../assets/images/hero-shape.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
import aboutIELTS from "../../assets/images/sop/06.png";
import purposeIelts from "../../assets/images/sop/07.png";
import sopLogo from "../../assets/images/sop/08.png";
import sopPerson from "../../assets/images/sop/09.png";
import ieltsFeature from "../../assets/images/sop/10.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";

export default function IeltsLanding() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  return (
    <>
      <div
        className="layout-body layout-scroll scrollbar-remove"
        ref={scrollRef}
      >
        {/* sop hero section */}
        <div className="hero-section about-hero-section bgOne">
          <div className="hero-section-content">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="hero-section-body about-section-body">
                  <h2 className="h1 darkText">IELTS Preparation</h2>
                  <p className="para2">
                    Take your preparation at home with the help of a top notch
                    team of professional instructors from home and abroad.
                  </p>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <div className="hero-section-img-content">
                  <div className="hero-section-scholership-img hero-section-ielts-img">
                    <img src={sopLogo} alt="scholiguide" />
                  </div>
                  <div className="hero-section-shape">
                    <img src={heroShape} alt="scholiguide" />
                  </div>
                  <div className="hero-section-person-img">
                    <img src={sopPerson} alt="scholiguide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* scholarship portal section */}
        <div className="scholarship-portal-section">
          <div className="scholarship-portal-section-content app-width">
            <SectionTitle title="Our IELTS Instructors will help you get the score you need" />
            <div className="card-info-body portal-info-body">
              <div className="card-list">
                <div className="row">
                  <div className="col-12">
                    <div className="card-full-img">
                      <img src={purposeIelts} alt="scholiguide" />
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="card-info-txt">
                          <p className="para3">
                            Take a look at the services available for you so you
                            can acquire that desired band.
                          </p>
                          <div className="card-bullet-point">
                            <ul>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  10 basic classes on each module; a total of 40
                                  basic classes where you will get to learn the
                                  nooks and crannies of IELTS.
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  There will be a total of 40 hours of guidance
                                  period where you will get the opportunity to
                                  discuss the problems you are facing with an
                                  instructor.
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  You will be offered a number of full mock
                                  tests and partial tests in a complete exam
                                  environment based on your plan.
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Every week there will be multiple language
                                  clubs on every modules there you can sharpen
                                  your language skills.
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  build towards the IELTS score you need to
                                  continue your studies abroad
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  build towards the IELTS score you need to
                                  continue your studies abroad
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="card-info-txt ielts-card-booking-card">
                          <h2>
                            Use our online booking system to book a free
                            consultation
                          </h2>
                          <div>
                            <button
                              type="button"
                              className="btn btn-large btn-darkOrange"
                              style={{ fontSize: "15px !important" }}
                            >
                              Connect With Counselor
                              <span>
                                <FontAwesomeIcon icon={faArrowRightLong} />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* hm-about-section */}
        <div className="hm-about-section sop-about-section bgOne">
          <div className="hm-about-content app-width">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="timeline-scholership-txt section-text">
                  <h2 className="title">Choose Your Plan</h2>
                  <h3 className="sub-title">Full time course (online)</h3>
                  <p className="para hm-about-para">
                    You will be put in a online batch where you will get 40
                    Classes, 40 hours of Guidance period, 03 Language Club per
                    week, 05 full mock test and 15 partial test. Total duration
                    of the course is 03 months.
                  </p>
                  <h3 className="sub-title">One on One Course (online)</h3>
                  <p className="para">
                    One or multiple instructors will be assigned for you and you
                    will get 40 Classes, 40 hours of Guidance period, 03
                    Language Club per week, 10 full mock tests and 20 partial
                    tests. Total duration of the course is 03 months.
                  </p>
                  <h3 className="sub-title">Package deal</h3>
                  <p className="para">
                    You will be given a complete recorded course of 40 classes.
                  </p>
                  <div>
                    <div className="d-flex align-items-center">
                      <button type="button" className="btn btn-mid btn-green">
                        Get Ready To Start
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="hm-about-content-img">
                  <img src={aboutIELTS} alt="scholiguide" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* hm-feature section */}
        <div className="hm-feature-section ielts-feature-section">
          <div className="hm-feature-content ielts-feature-content app-width">
            <SectionTitle title="An unique training design to guide you to your destination" />
            <div className="ielts-feature-img">
              <img src={ieltsFeature} alt="scholiguide" />
            </div>
          </div>
        </div>
        {/* faq-card-section */}
        <div className="faq-card-section bgOne ielts-card-section">
          <div className="faq-card-section-content app-width">
            <div className="card-info-heading">
              <SectionTitle title="FAQs" />
            </div>
            <div className="card-info-body">
              <ul className="faq-list">
                <li>
                  <h2 className="h4">
                    Question: What kind of score do I need to study abroad?
                  </h2>
                  <p>
                    Answer: The benchmark in IELTS is 6.00 which is enough for
                    application in most universities
                  </p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: What is the highest score in IELTS?
                  </h2>
                  <p>Answer: The highest band is 9.00.</p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: How many types of IELTS are there?
                  </h2>
                  <p>
                    Answer: There are two different approaches for IELTS:
                    General training and Academic IELTS.
                  </p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: Is IELTS helpful for getting a scholarship?
                  </h2>
                  <p>
                    Answer: In some cases, yes a good score can get you a
                    scholarship, at least it increases your chances.
                    {/* <Link to="/">www.scholiguide.com</Link> */}
                  </p>
                </li>
                <li>
                  <h2 className="h4">Question: How many modules are there?</h2>
                  <p>
                    Answer: There are four modules : Listening, Reading, Writing
                    and Speaking.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* contact with counselor section */}
        <div
          style={{ marginTop: "16px" }}
          className="connect-counselor-section app-width"
        >
          <div className="connect-counselor-inner-section">
            <h2 className="h2">
              Discover The Ideal Place For You With The Assist Of Our Counselor
            </h2>
            <div>
              <button
                type="button"
                className="btn btn-large btn-darkOrange"
                style={{ fontSize: "15px !important" }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/contact");
                }}
              >
                Connect With Counselor
                <span>
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* desktop footer */}
        <div className="footer-section bgOne d-xl-block d-none">
          <div className="footer-top app-width">
            <div className="row footer-logo-content align-items-center">
              <div className="col-lg-8">
                <div className="footer-logo">
                  <img src={footerLogo} alt="scholify" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-social-network">
                  <ul>
                    <li>
                      <Link to="/">
                        <img src={faFacebook} alt="scholify" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <Twitter sx={{ fontSize: "20px" }} />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <YouTube sx={{ fontSize: "20px" }} />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src={faSnapchat} alt="scholify" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <Instagram sx={{ fontSize: "20px" }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-menu">
              <div className="footer-menu-left row">
                <div className="col-lg-3">
                  <h3 className="h4 footer-menu-title">Degrees</h3>
                  <ul>
                    <li>
                      <Link to="/filter?degree=phd" className="h5">
                        Phd
                      </Link>
                    </li>
                    <li>
                      <Link to="/filter?degree=master" className="h5">
                        Masters
                      </Link>
                    </li>
                    <li>
                      <Link to="/filter?degree=bachelor" className="h5">
                        Bachelors
                      </Link>
                    </li>
                    <li>
                      <Link to="/filter?attendance=online" className="h5">
                        Online
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2">
                  <h3 className="h4 footer-menu-title">Pages</h3>
                  <ul>
                    <li>
                      <Link to="/" className="h5">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" className="h5">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog" className="h5">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="h5">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h3 className="h4 footer-menu-title">Take Help</h3>
                  <ul>
                    <li>
                      <Link to="/sop" className="h5">
                        Get Your SOP
                      </Link>
                    </li>
                    <li>
                      <Link to="/ielts" className="h5">
                        IELTS
                      </Link>
                    </li>
                    <li>
                      <Link to="/apply" className="h5">
                        Apply
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="h5">
                        Faq
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h3 className="h4 footer-menu-title">Contact Us</h3>
                  <ul>
                    <li>
                      <p className="h5">Palbari, Jashore</p>
                    </li>
                    <li>
                      <p className="h5">sunnymirza13@gmail.com</p>
                    </li>
                    <li>
                      <p className="h5">saikatkr034@gmail.com</p>
                    </li>
                    <li>
                      <p className="h5">+8801682175983</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="fotter-divider"></div>
          <div className="footer-bottom app-width">
            <p>
              &copy; 2022 All rights reserved by scholiguide.com
              {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
            </p>
            {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
          </div>
          <div className="fotter-left-shape">
            <img src={footerLeftShape} alt="scholify" />
          </div>
          <div className="fotter-right-shape">
            <img src={footerRightShape} alt="scholify" />
          </div>
        </div>
        <div className="layout-body">
          <div className="row">
            <div className="col-12">
              {/* footer */}
              <div className="res-footer-inner d-xl-none d-block">
                <div className="res-footer-menu">
                  <div className="res-footer-menu-img">
                    <Link to="/">
                      <img src={schoify} alt="scholify" />
                    </Link>
                  </div>
                  {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                  <ul className="res-footer-menu-list">
                    {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                    <li>
                      <Link to="/filter?degree=master">Latest Scholarship</Link>
                    </li>
                    <li>
                      <Link to="/sop">Get Your SOP</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <ul className="footer-social-list">
                  <li>
                    <a
                      href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebookImg} alt="scholify" />
                    </a>
                  </li>
                  {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                  {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/scholiguide/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedinImg} alt="scholify" />
                    </a>
                  </li>
                  {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                </ul>
                <div className="footer-web-duration">
                  &copy; 2022 www.scholiguide.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
