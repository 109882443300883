import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Suspense, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import commentImg from "../assets/images/icon/comment-dots.png";
// import phoneAlt from "../assets/images/icon/phone-alt.png";
import { setLocationPathAction } from "../commonRedux/reduxForLocalStorage/action";
import { useWindowResizeSize } from "../customHook/windowSize";
import EmailVerification from "../layout/EmailVerification";
// import Sidebar from "../layout/Sidebar";
import TopNavigation from "../layout/TopNavigation";
import { getNotificationData } from "./helper";
import { routingList } from "./routingList";

export default function BasePage() {
  const dispatch = useDispatch();
  const [clientWidth] = useWindowResizeSize();

  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  // const [isOpenSecondSidebar, setIsOpenSecondSidebar] = useState(false);

  const [isScroll, setIsScroll] = useState(false);

  const { scrollHeight, locationPath } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  const { profileData } = useSelector((state) => {
    return state.auth;
  }, shallowEqual);

  useEffect(() => {
    if (clientWidth > 1199) {
      setIsOpenSidebar(true);
    } else {
      setIsOpenSidebar(false);
    }
  }, [clientWidth]);

  useEffect(() => {
    if (scrollHeight > 175) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }, [scrollHeight]);

  useEffect(() => {
    if (window.location.pathname) {
      dispatch(setLocationPathAction(window.location.pathname));
    }
  }, [dispatch]);

  useEffect(() => {
    if (profileData?.id) {
      getNotificationData(profileData, dispatch);
    }

    // eslint-disable-next-line
  }, [profileData?.id]);

  return (
    <>
      <div id="main" className="main">
        <div
          onClick={() => setIsOpenSidebar(!isOpenSidebar)}
          className={isOpenSidebar ? "main-overlay" : "main-overlay w-0"}
        ></div>
        <Router>
          {/* topNavigation */}
          <div className="top-navigation-wrapper" id="topNav">
            <TopNavigation
              setIsOpenSidebar={setIsOpenSidebar}
              isOpenSidebar={isOpenSidebar}
              isScroll={isScroll}
              pageTitle={"Scholiguide"}
            />
          </div>

          {/* sidebar section */}
          {/* {locationPath === "/search" && (
            <div
              className={isOpenSidebar ? "sidebar width-15" : "sidebar width-0"}
            >
              <div
                className={
                  isOpenSecondSidebar
                    ? "sidebar-overlay overlay-none"
                    : "sidebar-overlay"
                }
              ></div>
              <Sidebar
                setIsOpenSidebar={setIsOpenSidebar}
                isOpenSidebar={isOpenSidebar}
                isOpenSecondSidebar={isOpenSecondSidebar}
                setIsOpenSecondSidebar={setIsOpenSecondSidebar}
              />
            </div>
          )} */}

          {/* body */}
          <div
            className={
              isOpenSidebar && locationPath === "/search"
                ? "body width-100"
                : "body width-100"
            }
          >
            {/* body-inner */}
            <div className="body-inner">
              <Suspense fallback={<div>Loading...</div>}>
                <div className="landing-wrapper">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-12 res-p-0">
                        <Switch>
                          {routingList?.map((item, index) => (
                            <Route
                              exact
                              path={item?.path}
                              component={item?.component}
                              key={index}
                            />
                          ))}
                          <Redirect to="/" />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </Suspense>
            </div>
          </div>

          {/* Email Verification For '/' route  */}
          <EmailVerification />

          {/* sliding-footer */}
          {isScroll && (
            <>
              <div
                className={
                  isScroll ? "sliding-footer active" : "sliding-footer"
                }
              >
                <div className="sliding-site-area">
                  <div className="sliding-footer-content">
                    <a href="/contact" className="btn btn-small btn-blue">
                      Contact Us
                      <span>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </span>
                    </a>
                    <div className="slider-content-right">
                      {/* <Link to="/contact" className="call">
                        <img src={phoneAlt} alt="Scholify" />
                        <span className="label">Call US</span>
                      </Link>
                      <span className="divider">|</span> */}
                      <div className="sliding-live-chat">
                        <a
                          href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                          className="call"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={commentImg} alt="Scholify" />
                          <span className="label">Live Chat</span>
                        </a>
                      </div>
                      <span className="divider">|</span>
                      <div className="sliding-live-chat">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeBf9-NlVlXHl88dfIRojEw14_KLZGOQgYytdPTgI97BT2x_Q/viewform"
                          className="call"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="label">Feedback</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Router>
      </div>
    </>
  );
}
