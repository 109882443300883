import axios from "axios";
import { toast } from "react-toastify";

export const singleCourseById = async (id, setLoading, cb) => {
  setLoading && setLoading(true);

  try {
    const res = await axios.get(`/api/scholarship/getById/${id}`);
    if (res?.status === 200) {
      setLoading && setLoading(false);
      toast.success(res?.data?.message, {
        toastId: 12311,
      });
      cb(res?.data?.data);
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, { toastId: 1231 });
    setLoading && setLoading(false);
  }
};
