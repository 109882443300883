import React from "react";
import { Link } from "react-router-dom";
import { sky } from "../../utility/customColor";

export default function Typography({ index, tabIndex }) {
  return (
    <>
      {index === tabIndex && (
        <div className="single-search-item-body">
          <div className="row">
            <div className="col-lg-12">
              {/* heading */}
              <div>
                <h2 className="h1" style={{ color: sky }}>
                  Heading
                </h2>
                <h2 className="h1">
                  Find The RIght Degree Or University For You
                </h2>
                <h2 className="h2">
                  Find The RIght Degree Or University For You
                </h2>
                <h3 className="h3">
                  Find The RIght Degree Or University For You
                </h3>
                <h3 className="h3 h3-bold">
                  Find The RIght Degree Or University For You
                </h3>
                <h4 className="h4">
                  Find The RIght Degree Or University For You
                </h4>
                <h4 className="h4 h4-bold">
                  Find The RIght Degree Or University For You
                </h4>
                <h5 className="h5">
                  Find The RIght Degree Or University For You
                </h5>
                <h5 className="h5 h5-bold">
                  Find The RIght Degree Or University For You
                </h5>
                <h6 className="h6">
                  Find The RIght Degree Or University For You
                </h6>
                <div className="hr-bottom-line"></div>
              </div>
              {/* paragraph */}
              <div>
                <h2 className="h1" style={{ color: sky }}>
                  Paragraph
                </h2>
                <p className="para1 para-bold">para1 para-bold</p>
                <p className="para1">para1</p>
                <p className="para2 para-bold">para2 para-bold</p>
                <p className="para2">para2</p>
                <p className="para3 para-bold">para3 para-bold</p>
                <p className="para3">para3</p>
                <p className="para4 para-bold">para4 para-bold</p>
                <p className="para4">para4</p>
                <div className="hr-bottom-line"></div>
              </div>
              {/* link */}
              <div>
                <h2 className="h1" style={{ color: sky }}>
                  Link
                </h2>
                <Link to="/design" className="link-big">
                  link-big
                </Link>
                <br />
                <Link to="/design" className="link-small">
                  link-small
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
