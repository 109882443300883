import React, { useState } from 'react';
import ColorCode from './ColorCode';
import ComponentUI from './ComponentUI';
import Typography from './Typography';

export default function Design() {
  const [index, setIndex] = useState(0);
  const tabName = [
    { name: "Typography" },
    { name: "Color" },
    { name: "Component" },
  ];
  return (
    <>
      <div
        className="layout-body layout-scroll scrollbar-remove blank-left-side"
      >
        <div className="search-section-heading">
          <h2 className="title">Design</h2>
        </div>
        <div className="couse-section-tab">
          <div className="course-tab-heading">
            {tabName.map((item, i) => {
              return (
                <button
                  key={i}
                  type="button"
                  className={`btn btn-tab ${i === index && "active"}`}
                  onClick={() => setIndex(i)}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
          <div className="course-tab-body">
            <div className="row">
              <div className="col-12" style={{ padding: "0 15px" }}>
                <div className="course-tab-inner-body">
                  <Typography index={index} tabIndex={0} />
                  <ColorCode index={index} tabIndex={1} />
                  <ComponentUI index={index} tabIndex={2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
