// import { Circle } from "@mui/icons-material";

export default function OverView({ index, tabIndex, singleCourse }) {
  return (
    <>
      {index === tabIndex && (
        <div className="single-search-item-body">
          <div className="row single-search-item-inner-body">
            <div className="col-md-6 single-search-item-inner-body-left">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">University</h3>
                <p className="para3 text-capitalize">
                  {singleCourse?.university}
                </p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Country</h3>
                <p className="para3 text-capitalize">{singleCourse?.country}</p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Degree</h3>
                <p className="para3 text-capitalize">
                  {singleCourse?.fullDegree}
                </p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Teaching language</h3>
                <p className="para3 text-capitalize">
                  {singleCourse?.language}
                </p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Programme duration</h3>
                <p className="para3 text-capitalize">
                  {singleCourse?.others?.durationValue}
                </p>
              </div>
              {/* <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Beginning</h3>
                <p className="para3">{singleCourse?.others?.durationValue}</p>
              </div> */}
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Application deadline</h3>
                <p className="para3 text-capitalize">
                  {singleCourse?.others?.applyDate}
                </p>
              </div>

              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Tuition fees per semester in EUR</h3>
                <p className="para3 text-capitalize">
                  {singleCourse?.others?.tutionValue}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Description/content</h3>
                <p className="course-tab-para">
                  {singleCourse?.others?.summary}
                </p>
              </div>

              {/* <div className="single-search-item-feature">
                <p className="course-tab-para">
                  The main focus of the Master's degree programme in Web
                  Engineering is based on the following subjects:
                </p>
              </div>
              <div className="single-search-item-feature">
                <ul>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Current trends in web engineering</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Software service engineering</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Databases</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Model-driven software development</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Analysis of software designs</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Data protection</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Parallel programming</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Parallel programming</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Real-time systems</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Human-computer interaction</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Next-generation Internet</p>
                  </li>
                  <li className="course-list">
                    <div className="bullet">
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: "#1c2b3c",
                        }}
                      />
                    </div>
                    <p>Strategic IT management</p>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
