import styled from "@emotion/styled";
import { CheckCircle } from "@mui/icons-material";
import { Box, CircularProgress, Tooltip, tooltipClasses } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { midText } from "../../utility/customColor";
import { getTimeLineData } from "./api";

export function TimeLineSection() {
  const { isLoading } = useQuery(["timelineData"], () => {
    return getTimeLineData(setRowDto);
  });

  // timeline
  let totalCell = 4;
  const emptyCell = (d, status) => {
    let dArray = [];
    const restDay = totalCell - d;
    for (let i = 1; i <= (status === "f" ? d - 1 : restDay); i++) {
      dArray.push(i);
    }
    return dArray;
  };

  // tooltip
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff !important",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: midText,
      boxShadow: "0px 19px 44px rgba(75, 102, 133, 0.25);",
      fontSize: 12,
    },
  }));

  // tooltip openClose
  const [rowDto, setRowDto] = useState([]);

  const rowDtoHandler = (name, index, value) => {
    const data = [...rowDto];
    data[index][name] = value;
    setRowDto(data);
  };

  return (
    <>
      {/* time-line */}
      <div className="card-sm-body professor-list overflowY">
        <div className="timeline-wrapper">
          {isLoading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : null}

          {rowDto?.map((item, index) => (
            <div className="timeline-row timeline-section" key={index}>
              <div
                style={{
                  width: "130px",
                  padding: "10px 5px",
                  marginRight: "0",
                  wordBreak: "break-all",
                  textTransform: "capitalize",
                }}
              >
                <p className="title">{item?.name}</p>
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    zIndex: 1,
                    backgroundColor: `${item?.color}`,
                    opacity: "0.5",
                    height: "100%",
                    top: 0,
                    left: 0,
                    borderRadius: "50px",
                    width: "94%",
                  },
                }}
              >
                {emptyCell(item?.start, "f")?.map((item, index) => (
                  <p key={index} className="empty-cell" />
                ))}
                <div
                  style={{
                    position: "relative",
                    right: item?.end === 4 ? "14px" : "0px",
                    backgroundColor: `${item?.color}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "2",
                  }}
                  className="color-check-cell"
                >
                  <LightTooltip
                    title={
                      <div
                        style={{
                          padding: "10px 15px",
                        }}
                      >
                        <p
                          style={{
                            padding: "5px 20px",
                            background: `${item?.tooltipColor}`,
                            borderRadius: "3px",
                            marginBottom: "5px",
                          }}
                        >
                          {item?.session}
                        </p>
                        <p
                          style={{
                            maxWidth: "130px",
                            fontSize: "12px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            color: midText,
                            margin: "0 auto",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "700" }}>
                            {item?.remainingDay} Days Left
                          </span>
                        </p>
                      </div>
                    }
                    arrow
                    open={item.status}
                    onClose={(prev) => {
                      rowDtoHandler("status", index, false);
                    }}
                    onOpen={(e) => {
                      rowDtoHandler("status", index, true);
                    }}
                  >
                    <CheckCircle
                      sx={{
                        color: "#fff",
                      }}
                      className="icon"
                    />
                  </LightTooltip>
                </div>
                {emptyCell(item?.end, "b")?.map((item, i) => (
                  <p key={i} className="empty-cell"></p>
                ))}
              </Box>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
