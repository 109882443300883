import blueShape from "../assets/images/shape/blue-circle.png";
import greenShape from "../assets/images/shape/green-circle.png";
import skyShape from "../assets/images/shape/sky-circle.png";
import yellowShape from "../assets/images/shape/yellow-circle.png";

// text color
export const darkText = "#1C2B3C";
export const midText = "#4d4d4d";
export const lightText = "#7a7a7a";
export const grayText = "#DFDFDF";

// border
export const border = "#dfdfdf";

// primary
export const blue = "#053680";
export const sky = "#3AA2F8";

// secondary
export const orange = "#ff842b";
export const skyBlue = "#5d9dff";
export const purple = "#9d8ef9";
export const deepSkyBlue = "#00c3f9";
export const neoGreen = "#93ff6d";
export const mediumPurple = "#ba84ff";
export const green = "#00ab6a";

// tertiary
export const orangeLight = "#fbe6d7";
export const deepSkyBlueLight = "#b3edfd";
export const meritimeBlue = "#117bd2";

export const shadeDark = "#D3D3D3";

// color
export const white = "#ffffff";
export const black = "#1D1E20";
export const gray = "#DFDFDF";
export const lightSky = "#CCEAFD";
export const deepSky = "#0094F5";
export const indigo = "#BA84FF";
export const lightGreen = "#8FD48E";
export const blueTwo = "#063781";
export const yellow = "#FFE76D";
export const lightYellow = "#DED580";
export const pink = "#FF84A1";

export const twitterColor = "#00a8e7";
export const instagramColor = "#E60C1F";

export const bodyColorTwo = "#F4F7F9";

export const colorCodeList = [orange, mediumPurple, green, sky];

export const colorList = [
  meritimeBlue,
  orange,
  deepSkyBlue,
  green,
  deepSkyBlue,
  green,
  meritimeBlue,
  orange,
];

export const serviceStyle = [
  {
    background: `linear-gradient(124.78deg, #FFF7EF 0%, #FFFFFF 100%)`,
    border: "1px solid #FFEEDA",
  },
  {
    background: `linear-gradient(124.78deg, #F3F3FF 0%, #FFFFFF 100%)`,
    border: "1px solid #EBEDFF",
  },
  {
    background: `linear-gradient(124.78deg, #E7FFEE 0%, #FFFFFF 100%)`,
    border: "1px solid #CBFFDB",
  },
  {
    background: `linear-gradient(124.78deg, #E0F9FF 0%, #FFFFFF 100%)`,
    border: "1px solid #C6F5FF",
  },
];

export const colorShape = [
  blueShape,
  skyShape,
  greenShape,
  yellowShape,
  blueShape,
];

export const colorPalate = [
  "#B29AF4",
  "#62A7F8",
  "#72BF7E",
  "#FFC135",
  "B29AF4",
];

export const visProcessingColor = ["#FFBF84", "#8490FF", "#BA84FF"];
export const visProcessingBgColor = [
  "rgba(251, 230, 215, 0.3)",
  "rgba(132, 144, 255, 0.1)",
  "rgba(186, 132, 255, 0.1",
];
