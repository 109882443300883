import {
  deepSky,
  gray,
  lightSky,
  lightText,
  midText,
  white,
} from "./customColor";

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "38px",
    height: "38px",
    borderRadius: "4px",
    borderColor: gray,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "38px",
    padding: "0 10px",
  }),
  valueOption: (provided, state) => ({
    ...provided,
    zIndex: 999999,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    color: midText,
    fontWeight: 400,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0px",
    paddingRight: "3px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "38px",
  }),
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: 0.1,
    paddingLeft: 10,
    color: isDisabled ? lightText : isSelected ? deepSky : midText,
    backgroundColor: isDisabled
      ? lightText
      : isSelected
      ? lightSky
      : isFocused
      ? lightSky
      : white,
    ":active": {
      backgroundColor: !isDisabled ? (isSelected ? lightSky : white) : white,
    },
    zIndex: 99999999,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 400,
    textOverflow: "ellipsis",
    maxWidth: "95%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: lightText,
  }),
};
