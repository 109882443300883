import axios from "axios";
import courseOne from "../../assets/images/course/01.png";
import courseTwo from "../../assets/images/course/02.png";
import courseThree from "../../assets/images/course/03.png";
import courseFour from "../../assets/images/course/04.png";
import courseFive from "../../assets/images/course/05.png";
import courseSix from "../../assets/images/course/06.png";
import courseSeven from "../../assets/images/course/07.png";
import courseEight from "../../assets/images/course/08.png";
import sopImg from "../../assets/images/features/01.png";
import ieltsImg from "../../assets/images/features/02.png";
import planImg from "../../assets/images/features/03.png";
import visaImg from "../../assets/images/features/04.png";
import courseIcon from "../../assets/images/icon/course.png";
import cvIcon from "../../assets/images/icon/cv.png";
import essayIcon from "../../assets/images/icon/essay.png";
import flagIcon from "../../assets/images/icon/flag.png";
import lorIcon from "../../assets/images/icon/lor.png";
import scholershipIcon from "../../assets/images/icon/scholership.png";
import sopIcon from "../../assets/images/icon/sop.png";
import universityIcon from "../../assets/images/icon/university.png";
import instituteOne from "../../assets/images/institute1.png";
import instituteTwo from "../../assets/images/institute2.png";
import PersonOne from "../../assets/images/person/01.png";
import PersonTwo from "../../assets/images/person/02.png";
import PersonThree from "../../assets/images/person/19.png";
import PersonFour from "../../assets/images/person/18.png";
import personeFive from "../../assets/images/person/05.png";
import personeSix from "../../assets/images/person/06.png";
import teacher01 from "../../assets/images/person/08.png";
import teacher02 from "../../assets/images/person/09.png";
import teacher03 from "../../assets/images/person/12.png";
import teacher04 from "../../assets/images/person/13.png";
import personeSeven from "../../assets/images/person/14.png";
import personeEight from "../../assets/images/person/15.png";
import studentReview01 from "../../assets/images/review/01.png";
import countryOne from "../../assets/images/scholership-countries/01.png";
import countryTwo from "../../assets/images/scholership-countries/02.png";
import countryThree from "../../assets/images/scholership-countries/03.png";
import countryFour from "../../assets/images/scholership-countries/04.png";
import studentOne from "../../assets/images/student1.png";
import studentTwo from "../../assets/images/student2.png";
import bangorVLogo from "../../assets/images/versity/bangorVLogo.svg";
import osakaVLogo from "../../assets/images/versity/osakaVLogo.svg";
import ottawaVLogo from "../../assets/images/versity/ottawaVLogo.svg";
import queensVLogo from "../../assets/images/versity/queensVLogo.svg";
import stonyBrookVLogo from "../../assets/images/versity/stonyBrookVLogo.svg";

export const processingIconList = [sopIcon, lorIcon, essayIcon, cvIcon];

export const serviceIconList = [
  flagIcon,
  universityIcon,
  courseIcon,
  scholershipIcon,
];

export const professorList = [
  {
    img: teacher01,
    name: "Curtis Carver",
    field: "Computer Science, Security",
    email: "carverc@uab.edu",
  },
  {
    img: teacher03,
    name: "Jake Chen",
    field: "Big data analytics",
    email: "jakechen@uab.edu",
  },
  {
    img: teacher02,
    name: "Baocheng Geng",
    field: "Human decision makingy",
    email: "bgeng@uab.edu",
  },
  {
    img: teacher04,
    name: "Mahmut Unan",
    field: "Medical Robotics",
    email: "unan@uab.edu",
  },
];

export const instituteList = [
  {
    name: "Stanford University (USA)",
    rate: "Graduation Rate 94%",
    time: 14,
    img: instituteOne,
  },
  {
    name: "University Of Oxford (UK)",
    rate: "Graduation Rate 94%",
    time: 14,
    img: instituteTwo,
  },
  {
    name: "Stanford University (USA)",
    rate: "Graduation Rate 94%",
    time: 14,
    img: instituteOne,
  },
  {
    name: "University Of Oxford (UK)",
    rate: "Graduation Rate 94%",
    time: 14,
    img: instituteTwo,
  },
];

export const studentList = [
  {
    name: "Zoozef Siddiq",
    education: "MSc, University Of Oklahoma",
    email: "thomashmuller@gmail.com",
    img: studentOne,
  },
  {
    name: "Tarique Rv",
    education: "University Of British Columbia ",
    email: "thomashmuller@gmail.com",
    img: studentTwo,
  },
  {
    name: "Zoozef Siddiq",
    education: "MSc, University Of Oklahoma",
    email: "thomashmuller@gmail.com",
    img: studentOne,
  },
  {
    name: "Tarique Rv",
    education: "University Of British Columbia ",
    email: "thomashmuller@gmail.com",
    img: studentTwo,
  },
];

export const scholarshipCountryList = [
  { img: countryOne, name: "Netherland", rate: "87%" },
  { img: countryTwo, name: "United Kingdom", rate: "87%" },
  { img: countryThree, name: "Germany", rate: "87%" },
  { img: countryFour, name: "Denmark", rate: "87%" },
];

export const courseList = [
  {
    img: courseOne,
    name: "Harvard University Free Online Courses 2022",
    date: "11 June 2022",
  },
  {
    img: courseThree,
    name: "New British Council Free Online Courses 2022 | Fully Funded",
    date: "11 June 2022",
  },
  {
    img: courseFour,
    name: "All UN Opportunities | Jobs, Internships, Online Courses",
    date: "11 June 2022",
  },
  {
    img: courseFive,
    name: "Cambridge University Free Online Courses 2022",
    date: "11 June 2022",
  },
];

export const scholershipList = [
  {
    img: courseTwo,
    name: "USA Scholarships for International Students 2022 | Fully Funded",
    date: "11 June 2022",
  },
  {
    img: courseSix,
    name: "Singapore Scholarships to Apply 2022-2023 | Fully Funded",
    date: "11 June 2022",
  },
  {
    img: courseSeven,
    name: "Popular Canadian Scholarships 2022-23 | Fully Funded",
    date: "11 June 2022",
  },
  {
    img: courseEight,
    name: "Alberta University Canada Scholarship 2022-23 | Fully Funded",
    date: "11 June 2022",
  },
];

export const applicationProcessList = [
  { title: "Get Your SOP", subtitle: "Statetement of purpose" },
  { title: "IELTS Preparation", subtitle: "Letter of recommendation" },
  { title: "Roadmap", subtitle: "Essay writing process" },
  { title: "Visa Processing", subtitle: "Carriculam vitae" },
];

export const serviceList = [
  { title: 56, subtitle: "Countries" },
  { title: 3765, subtitle: "Universities" },
  { title: 80198, subtitle: "Courses" },
  { title: 21290, subtitle: "Scholarships" },
];

export const scholarsList = [
  {
    img: personeFive,
    name: "Zozef Siddik",
    education: "MSc, University Of Oklahoma, USA",
    email: "thomashmuller@gmail.com",
  },
  {
    img: personeSix,
    name: "Tarique Mahmud",
    education: "MSc, Thompson Rivers University, Canada",
    email: "tarique.r.mahmud@gmail.com",
  },
  {
    img: personeSeven,
    name: "Masum Billah Forhad",
    education: "MSc, TU Freiberg, Germany",
    email: "masumbillah110828@gmail.com",
  },
  {
    img: personeEight,
    name: "Momen Al- Reza",
    education: "MSc, Hochshule Niederrhein University, Germany",
    email: "md.momenalreza@gmail.com",
  },
];

// export const timelineData = [
//   {
//     name: "Oxford",
//     start: 1,
//     end: 1,
//     total: 4,
//     color: "#5D9DFF",
//     session: "Summer",
//     remainingDay: "57",
//     tooltipColor: "rgba(93, 157, 255,.3)",
//   },
//   {
//     name: "Cambridge",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
//   {
//     name: "Toronto",
//     start: 3,
//     end: 3,
//     total: 4,
//     color: "#9D8EF9",
//     session: "Winter",
//     remainingDay: "57",
//     tooltipColor: "rgba(157, 142, 249,.3)",
//   },
//   {
//     name: "Humber",
//     start: 4,
//     end: 4,
//     total: 4,
//     color: "#00C3F9",
//     session: "Fall",
//     remainingDay: "57",
//     tooltipColor: "rgba(0, 195, 249,.3)",
//   },
//   {
//     name: "Cambridge",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
//   {
//     name: "Oxford",
//     start: 1,
//     end: 1,
//     total: 4,
//     color: "#5D9DFF",
//     session: "Summer",
//     remainingDay: "57",
//     tooltipColor: "rgba(93, 157, 255,.3)",
//   },
//   {
//     name: "Cambridge",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
//   {
//     name: "Toronto",
//     start: 3,
//     end: 3,
//     total: 4,
//     color: "#9D8EF9",
//     session: "Winter",
//     remainingDay: "57",
//     tooltipColor: "rgba(157, 142, 249,.3)",
//   },
//   {
//     name: "Humber",
//     start: 4,
//     end: 4,
//     total: 4,
//     color: "#00C3F9",
//     session: "Fall",
//     remainingDay: "57",
//     tooltipColor: "rgba(0, 195, 249,.3)",
//   },
//   {
//     name: "Cambridge",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
// ];

export const teamList = [
  {
    img: PersonOne,
    teamName: "Mirza Sunny",
    teamDesignation: "Managing Director & Co-Founder",
  },
  {
    img: PersonTwo,
    teamName: "Saikat Kumar",
    teamDesignation: "Creative Director & Co-Founder",
  },
  // {
  //   img: PersonThree,
  //   teamName: "Shohan Hossain",
  //   teamDesignation: "Chief Consultant",
  // },
  {
    img: PersonThree,
    teamName: "Jubayer Alam Khan",
    teamDesignation: "Frontend Software Developer",
  },
  {
    img: PersonThree,
    teamName: "Mahmudul Hasan Emon",
    teamDesignation: "Software Engineer",
    isNoImg: true,
  },
];

export const expertProfessorList = [
  {
    img: teacher01,
    name: "Curtis Carver",
    education: "Computer Science, Security",
    email: "carverc@uab.edu",
  },
  {
    img: teacher03,
    name: "Jake Chen",
    education: "Big data analytics",
    email: "jakechen@uab.edu",
  },
  {
    img: teacher02,
    name: "Baocheng Geng",
    education: "Human decision makingy",
    email: "bgeng@uab.edu",
  },
  {
    img: teacher04,
    name: "Mahmut Unan",
    education: "Medical Robotics",
    email: "unan@uab.edu",
  },
];

export const featureList = [
  {
    id: 1,
    img: sopImg,
    title: "Get Your SOP",
    subtitle: "Learn how to write your SOP",
    describe:
      "There are several components needed to keep in mind while writing your SOP for there is no room for error.",
    url: "/sop",
    isBtn: true,
    btnTitle: "See Details",
  },
  {
    id: 2,
    img: ieltsImg,
    title: "IELTS Preparation",
    subtitle: "Take your IELTS preparation from home",
    describe:
      "You are welcome to explore our study materials and get adequate lessons from our experienced instructors.",
    url: "/ielts",
    isBtn: true,
    btnTitle: "How It Works",
  },
  {
    id: 3,
    img: planImg,
    title: "Roadmap",
    subtitle: "Figure out your roadmap",
    describe:
      "The road to reach your destination from rock bottom can be utterly confusing. Get counsel from our experienced professionals and design your affairs wisely.",
    url: "/",
    isBtn: false,
    btnTitle: "How It Works",
  },
  {
    id: 4,
    img: visaImg,
    title: "Visa Processing",
    subtitle: "Apply for your visa on your own",
    describe:
      "Do you know you don't need any help from brokers or lawyers for applying your visa if you get the proper guidance? Let us help you with that with step by step guidelines.",
    url: "/apply",
    isBtn: true,
    btnTitle: "How It Works",
  },
];

export const studntReviewList = [
  {
    img: studentReview01,
    title: "Excelent Progression",
    comment:
      "Getting acquainted with Scholiguide was one of the wisest decisions I've ever made. They know exactly what you need even before you ask for it.",
    sName: " ",
    university: " ",
    isActive: true,
  },
  {
    img: studentReview01,
    title: "Excelent Progression",
    comment:
      "When it comes to study abroad, It's very common to get confused. Luckily I had the support of Scholiguide all the way. I'm utterly grateful to this platform.",
    sName: " ",
    university: " ",
    isActive: false,
  },
  {
    img: studentReview01,
    title: "Excelent Progression",
    comment:
      "It would be my advice for all the students who are planning to obtain an overseas degree to not depend on any broker or regional agencies as you have the ability to complete the whole process on your own. All you need is a little support and proper guidelines which you can easily obtain from Scholiguide as I got mine.",
    sName: " ",
    university: " ",
    isActive: false,
  },
];

export const topInstituteList = [
  {
    img: queensVLogo,
    instituteName: "Queen's University",
    acceptanceRate: 53,
    url: "https://www.queensu.ca/",
  },
  {
    img: osakaVLogo,
    instituteName: "Osaka University",
    acceptanceRate: 75,
    url: "https://www.osaka-u.ac.jp/en",
  },
  {
    img: bangorVLogo,
    instituteName: "Bangor University",
    acceptanceRate: 67,
    url: "https://www.bangor.ac.uk/",
  },
  {
    img: stonyBrookVLogo,
    instituteName: "Stony Brook University",
    acceptanceRate: 55,
    url: "https://www.stonybrook.edu/",
  },
  {
    img: ottawaVLogo,
    instituteName: "University of Ottawa",
    acceptanceRate: 53,
    url: "https://www.uottawa.ca/en",
  },
  {
    img: queensVLogo,
    instituteName: "Queen's University",
    acceptanceRate: 53,
    url: "https://www.queensu.ca/",
  },
  {
    img: osakaVLogo,
    instituteName: "Osaka University",
    acceptanceRate: 75,
    url: "https://www.osaka-u.ac.jp/en",
  },
  {
    img: bangorVLogo,
    instituteName: "Bangor University",
    acceptanceRate: 67,
    url: "https://www.bangor.ac.uk/",
  },
];

export const gerFilterDistinctDataStat = async (cb) => {
  try {
    const { data: country } = await axios.get(
      `/api/scholarship/distinct?field=country`
    );
    if (country?.length > 0) {
      cb({ title: country?.length, subtitle: "Countries" });
    }

    const { data: university } = await axios.get(
      `/api/scholarship/distinct?field=university`
    );
    if (university?.length > 0) {
      cb({ title: university?.length, subtitle: "Universities" });
    }

    const { data: discipline } = await axios.get(
      `/api/scholarship/distinct?field=discipline`
    );
    if (discipline?.length > 0) {
      cb({ title: discipline?.length, subtitle: "Courses" });
    }

    const { data: all } = await axios.get(
      `/api/scholarship/distinct?field=all`
    );
    if (all?.length > 0) {
      cb({ title: all?.length, subtitle: "Scholarships" });
    }
  } catch (err) {
    cb({});
  }
};
