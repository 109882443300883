import axios from "axios";

// export const timelineData = [
//   {
//     name: "Canada",
//     start: 1,
//     end: 1,
//     total: 4,
//     color: "#5D9DFF",
//     session: "Summer",
//     remainingDay: "57",
//     tooltipColor: "rgba(93, 157, 255,.3)",
//   },
//   {
//     name: "United States",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
//   {
//     name: "United Kingdom",
//     start: 3,
//     end: 3,
//     total: 4,
//     color: "#9D8EF9",
//     session: "Winter",
//     remainingDay: "57",
//     tooltipColor: "rgba(157, 142, 249,.3)",
//   },
//   {
//     name: "Chaina",
//     start: 4,
//     end: 4,
//     total: 4,
//     color: "#00C3F9",
//     session: "Fall",
//     remainingDay: "57",
//     tooltipColor: "rgba(0, 195, 249,.3)",
//   },
//   {
//     name: "Australia",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
//   {
//     name: "Oxford",
//     start: 1,
//     end: 1,
//     total: 4,
//     color: "#5D9DFF",
//     session: "Summer",
//     remainingDay: "57",
//     tooltipColor: "rgba(93, 157, 255,.3)",
//   },
//   {
//     name: "Cambridge",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
//   {
//     name: "Toronto",
//     start: 3,
//     end: 3,
//     total: 4,
//     color: "#9D8EF9",
//     session: "Winter",
//     remainingDay: "57",
//     tooltipColor: "rgba(157, 142, 249,.3)",
//   },
//   {
//     name: "Humber",
//     start: 4,
//     end: 4,
//     total: 4,
//     color: "#00C3F9",
//     session: "Fall",
//     remainingDay: "57",
//     tooltipColor: "rgba(0, 195, 249,.3)",
//   },
//   {
//     name: "Cambridge",
//     start: 2,
//     end: 2,
//     total: 4,
//     color: "#FFA974",
//     session: "Spring",
//     remainingDay: "57",
//     tooltipColor: "rgba(255, 169, 116,.3)",
//   },
// ];

/* Time Line Api Called */
export const getTimeLineData = async (setter) => {
  const res = await axios.get("/api/overAllConfig/getAll");

  const modifyTimelineRes = res?.data?.data?.map((data, index) => {
    return {
      ...data,
      timeline: {
        ...data?.timeline,
        intLine: index + 1,
        status: false,
      },
    };
  });

  const data = datasetMakerForTimeLine(modifyTimelineRes) || [];
  const fistObj = { ...data[0], status: true };
  data[0] = fistObj;
  setter(data);

  return data;
};

const currentDate = new Date();
const month = currentDate.getMonth() + 1;

const checkIfExits = (string) => {
  const start = string.split("-")[0];
  const end = string.split("-")[1];

  if (!start || !end) return false;

  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  if (+start < +end) {
    if (+start <= month && +end >= month) {
      const lastDay = new Date(currentDate.getFullYear(), +end, 0);
      const diffInTime = lastDay.getTime() - firstDay.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      return { remainingDay: +diffInDays };
    }
  } else {
    const lastDay = new Date(currentDate.getFullYear() + 1, +end, 0);
    const diffInTime = lastDay.getTime() - firstDay.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return { remainingDay: +diffInDays };
  }

  return false;
};

export const datasetMakerForTimeLine = (data) => {
  // const currentDate = new Date();
  // const month = currentDate.getMonth() + 1;

  // console.log("Data", data);
  const arr = [];

  for (let obj of data) {
    let { fall, spring, summer, winter, country, intLine } = obj?.timeline;

    let payload = {
      name: country,
      total: 4,
      intLine: intLine,
    };

    fall = checkIfExits(fall);
    spring = checkIfExits(spring);
    summer = checkIfExits(summer);
    winter = checkIfExits(winter);

    if (fall?.remainingDay >= 0) {
      payload = {
        ...payload,
        session: "Fall",
        color: "#9D8EF9",
        start: 4,
        end: 4,
        tooltipColor: "rgba(157, 142, 249,.3)",
        remainingDay: fall?.remainingDay,
      };
    } else if (spring?.remainingDay >= 0) {
      payload = {
        ...payload,
        session: "Spring",
        color: "#5D9DFF",
        start: 2,
        end: 2,
        tooltipColor: "rgba(255, 169, 116,.3)",
        remainingDay: spring?.remainingDay,
      };
    } else if (summer?.remainingDay >= 0) {
      payload = {
        ...payload,
        session: "Summer",
        color: "#FFA974",
        start: 1,
        end: 1,
        tooltipColor: "rgba(93, 157, 255,.3)",
        remainingDay: summer?.remainingDay,
      };
    } else if (winter?.remainingDay >= 0) {
      payload = {
        ...payload,
        color: "#00C3F9",
        session: "Winter",
        start: 3,
        end: 3,
        tooltipColor: "rgba(0, 195, 249,.3)",
        remainingDay: winter?.remainingDay,
      };
    }

    arr.push(payload);
  }

  return arr;
};
