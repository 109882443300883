import { Clear, Search } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { lightText } from "../utility/customColor";

export default function FilterSearch({
  placeholder,
  value,
  setValue,
  cancelHandler,
  width,
  crossClassName,
  classes,
  searchFontSize,
  isSearchIcon,
  onKeyDown,
}) {
  return (
    <div
      component="form"
      className={classes ? `default-search ${classes}` : `default-search`}
    >
      {isSearchIcon && (
        <IconButton
          type="button"
          sx={{
            p: "8px 0px 8px 15px",
            "&.MuiButtonBase-root": {
              "&:hover": {
                backgroundColor: "transparent!important",
              },
            },
            "&.MuiTouchRipple-root": {
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
          }}
          aria-label={placeholder ? placeholder : "search"}
        >
          <Search
            sx={{
              "&.MuiSvgIcon-root": {
                color: lightText,
                fontSize: searchFontSize || "16px",
              },
            }}
          />
        </IconButton>
      )}

      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          width: 297,
          "&.MuiInputBase-root": {
            marginLeft: "14px",
          },
        }}
        placeholder={placeholder ? placeholder : "Search"}
        inputProps={{ "aria-label": placeholder ? placeholder : "search" }}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
        onKeyDown={(e) =>
          onKeyDown
            ? onKeyDown(e)
            : (e) => {
                setValue(e);
              }
        }
      />
      {(value.length || 0) > 0 && (
        <span
          className={crossClassName ? `pointer ${crossClassName}` : "pointer"}
          onClick={() => cancelHandler()}
        >
          <Clear
            sx={{
              "&.MuiSvgIcon-root": {
                color: lightText,
                fontSize: searchFontSize || "16px",
              },
            }}
          />
        </span>
      )}
    </div>
  );
}
