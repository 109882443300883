import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  KeyboardArrowDownOutlined,
  Menu,
  NotificationsNoneOutlined,
} from "@mui/icons-material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import filterIcon from "../assets/images/icon/filters.png";
import phoneIcon from "../assets/images/icon/phone.png";
import profileImg from "../assets/images/profile.png";
import authLogo from "../assets/logos/logo.png";
import FilterSearch from "../common/FilterSearch";
import FormikSelect from "../common/FormikSelect";
import ViewModal from "../common/ViewModal";
import {
  filterSearchAction,
  setLocationPathAction,
} from "../commonRedux/reduxForLocalStorage/action";
import ForgotPassword from "../modules/auth/ForgotPassword";
import Login from "../modules/auth/Login";
import SignIn from "../modules/auth/SignIn";
import { gray, midText } from "../utility/customColor";
import { customStyles } from "../utility/selectCustomStyle";
import ProfilePopUp from "./ProfilePopUp";

const menuList = [
  { title: "Home", route: "/", dropdown: false },
  {
    title: "Degree",
    dropdown: true,
    isDropdownChild: [
      { id: 1, title: "Bachelors", route: "/filter?degree=bachelor" },
      { id: 2, title: "Masters", route: "/filter?degree=master" },
      { id: 3, title: "PHD", route: "/filter?degree=phd" },
      { id: 4, title: "Online", route: "/filter?attendance=online" },
    ],
  },
  { title: "About", route: "/about", dropdown: false },
  {
    title: "Service",
    dropdown: true,
    isDropdownChild: [
      { id: 1, title: "Apply", route: "/apply" },
      { id: 2, title: "Get Your Sop", route: "/sop" },
      { id: 3, title: "IELTS", route: "/ielts" },
    ],
  },
  { title: "Blog", route: "/blog", dropdown: false },
  { title: "Faq", route: "/faq", dropdown: false },
  { title: "Contact", route: "/contact", dropdown: false },
];

const initData = {
  searchString: "",
  search: "",
};

export default function TopNavigation({
  setIsOpenSidebar,
  isOpenSidebar,
  isScroll,
  pageTitle,
}) {
  const { profileData } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const { filterSearch, notificationsCount } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  const { isAuth } = useSelector(
    (state) => state?.auth?.profileData,
    shallowEqual
  );

  // profile menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // sign in modal
  const [show, setShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [forgotShow, setForgotShow] = useState(false);

  // responsive menu
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => setShowMenu(false);
  const handleMenuShow = () => setShowMenu(true);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...initData,
          searchString: filterSearch?.search || "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          resetForm(initData);
        }}
      >
        {({
          handleSubmit,
          resetForm,
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          isValid,
        }) => (
          <>
            <title>{pageTitle}</title>
            <Form>
              <Navbar className="top-navigation" collapseOnSelect expand="xl">
                {/* header-top */}
                <div className="header-top">
                  {/* responsive button */}
                  <div className="d-lg-none" onClick={handleMenuShow}>
                    <Menu
                      sx={{
                        marginRight: "10px",
                        fontSize: "20px",
                        color: midText,
                      }}
                    />
                    <span className="menu-title">Menu</span>
                  </div>

                  {/* logo */}
                  <div className="logo-wrapper top-logo-wrapper">
                    <Link
                      to="/"
                      onClick={() => {
                        dispatch(setLocationPathAction("/"));
                        dispatch(
                          filterSearchAction({
                            search: "",
                            courseType: "",
                            department: "",
                            subject: "",
                            course: "",
                            city: "",
                            institute: "",
                            tutions: "",
                            courseTime: "",
                          })
                        );
                      }}
                    >
                      <div className="logo-img">
                        <img src={authLogo} alt="scholify" />
                      </div>
                    </Link>
                  </div>

                  {/* select search */}
                  <div className="custom-button-group res-seclect-search">
                    {/* dropdown */}
                    <FormikSelect
                      classes="input-sm btn-single-groupe"
                      styles={{
                        ...customStyles,
                        control: (provided, state) => ({
                          ...provided,
                          width: "133px",
                          minHeight: "50px",
                          height: "50px",
                          borderRadius: "0px",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                          borderColor: gray,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: "50px",
                          padding: "0 10px",
                        }),
                        indicatorsContainer: (provided, state) => ({
                          ...provided,
                          height: "50px",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0px",
                          paddingRight: "15px",
                        }),
                      }}
                      name="education"
                      options={[
                        {
                          value: 1,
                          label: "O Level",
                          icon: <FontAwesomeIcon icon={faBookOpen} />,
                        },
                        {
                          value: 2,
                          label: "A Level",
                          icon: <FontAwesomeIcon icon={faBookOpen} />,
                        },
                        {
                          value: 3,
                          label: "Bachelor",
                          icon: <FontAwesomeIcon icon={faBookOpen} />,
                        },
                        {
                          value: 4,
                          label: "Masters",
                          icon: <FontAwesomeIcon icon={faBookOpen} />,
                        },
                      ]}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}
                          <span style={{ marginLeft: 5, fontWeight: "bold" }}>
                            {e.label}
                          </span>
                        </div>
                      )}
                      value={values?.education}
                      onChange={(valueOption) => {
                        setFieldValue("education", valueOption);
                      }}
                      errors={errors}
                      touched={touched}
                      isClearable={false}
                    />
                    {/* filter serach */}
                    <FilterSearch
                      classes="desktop-top-search btn-single-groupe no-left-radius"
                      placeholder="Search for degrees( Ex: BSc, Masters, PHD... )"
                      value={values?.searchString}
                      onKeyDown={(e) => {
                        setFieldValue("searchString", e.target.value);
                        if (e.keyCode === 13) {
                          dispatch(
                            filterSearchAction({
                              ...filterSearch,
                              search: e.target.value,
                            })
                          );
                          dispatch(setLocationPathAction("/search"));
                          history.push("/search");
                        }
                      }}
                      setValue={(e) => {
                        setFieldValue("searchString", e.target.value);
                        if (e.keyCode === 13) {
                          dispatch(
                            filterSearchAction({
                              ...filterSearch,
                              search: e.target.value,
                            })
                          );
                          dispatch(setLocationPathAction("/search"));
                          history.push("/search");
                        }
                      }}
                      cancelHandler={() => {
                        dispatch(
                          filterSearchAction({
                            search: "",
                          })
                        );
                        setFieldValue("searchString", "");
                        history.push("/");
                        dispatch(setLocationPathAction("/"));
                      }}
                    />
                  </div>

                  {/* contact phone */}
                  <div className="top_contact_call d-xl-none">
                    {isAuth ? (
                      <>
                        <div
                          className="top-user pointer notification-bell res-top-user"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/notification`);
                          }}
                        >
                          <span>
                            <NotificationsNoneOutlined
                              sx={{
                                color: "action.active",
                                zIndex: 1,
                              }}
                            />
                          </span>
                          {+notificationsCount > 0 ? (
                            <span className="badge">{notificationsCount}</span>
                          ) : null}
                        </div>
                        <div
                          className="top-user pointer res-top-user"
                          onClick={(e) => handleClick(e)}
                        >
                          <span className="profile-menu-img">
                            {profileData?.imageUrl ? (
                              <img
                                src={profileData?.imageUrl}
                                alt={"Profile-img"}
                              />
                            ) : (
                              <img src={profileImg} alt="Profile" />
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <h2 onClick={() => history.push("/contact")}>
                          Call Us
                          <img src={phoneIcon} alt="scholify" />
                        </h2>
                      </>
                    )}
                  </div>

                  {/* auth button */}
                  <div className="top-menu-right">
                    {/* contact us */}
                    {/* <div className="top-user">
                      <button
                        type="button"
                        className="btn btn-small btn-blue"
                      >
                        Contact Us
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </div> */}

                    {isAuth ? (
                      <>
                        <div className="top-user">
                          <button
                            type="button"
                            className="btn btn-small btn-default"
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push("/my-list");
                            }}
                          >
                            My List
                          </button>
                        </div>
                        <div
                          className="top-user pointer notification-bell"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/notification`);
                          }}
                        >
                          <span>
                            <NotificationsNoneOutlined
                              sx={{
                                color: "action.active",
                                zIndex: 1,
                              }}
                            />
                          </span>
                          {+notificationsCount > 0 ? (
                            <span className="badge">{notificationsCount}</span>
                          ) : null}
                        </div>
                        <div
                          className="top-user pointer"
                          onClick={(e) => handleClick(e)}
                        >
                          <span className="profile-menu-img">
                            {profileData?.imageUrl ? (
                              <img
                                src={profileData?.imageUrl}
                                alt={"Profile-img"}
                              />
                            ) : (
                              <img src={profileImg} alt="Profile" />
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="top-user">
                          <button
                            type="button"
                            className="btn btn-small btn-default btn-small-two btn-colorless"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                            }}
                          >
                            <span style={{ marginRight: "10px" }}>
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                            sign in
                          </button>
                          <button
                            type="button"
                            className="btn btn-mid btn-orange"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                            }}
                          >
                            sign up
                          </button>
                        </div>
                        {/* <div className="top-user">
                            <button
                              type="button"
                              className="btn btn-load"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLoginShow(true);
                              }}
                            >
                              Log In
                            </button>
                          </div> */}
                      </>
                    )}
                  </div>
                </div>

                {/* responsive search */}
                {isScroll && (
                  <>
                    <div className="hero-form-wrapper responsive-filter-serch d-xl-none d-flex">
                      <div className="subscribe-from">
                        <FilterSearch
                          classes="hero-search-form"
                          placeholder="Search for degrees( Ex: BSc, Masters, PHD... )"
                          value={values?.searchString}
                          onKeyDown={(e) => {
                            setFieldValue("searchString", e.target.value);
                            if (e.keyCode === 13) {
                              dispatch(
                                filterSearchAction({
                                  ...filterSearch,
                                  search: e.target.value,
                                })
                              );
                              dispatch(setLocationPathAction("/search"));
                              history.push("/search");
                            }
                          }}
                          setValue={(e) => {
                            setFieldValue("searchString", e.target.value);
                            if (e.keyCode === 13) {
                              dispatch(
                                filterSearchAction({
                                  ...filterSearch,
                                  search: e.target.value,
                                })
                              );
                              dispatch(setLocationPathAction("/search"));
                              history.push("/search");
                            }
                          }}
                          cancelHandler={() => {
                            dispatch(
                              filterSearchAction({
                                search: "",
                              })
                            );
                            setFieldValue("searchString", "");
                            history.push("/");
                            dispatch(setLocationPathAction("/"));
                          }}
                        />
                        <button
                          type="button"
                          className="btn default-button btn-subscribe btn-hero-subscribe"
                          onClick={() => {
                            dispatch(
                              filterSearchAction({
                                ...filterSearch,
                                search: values?.searchString,
                              })
                            );
                            dispatch(setLocationPathAction("/search"));
                            history.push("/search");
                          }}
                        >
                          <img src={filterIcon} alt="scholify" />
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* header-bottom */}
                <div className="header-bottom">
                  {/* menu */}
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <div className="top-menu">
                      <div className="top-menu-left">
                        <Nav className="menu top-left-menu">
                          {menuList &&
                            menuList.map((item, index) => {
                              return (
                                <>
                                  {!item?.dropdown && (
                                    <NavLink
                                      key={index}
                                      exact
                                      // activeStyle={{
                                      //   fontWeight: "600",
                                      // }}
                                      className={"h5 h5-bold"}
                                      to={item?.route}
                                    >
                                      {item?.title}
                                    </NavLink>
                                  )}

                                  {item?.dropdown && (
                                    <NavDropdown
                                      title={
                                        <>
                                          {item?.title}
                                          <span className="icon">
                                            <KeyboardArrowDownOutlined
                                              sx={{ fontSize: "16px" }}
                                            />
                                          </span>
                                        </>
                                      }
                                      id="navbarScrollingDropdown"
                                      key={index}
                                    >
                                      {item?.isDropdownChild?.length > 0 &&
                                        item?.isDropdownChild?.map((itm, i) => {
                                          return (
                                            <NavLink
                                              key={i}
                                              exact
                                              activeStyle={{
                                                fontWeight: "600",
                                              }}
                                              to={itm?.route}
                                              className={""}
                                            >
                                              {itm?.title}
                                            </NavLink>
                                          );
                                        })}
                                    </NavDropdown>
                                  )}
                                </>
                              );
                            })}
                          <NavLink
                            exact
                            className={"h5 h5-bold d-xl-none d-inline-block"}
                            to={"#"}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                            }}
                          >
                            sign In
                          </NavLink>
                          <NavLink
                            exact
                            className={"h5 h5-bold d-xl-none d-inline-block"}
                            to={"#"}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                            }}
                          >
                            Sign Up
                          </NavLink>
                        </Nav>
                      </div>
                    </div>
                  </Navbar.Collapse>
                </div>
              </Navbar>

              {/* profile PopUp */}
              <ProfilePopUp
                propsObj={{
                  id,
                  open,
                  anchorEl,
                  handleClose,
                }}
              />

              {/* sign in modal */}
              <ViewModal
                size="lg"
                title={` `}
                backdrop="true"
                classes="default-modal preview-modal auth-modal"
                show={show}
                onHide={() => setShow(false)}
                isCloseIcon={false}
                centered={true}
              >
                <SignIn
                  setLoginShow={setLoginShow}
                  setForgotShow={setForgotShow}
                  setShow={setShow}
                  setShowMenu={setShowMenu}
                />
              </ViewModal>

              {/* login modal */}
              <ViewModal
                size="lg"
                title={` `}
                backdrop="static"
                classes="default-modal preview-modal auth-modal"
                show={loginShow}
                onHide={() => setLoginShow(false)}
              >
                <Login
                  setLoginShow={setLoginShow}
                  setForgotShow={setForgotShow}
                  setShowMenu={setShowMenu}
                />
              </ViewModal>

              {/* forgotpassword modal */}
              <ViewModal
                size="lg"
                title={` `}
                backdrop="static"
                classes="default-modal preview-modal auth-modal"
                show={forgotShow}
                onHide={() => setForgotShow(false)}
              >
                <ForgotPassword
                  setLoginShow={setLoginShow}
                  setForgotShow={setForgotShow}
                  setShow={setShow}
                  singupVerification={forgotShow}
                />
              </ViewModal>

              {/* responsive menu */}
              <Offcanvas
                show={showMenu}
                onHide={handleMenuClose}
                responsive="lg"
                className="responsive-menu"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{` `}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="top-menu d-lg-none">
                    <div className="top-menu-left">
                      <Nav className="menu top-left-menu">
                        {menuList &&
                          menuList.map((item, index) => {
                            return (
                              <>
                                {!item?.dropdown && (
                                  <NavLink
                                    key={index}
                                    exact
                                    // activeStyle={{
                                    //   fontWeight: "600",
                                    // }}
                                    className={"h5 h5-bold"}
                                    to={item?.route}
                                    onClick={handleMenuClose}
                                  >
                                    {item?.title}
                                  </NavLink>
                                )}

                                {item?.dropdown && (
                                  <NavDropdown
                                    title={
                                      <>
                                        {item?.title}
                                        <span className="icon">
                                          <KeyboardArrowDownOutlined
                                            sx={{ fontSize: "16px" }}
                                          />
                                        </span>
                                      </>
                                    }
                                    id="navbarScrollingDropdown"
                                    key={index}
                                  >
                                    {item?.isDropdownChild?.length > 0 &&
                                      item?.isDropdownChild?.map((itm, i) => {
                                        return (
                                          <NavLink
                                            key={i}
                                            exact
                                            activeStyle={{
                                              fontWeight: "600",
                                            }}
                                            to={itm?.route}
                                            className={""}
                                            onClick={handleMenuClose}
                                          >
                                            {itm?.title}
                                          </NavLink>
                                        );
                                      })}
                                  </NavDropdown>
                                )}
                              </>
                            );
                          })}
                        {isAuth ? (
                          <>
                            <div className="top-user">
                              <button
                                type="button"
                                className="btn btn-small btn-default"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  history.push("/my-list");
                                }}
                              >
                                My List
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <NavLink
                              exact
                              className={"h5 h5-bold d-xl-none d-inline-block"}
                              to={"#"}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShow(true);
                              }}
                            >
                              sign In
                            </NavLink>
                            <NavLink
                              exact
                              className={"h5 h5-bold d-xl-none d-inline-block"}
                              to={"#"}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShow(true);
                              }}
                            >
                              Sign Up
                            </NavLink>
                          </>
                        )}
                      </Nav>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
