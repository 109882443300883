import axios from "axios";
import { toast } from "react-toastify";

export const searchData = [
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "FULL FUNDING SCHOLARSHIP",
    isFullFunding: true,
    url: "/",
  },
  {
    title: "Masters in Web Engineering",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
];

export const postNotificationGererate = async (payload, setLoading, cb) => {
  setLoading && setLoading(payload?.scholarshipId);
  try {
    const res = await axios.post(
      "/api/notification/generateNotification",
      payload
    );

    if (res?.status === 200) {
      setLoading && setLoading(false);
      toast.success(
        "You will notify from this scholarship" || res?.data?.message,
        {
          toastId: 123111,
        }
      );
      cb();
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, {
      toastId: 111231,
    });
    setLoading && setLoading(false);
  }
};

export const postAddToMyListApi = async (payload, setLoading, cb) => {
  setLoading && setLoading(payload?.payload?.scholarshipId);
  try {
    const res = await axios.post(
      "/api/scholarship/scholarshipListing",
      payload
    );

    if (res?.status === 200) {
      setLoading && setLoading(false);
      toast.success(res?.data?.message, {
        toastId: 12311,
      });
      cb();
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, { toastId: 1231 });
    setLoading && setLoading(false);
  }
};

export const getSearchScholarship = async (search, setLoading, setter) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.get(
      `/api/scholarship/search?keyword=${search}&skip=0&limit=10`
    );

    if (res?.status === 200) {
      setter(res?.data);
      setLoading && setLoading(false);
    }
  } catch (err) {
    setter([]);
    setLoading && setLoading(false);
  }
};
