import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

// router
import BasePage from "./router/BasePage";

// css
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./assets/css/index.css";
import axios from "axios";
axios.defaults.baseURL = "https://scholify-backend.herokuapp.com/";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <BasePage />

        <ToastContainer
          position="bottom-right"
          newestOnTop={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
          autoClose={1500}
          z-index={999999}
        />
      </QueryClientProvider>
    </div>
  );
}

export default App;
