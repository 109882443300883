import { Circle } from "@mui/icons-material";
import React from "react";

export default function CourseView({ index, tabIndex, singleCourse }) {
  return (
    <>
      {index === tabIndex && (
        <div className="single-search-item-body">
          <div className="row single-search-item-inner-body">
            <div className="col-md-6 single-search-item-inner-body-left">
              <div className="single-search-item-feature">
                {singleCourse?.others?.programStuctures?.title ? (
                  <h3 className="h4 h4-bold">
                    {" "}
                    {singleCourse?.others?.programStuctures?.title}
                  </h3>
                ) : null}
              </div>

              <div className="single-search-item-feature">
                {singleCourse?.others?.programStuctures?.list?.map((item) => (
                  <ul>
                    <li className="course-list">
                      <div className="bullet">
                        <Circle
                          sx={{
                            fontSize: "8px",
                            color: "#1c2b3c",
                          }}
                        />
                      </div>
                      <p>{item}</p>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">
                  Course-specific, integrated language
                </h3>
                <p className="text-capitalize">{singleCourse?.language}</p>
              </div>

              {/* <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">
                  Course-specific, integrated German language courses
                </h3>
                <p>No</p>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
