import { CloseOutlined, Mail } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InstitutionComponents from "../../common/institution";
import { TimeLineComponent } from "../../common/timeLine";
import {
  filterSearchAction,
  scrollHeightAction,
  setLocationPathAction,
} from "../../commonRedux/reduxForLocalStorage/action";
import { useFetchInfiniteScroll } from "../../customHook/useFetchInfiniteScroll";
import { bodyColorTwo, deepSky } from "../../utility/customColor";
import { professorList, studentList } from "../home/helper";
import SchoarshipComponent from "./schoarshipComponent";

export default function SearchPage() {
  const scrollRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.localStorage.filterSearch);

  const [pageNo, setPageNo] = useState(0);
  const pageSize = 10;
  const loader = useRef(null);

  const getFilterPayload = useMemo(() => {
    let srch = search.replaceAll("&", "%26");
    srch = srch.replaceAll(" ", "%20");

    /* Replace All Bracket's For Backend Regx Check */
    srch = srch.replaceAll("(", "\\(");
    srch = srch.replaceAll(")", "\\)");

    return srch;
  }, [search]);

  const { loading, list, extraObj } = useFetchInfiniteScroll(
    `/api/scholarship/search?keyword=${getFilterPayload}&skip=${pageNo}&limit=${pageSize}`
  );

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPageNo((prev) => prev + 10);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
  }, [dispatch]);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {loading ? (
        <div className="col-xl-9 col-12">
          <div className="text-center mt-4">
            <CircularProgress color="inherit" />
          </div>
        </div>
      ) : null}

      <div className="layout-scroll scrollbar-remove" ref={scrollRef} style={{ background: bodyColorTwo }}>
        <div className="layout-body">
          <div className="row">
            <div className="col-xl-9 col-12">
              <div className="search-section">
                <div className="search-section-heading">
                  {loading ? (
                    <>
                      <h6 style={{ width: "100%", textAlign: "center" }}>
                        Searching...
                      </h6>
                    </>
                  ) : (
                    <h2 className="h1">
                      About <span>{extraObj?.totalResult || 0}</span> results
                      found for you with {search}
                    </h2>
                  )}
                  <div
                    className="icon pointer"
                    onClick={() => {
                      dispatch(
                        filterSearchAction({
                          search: "",
                          courseType: "",
                          department: "",
                          subject: "",
                          course: "",
                          city: "",
                          institute: "",
                          tutions: "",
                          courseTime: "",
                        })
                      );
                      dispatch(setLocationPathAction("/"));
                      history.push("/");
                    }}
                  >
                    <CloseOutlined sx={{ fontSize: "30px" }} />
                  </div>
                </div>

                <SchoarshipComponent data={list} loading={loading} />

                {!loading && list?.length > 0 ? (
                  <h2
                    className="h3"
                    style={{
                      marginTop: "30px",
                      paddingBottom: "20px",
                      color: "gray",
                    }}
                  >
                    About {list?.length} data found out of{" "}
                    {extraObj?.totalResult}
                  </h2>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 d-xl-block d-none">
              {/* Time Line */}
              <TimeLineComponent />

              {/* professor list */}
              <div className="card-sm-style card-sm-height">
                <div className="title">
                  <h2 className="h4">Prominent Supervisors</h2>
                </div>
                <div className="card-sm-body professor-list overflowY">
                  {professorList?.length > 0 &&
                    professorList?.map((item, index) => {
                      return (
                        <div className="single-person" key={index}>
                          <div className="single-person-img small-person-img">
                            <img src={item?.img} alt="scholify" />
                          </div>
                          <div className="single-person-body">
                            <h3 className="h5 h5-bold">{item?.name}</h3>
                            <h6 className="h6">{item.field}</h6>
                            <p>
                              <Mail
                                sx={{
                                  color: deepSky,
                                  fontSize: "12px",
                                }}
                              />
                              <span>{item?.email}</span>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* student list */}
              <div className="card-sm-style card-sm-height">
                <div className="title">
                  <h2 className="h4">Successful Scholars</h2>
                </div>
                <div className="card-sm-body professor-list overflowY">
                  {studentList?.length > 0 &&
                    studentList?.map((item, index) => {
                      return (
                        <div className="single-person" key={index}>
                          <div className="single-person-img">
                            <img src={item?.img} alt="scholify" />
                          </div>
                          <div className="single-person-body">
                            <h3 className="h5 h5-bold">{item?.name}</h3>
                            <h6 className="h6">{item.education}</h6>
                            <p>
                              <Mail
                                sx={{
                                  color: deepSky,
                                  fontSize: "12px",
                                }}
                              />
                              <span>{item?.email}</span>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* Institution Components */}
              <InstitutionComponents />
            </div>
          </div>
        </div>

        {/* Loader For Infinite Scroll*/}
        <div ref={loader} />
      </div>
    </>
  );
}
