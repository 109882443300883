import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../common/SectionTitle";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";
import {
  colorCodeList,
  midText,
  serviceStyle,
} from "../../utility/customColor";
import {
  gerFilterDistinctDataStat,
  serviceIconList,
  // serviceList,
  // studntReviewList,
  teamList,
} from "../home/helper";

// image
import pointsOne from "../../assets/images/about/01.png";
import pointsThree from "../../assets/images/about/03.png";
import pointsFour from "../../assets/images/about/05.png";
import pointsTwo from "../../assets/images/about/06.png";
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import heroShape from "../../assets/images/hero-shape.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
// import quotationIcon from "../../assets/images/icon/quotation.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
// import instagramImg from "../../assets/images/instagram.png";
import portalBg from "../../assets/images/institute4.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import directorTwo from "../../assets/images/person/03.png";
import directorOne from "../../assets/images/person/04.png";
import heroPersonOne from "../../assets/images/person/17.png";
// import heroPersonTwo from "../../assets/images/person/11.png";
// import reviewImg from "../../assets/images/review.png";
import footerRightShape from "../../assets/images/rightShape.png";
// import reviewShape from "../../assets/images/shape/shape03.png";
// import reviewShapeImg from "../../assets/images/shape/shape05.png";
import signetureOne from "../../assets/images/signeture1.png";
import signetureTwo from "../../assets/images/signeture2.png";
// import twitterImg from "../../assets/images/twitter.png";
// import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";

export default function About() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  const [serviceList, setServiceList] = useState([]);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  useEffect(() => {
    const cb = (data) => setServiceList((oldData) => [...oldData, data]);

    gerFilterDistinctDataStat(cb);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form>
        <div className="layout-scroll scrollbar-remove" ref={scrollRef}>
          {/* about hero section */}
          <div className="hero-section about-hero-section bgOne">
            <div className="hero-section-content">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="hero-section-body about-section-body">
                    <h2 className="h1 darkText">About Us</h2>
                    <p className="para2">
                      Our mission is offering the best opportunity to every
                      potential candidate who longs to study abroad so that the
                      light of education can shine all around the globe.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="hero-section-img-content">
                    <div className="hero-section-shape">
                      <img src={heroShape} alt="scholiguide" />
                    </div>
                    <div className="hero-section-person-img about-section-person-img">
                      <img src={heroPersonOne} alt="scholiguide" />
                      {/* <img
                        src={heroPersonTwo}
                        alt="scholiguide"
                        className="right-img"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* services section */}
          <div className="service-card-info res-app-width">
            <div className="card-info-body app-width">
              <ul className="service-card-list">
                {serviceList?.length > 0 && (
                  <>
                    {serviceList?.map((item, index) => {
                      return (
                        <li
                          className="single-service-list"
                          style={serviceStyle[index]}
                          key={index}
                        >
                          <div
                            className="single-service-img"
                            style={{
                              background: `${colorCodeList[index]}`,
                            }}
                          >
                            <img src={serviceIconList[index]} alt="scholify" />
                          </div>
                          <div className="single-service-body">
                            <h2>{item.title}</h2>
                            <p>{item.subtitle}</p>
                          </div>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
          {/* scholarship portal section */}
          <div className="scholarship-portal-section">
            <div className="scholarship-portal-section-content app-width">
              <SectionTitle title="Largest scholarship portal in South Asia" />
              <div className="card-info-body portal-info-body">
                <div className="card-list">
                  <div className="row">
                    <div className="col-12">
                      <div className="card-full-img">
                        <img src={portalBg} alt="scholify" />
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="card-info-txt about-card">
                            <h3 className="h3 h3-bold">How it all started</h3>
                            <p className="para3">
                              After completing their graduation degree from a
                              regional university the co-founders of Scholiguide
                              felt their utmost desire to obtain an overseas
                              degree yet sadly they were entrapped into a
                              labyrinth as soon as they started the procedure.
                              Having suffered from their own misfortune, the
                              idea strikes as a single online based platform
                              where students from any part of the world can get
                              the proper guidance and support to back their
                              dream up.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="card-info-txt">
                            <h3 className="h3 h3-bold">
                              How we intend to serve you
                            </h3>
                            {/* <p className="para3">
                              Embarking on a study abroad adventure opens doors to a world of transformative opportunities. It fosters personal growth, enhances your academic prowess, and equips you with invaluable life skills that set you apart in today's competitive world.
                            </p> */}
                            <div className="card-bullet-point">
                              <ul>
                                <li>
                                  <div className="bullet">
                                    <Circle
                                      sx={{
                                        fontSize: "8px",
                                        color: midText,
                                      }}
                                    />
                                  </div>
                                  <p className="para3">
                                    Providing step by step guidelines.
                                  </p>
                                </li>
                                <li>
                                  <div className="bullet">
                                    <Circle
                                      sx={{
                                        fontSize: "8px",
                                        color: midText,
                                      }}
                                    />
                                  </div>
                                  <p className="para3">
                                    Preparing you for IELTS.
                                  </p>
                                </li>
                                <li>
                                  <div className="bullet">
                                    <Circle
                                      sx={{
                                        fontSize: "8px",
                                        color: midText,
                                      }}
                                    />
                                  </div>
                                  <p className="para3">
                                    Taking care of your documents.
                                  </p>
                                </li>
                                <li>
                                  <div className="bullet">
                                    <Circle
                                      sx={{
                                        fontSize: "8px",
                                        color: midText,
                                      }}
                                    />
                                  </div>
                                  <p className="para3">Processing your visa.</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* core points section */}
          <div className="core-points-info bgOne">
            <div className="core-points-info-content app-width">
              <SectionTitle title="Why Should you choose Scholiguide" />
              <div className="card-info-body core-points-info-body">
                <div className="card-list">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="single-card-point-info">
                        <div className="card-full-img">
                          <img src={pointsOne} alt="scholify" />
                        </div>
                        <div className="card-info-txt core-points-txt">
                          <h2 className="h3 h3-bold sky">24/7 Service</h2>
                          <p className="para3">
                            One of our core strengths is we are always a click
                            away from you regardless of the time.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-card-point-info">
                        <div className="card-full-img">
                          <img src={pointsTwo} alt="scholify" />
                        </div>
                        <div className="card-info-txt core-points-txt">
                          <h2 className="h3 h3-bold orange">
                            1000+ candidates
                          </h2>
                          <p className="para3">
                            We have already served more than a thousand
                            successful candidates within a very short span of
                            time.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-card-point-info">
                        <div className="card-full-img">
                          <img src={pointsThree} alt="scholify" />
                        </div>
                        <div className="card-info-txt core-points-txt">
                          <h2 className="h3 h3-bold purple">
                            10,000+ Monthly hit
                          </h2>
                          <p className="para3">
                            Every month thousands of people visit our services
                            and a large portion of them become our clients.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-card-point-info">
                        <div className="card-full-img">
                          <img src={pointsFour} alt="scholify" />
                        </div>
                        <div className="card-info-txt core-points-txt">
                          <h2 className="h3 h3-bold green">150+ Country</h2>
                          <p className="para3">
                            We have no tie up with any particular country or
                            university which enables our student placement
                            opportunity to the maximum.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* team section */}
          <div className="team-section">
            <div className="team-section-content app-width">
              <SectionTitle title="Passionate Team Behind The Scholiguide" />
              <div className="card-info-body team-section-content-body">
                <div className="card-list grid-customize">
                  <>
                    <div className="row">
                      {teamList?.map((item, index) => {
                        return (
                          <div
                            className="col-xl-3 col-lg-4 col-sm-6"
                            key={index}
                          >
                            <div className="single-team-card">
                              <div
                                className={
                                  item?.isNoImg
                                    ? `single-team-card-img noImg`
                                    : `single-team-card-img`
                                }
                              >
                                <img src={item?.img} alt={"scholify"} />
                              </div>
                              <div className="single-team-card-txt">
                                <h3 className="h3 h3-bold">{item?.teamName}</h3>
                                <p className="para3">{item?.teamDesignation}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          {/* hm-student-review section */}
          {/* <div className="hm-student-review-section about-hm-review bgOne">
            <div className="hm-student-review-content app-width">
              <SectionTitle title="What Our Students Say" />
              <div className="hm-student-review-inner">
                <div className="hm-student-review-quotation">
                  <img src={quotationIcon} alt="scholiguide" />
                </div>
                <div className="row">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="hm-student-review-inner-txt-content">
                      <div
                        id="sReviewSlider"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {studntReviewList.map((itm, idx) => {
                            return (
                              <div
                                className={
                                  itm?.isActive
                                    ? "carousel-item active"
                                    : "carousel-item"
                                }
                                key={idx}
                              >
                                <div className="single-review">
                                  <div className="single-review-img">
                                    <img src={itm?.img} alt="scholiguide" />
                                  </div>
                                  <div className="single-review-comment">
                                    <h2>{itm?.title}</h2>
                                    <p>{itm?.comment}</p>
                                  </div>
                                  <div className="single-review-comment-author">
                                    <h3>{itm?.sName}</h3>
                                    <p>{itm?.university}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#sReviewSlider"
                          role="button"
                          data-slide="prev"
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#sReviewSlider"
                          role="button"
                          data-slide="next"
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1">
                    <div className="hm-student-review-inner-img">
                      <img src={reviewImg} alt="scholiguide" />
                      <div className="hm-student-review-inner-img-shape">
                        <img src={reviewShapeImg} alt="scholiguide" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hm-student-review-inner-shape">
                  <img src={reviewShape} alt="scholiguide" />
                </div>
              </div>
            </div>
          </div> */}
          {/* letter section */}
          <div className="letter-section">
            <div className="letter-section-content app-width">
              <div className="card-info-heading">
                <SectionTitle title="Letter From The Directors" />
              </div>
              <div className="card-info-body letter-info-body">
                <div className="card-list">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-letter-info">
                        <div className="card-full-img">
                          <img src={directorOne} alt="scholify" />
                        </div>
                        <div className="card-info-txt">
                          <p className="para3">
                            Scholiguide was born out of a vision, a vision that
                            recognizes the boundless potential within each
                            student, regardless of their background or
                            circumstances. Our belief is that education knows no
                            boundaries, and it is this belief that has propelled
                            us to create a platform that opens doors to global
                            education excellence.
                          </p>
                          <p className="para3">
                            As the Managing Director, I am humbled by the
                            dedication of our team – a team that tirelessly
                            works to simplify the scholarship search, provide
                            personalized guidance, and empower students to step
                            confidently onto the global stage.
                          </p>
                          <div className="letter-footer">
                            <p className="para3">Be well,</p>
                            <div className="letter-footer-signeture-img">
                              <img src={signetureOne} alt="scholify" />
                            </div>
                            <h3 className="para2 para2-bold">Mirza Sunny</h3>
                            <p className="para4">
                              Managing Director & Co-Founder
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-letter-info">
                        <div className="card-full-img">
                          <img src={directorTwo} alt="scholify" />
                        </div>
                        <div className="card-info-txt">
                          <p className="para3">
                            Scholiguide is not just an organization; it is a
                            vessel of aspirations, a conduit of opportunities,
                            and a catalyst for change. We believe that education
                            should be a passport to boundless horizons,
                            transcending geographical borders and financial
                            limitations. Our mission, our purpose, is to empower
                            students to pursue their academic dreams, no matter
                            where those dreams may lead.
                          </p>
                          <p className="para3">
                            I extend my heartfelt gratitude to our students,
                            whose courage and determination inspire us every
                            day. To our partners and supporters, your belief in
                            our mission strengthens our resolve. And to the
                            Scholiguide team.
                          </p>
                          <div className="letter-footer">
                            <p className="para3">Be well,</p>
                            <div className="letter-footer-signeture-img">
                              <img src={signetureTwo} alt="scholify" />
                            </div>
                            <h3 className="para2 para2-bold">Saikat Kumar</h3>
                            <p className="para4">
                              Creative Director & Co-Founder
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* contact with counselor section */}
          <div
            style={{ marginTop: "16px" }}
            className="connect-counselor-section app-width"
          >
            <div className="connect-counselor-inner-section">
              <h2 className="h2">
                Discover The Ideal Place For You With The Assist Of Our
                Counselor
              </h2>
              <div>
                <button
                  type="button"
                  style={{ fontSize: "15px !important" }}
                  className="btn btn-large btn-darkOrange"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/contact");
                  }}
                >
                  Connect With Counselor
                  <span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
          <div className="layout-body">
            <div className="row">
              <div className="col-12">
                {/* footer */}
                <div className="res-footer-inner d-xl-none d-block">
                  <div className="res-footer-menu">
                    <div className="res-footer-menu-img">
                      <Link to="/">
                        <img src={schoify} alt="scholify" />
                      </Link>
                    </div>
                    {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                    <ul className="res-footer-menu-list">
                      {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                      <li>
                        <Link to="/filter?degree=master">
                          Latest Scholarship
                        </Link>
                      </li>
                      <li>
                        <Link to="/sop">Get Your SOP</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="footer-social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.linkedin.com/company/scholiguide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="scholify" />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="footer-web-duration">
                    &copy; 2022 www.scholiguide.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
