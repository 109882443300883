import axios from "axios";
import { setLoginAction } from "../../../commonRedux/auth/action";
import { toast } from "react-toastify";

export const singupGoogleApi = async (objData, dispatch, setShow, setShowMenu) => {
  const { name, email, googleId, imageUrl } = objData;
  const payload = {
    name,
    email,
    googleId,
    imageUrl,
  };

  try {
    const res = await axios.post("/api/auth/signup", payload);

    if (res?.status === 200) {
      console.log("Singup message", res?.data);
      dispatch(setLoginAction(res?.data?.user));
      setShow(false);
      setShowMenu(false);
      toast.success(res?.data?.message);
    }
  } catch (err) {
    console.log("Error Signup google", err);
  }
};

export const loginApi = async (payload, dispatch, setShow, setLoading, setShowMenu) => {
  try {
    const res = await axios.post("/api/auth/signin", payload);

    if (res?.status === 200) {
      setShow(false);
      setShowMenu(false);
      dispatch(setLoginAction(res?.data?.user));
      setLoading(false);
      toast.success(res?.data?.message, { toastId: 123 });
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, { toastId: 1234 });
    setLoading(false);
  }
};

export const signupApi = async (payload, setLoading, cb) => {
  try {
    const res = await axios.post("/api/auth/signup", payload);

    if (res?.status === 200) {
      cb();
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, { toastId: 12345 });
    setLoading(false);
  }
};

export const resetPasswordApi = async (payload, setLoading, cb) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.post("/api/auth/send-reset-password-mail", payload);
    if (res?.status === 200) {
      setLoading && setLoading(false);
      cb();
    }
  } catch (err) {
    toast.warn(err?.response?.data?.message || err.message, {
      toastId: 1234225,
    });
    setLoading && setLoading(false);
  }
};
