export const sopStep = [
  { title: "Plan" },
  { title: "Introduction" },
  { title: "Formulate" },
  { title: "Conclude" },
];

export const sopStyle = [
  {
    background: `#FEF8F3`,
  },
  {
    background: `#F3F4FF`,
  },
  {
    background: `#F6EFFE`,
  },
  {
    background: `#E3FDFB`,
  },
];

export const sopCodeList = ["#FFBF84", "#8490FF", "#BA84FF", "#57AEA4"];
