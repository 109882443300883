import { LockOutlined } from "@mui/icons-material";
import { Backdrop, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/logos/logo.png";
import DefaultInput from "../../common/DefaultInput";
import { postChangedPasswordApi, validationSchemaResetPassword } from "./api";

export default function ResetPassword() {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const formikprops = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchemaResetPassword,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        token,
        password: values?.password,
      };

      postChangedPasswordApi(payload, setLoading, () => {
        resetForm();
        history.push("/");
      });
    },
  });

  const { values, touched, errors, setFieldValue, handleSubmit } = formikprops;

  const { search } = useLocation();

  useEffect(() => {
    const _t = search.split("?_rp=")[1];
    if (_t) {
      setToken(_t);
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="auth-wrapper-page flex-center">
        <div className="auth-login-wrapper p-5">
          <div className="logo-img">
            <img src={logo} alt="scholify" />
          </div>
          <div className="auth-login-body">
            <h2 className="auth-title">Login to Scholars!</h2>
            <p className="auth-subtitle">OR LOGIN WITH EMAIL</p>

            <div className="row">
              <div className="col-12">
                <div className="auth-label">
                  <label className="label">New Password: *</label>
                </div>
                <DefaultInput
                  classes="input-sm"
                  value={values?.password}
                  name="password"
                  type="password"
                  placeholder=" "
                  passwordicon={<LockOutlined sx={{ fontSize: "16px" }} />}
                  errors={errors}
                  touched={touched}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                />
              </div>

              <div className="col-12">
                <div className="auth-label">
                  <label className="label">Confirm Password: *</label>
                </div>
                <DefaultInput
                  classes="input-sm"
                  value={values?.confirmPassword}
                  name="confirmPassword"
                  type="password"
                  placeholder=" "
                  passwordicon={<LockOutlined sx={{ fontSize: "16px" }} />}
                  errors={errors}
                  touched={touched}
                  onChange={(e) =>
                    setFieldValue("confirmPassword", e.target.value)
                  }
                />
              </div>

              <div className="col-12 mt-3">
                <button
                  type="button"
                  className="btn btn-medium btn-sky w-100"
                  onClick={handleSubmit}
                >
                  Change Your Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
