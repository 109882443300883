import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function InstitutionComponents() {
  const { data: institutionList } = useQuery(["institude"], async () => {
    const { data } = await axios.get("/api/institution/getAll");
    return data;
  });

  return (
    <>
      <div className="card-sm-style card-sm-height">
        <div className="title">
          <h2 className="h4">Top Scholarship Providing Institution</h2>
        </div>
        <div className="card-sm-body professor-list overflowY">
          {institutionList?.data?.length > 0 &&
            institutionList?.data?.map((item, index) => {
              return (
                <div className="single-person" key={index}>
                  <div className="single-person-img">
                    <img
                      src={`https://scholify-schedule.herokuapp.com/static/${item?.strImage}`}
                      alt="scholify"
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </div>
                  <div className="single-person-body">
                    <h3 className="h5 h5-bold text-capitalize">
                      {item?.strInstitutionName}
                    </h3>
                    <h6 className="h6">
                      Graduation Rate {item?.intGraduationRate}%
                    </h6>
                    <p>
                      <a
                        className="link-small"
                        target="_blank"
                        href={`${item?.strWebsite}`}
                        rel="noreferrer"
                      >
                        Learn More
                      </a>
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
