import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import schoify from "../../assets/images/footer-logo.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import facebook from "../../assets/images/icon/facebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
import instagramImg from "../../assets/images/instagram.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
import twitterImg from "../../assets/images/twitter.png";
import whBg from "../../assets/images/wh-bg-two.png";
import whatsapp from "../../assets/images/whatsapp.png";
import youtubeImg from "../../assets/images/youtube.png";
import footerLogo from "../../assets/logos/logo.png";
import FormikSelect from "../../common/FormikSelect";
import {
  bodyColorTwo,
  instagramColor,
  twitterColor,
} from "../../utility/customColor";
import { customStyles } from "../../utility/selectCustomStyle";
import CourseView from "./courseView";
import Finding from "./finding";
import { singleCourseById } from "./helper";
import OverView from "./overView";
import Requirements from "./requirements";
// import ServiceView from "./serviceView";

export default function CourseDetails() {
  const params = useParams();
  const history = useHistory();
  const scrollRef = useRef();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [singleCourse, setSingleCourse] = useState({});

  const tabName = [
    { name: "Overview" },
    { name: "Course" },
    { name: "Funding" },
    { name: "Requirements" },
  ];

  const initData = {
    language: "",
  };

  useEffect(() => {
    if (params?.id) {
      singleCourseById(params?.id, setLoading, (data) => {
        setSingleCourse(data);
      });
    }
  }, [params]);

  const { values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      resetForm(initData);
    },
  });

  // // The scroll listener
  // const handleScroll = useCallback(() => {
  //   const scrollTop = scrollRef.current.scrollTop;
  //   dispatch(scrollHeightAction(scrollTop));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // // Attach the scroll listener to the div
  // useEffect(() => {
  //   const div = scrollRef.current;
  //   div.addEventListener("scroll", handleScroll);
  // }, [handleScroll]);

  console.log("singleCourse", singleCourse);

  return (
    <>
      {loading ? (
        <div className="text-center mt-4">
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : null}

      <form>
        <div
          className="layout-scroll scrollbar-remove"
          ref={scrollRef}
          style={{ background: bodyColorTwo }}
        >
          <div className="layout-body">
            <div className="search-section course-section">
              <div classNames="search-section-heading">
                <h3
                  className="go-back"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.goBack();
                  }}
                >
                  <span style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </span>
                  Go back
                </h3>
                <h2
                  className="h1 text-capitalize"
                  style={{ marginBottom: "10px!important" }}
                >
                  {singleCourse?.title}
                </h2>
              </div>
              <div className="couse-section-tab">
                <div className="course-tab-heading">
                  {tabName.map((item, i) => {
                    return (
                      <button
                        key={i}
                        type="button"
                        className={`btn btn-tab ${i === index ? "active" : "btn-default btn-courseTab"
                          }`}
                        onClick={() => setIndex(i)}
                      >
                        {item.name}
                      </button>
                    );
                  })}
                </div>
                <div className="course-tab-body">
                  <div className="row">
                    <div className="col-xl-9 col-12">
                      <div className="course-tab-inner-body">
                        <OverView
                          index={index}
                          tabIndex={0}
                          singleCourse={singleCourse}
                        />
                        <CourseView
                          index={index}
                          tabIndex={1}
                          singleCourse={singleCourse}
                        />
                        <Finding
                          index={index}
                          tabIndex={2}
                          singleCourse={singleCourse}
                        />
                        <Requirements
                          index={index}
                          tabIndex={3}
                          singleCourse={singleCourse}
                        />
                        {/* <ServiceView index={index} tabIndex={4} /> */}
                      </div>

                      {/* footer */}
                      <div className="res-footer-inner d-xl-none d-block">
                        <div className="res-footer-menu">
                          <div className="res-footer-menu-img">
                            <Link to="/">
                              <img src={schoify} alt="scholify" />
                            </Link>
                          </div>
                          <div
                            className="input-field-main"
                            style={{
                              margin: "0 auto 20px",
                              maxWidth: "178px",
                            }}
                          >
                            <FormikSelect
                              classes="input-sm"
                              styles={customStyles}
                              name="language"
                              options={[
                                { value: 1, label: "English" },
                                { value: 2, label: "Bangla" },
                              ]}
                              value={values?.language}
                              onChange={(valueOption) => {
                                setFieldValue("language", valueOption);
                              }}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <ul className="res-footer-menu-list">
                            <li>
                              <Link to="/">Full Funding Scholarship</Link>
                            </li>
                            <li>
                              <Link to="/">Latest Scholarship</Link>
                            </li>
                            <li>
                              <Link to="/">Get Your SOP</Link>
                            </li>
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <Link to="/blog">Blog</Link>
                            </li>
                            <li>
                              <Link to="/">Contact Us</Link>
                            </li>
                          </ul>
                        </div>
                        <ul className="footer-social-list">
                          <li>
                            <Link to="/">
                              <img src={facebook} alt="scholify" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <img src={instagramImg} alt="scholify" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <img src={twitterImg} alt="scholify" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <img src={linkedinImg} alt="scholify" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <img src={youtubeImg} alt="scholify" />
                            </Link>
                          </li>
                        </ul>
                        <div className="footer-web-duration">
                          &copy; 2022 www.scholiguide.com
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 d-xl-block d-none">
                      {/* contact-details */}
                      <div className="card-sm-style">
                        <div className="contact-info-heading">
                          <h2 className="h3 h3-bold">Contact Details</h2>
                        </div>
                        <div className="contact-info-body">
                          <ul>
                            <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <h3 className="h4 h4-bold uniname text-capitalize">
                                {singleCourse?.university}
                              </h3>
                            </li>
                            <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <h4 className="para3 text-capitalize">
                                <span>Address: </span>
                                {singleCourse?.others?.fullLocation}
                              </h4>
                            </li>
                            {/* <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <h4 className="h4 h4-bold">
                                <span>Prof Dr Martin Gaedke</span>
                              </h4>
                            </li> */}
                            {/* <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <h4 className="para3">
                                <span>tel:</span> +49 37153125550
                              </h4>
                            </li> */}
                            <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <a
                                href={(() => {
                                  const gmailId = "scholiguide24@gmail.com"; // Replace with the desired Gmail ID
                                  const subject = "";
                                  const body = "";

                                  const mailtoUrl = `mailto:${gmailId}?subject=${encodeURIComponent(
                                    subject
                                  )}&body=${encodeURIComponent(body)}`;

                                  return mailtoUrl;
                                })()}
                                className="link-big"
                              >
                                <span>Email</span>
                              </a>
                            </li>
                            <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <a
                                href={singleCourse?.others?.applyLink || ""}
                                className="link-big"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>Course Website</span>
                              </a>
                            </li>
                            <li>
                              <div className="bullet">
                                <Circle
                                  sx={{
                                    fontSize: "16px",
                                    color: "#EAEAEA",
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="single-course-icon">
                                  <Link to="/">
                                    <img src={facebook} alt="scholify" />
                                  </Link>
                                </div>
                                <div className="single-course-icon">
                                  <Link to="/">
                                    {" "}
                                    <Twitter
                                      sx={{
                                        fontSize: "24px",
                                        color: twitterColor,
                                      }}
                                    />{" "}
                                  </Link>
                                </div>
                                <div className="single-course-icon">
                                  <Link to="/">
                                    {" "}
                                    <Instagram
                                      sx={{
                                        fontSize: "24px",
                                        color: instagramColor,
                                      }}
                                    />{" "}
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* whatsapp  */}
                      <div className="whats-app-section">
                        <div className="whatsapp-bg">
                          <img src={whBg} alt="scholify" />
                        </div>
                        <div className="whatspp-body">
                          <h2 className="h3 h3-bold">
                            Join Our Whatsapp Channel
                          </h2>
                          <p>
                            <img src={whatsapp} alt="scholify" />
                            <span>01682 17 5983</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* desktop footer */}
          <div className="footer-section bgOne d-xl-block d-none">
            <div className="footer-top app-width">
              <div className="row footer-logo-content align-items-center">
                <div className="col-lg-8">
                  <div className="footer-logo">
                    <img src={footerLogo} alt="scholify" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-social-network">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src={faFacebook} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Twitter sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <YouTube sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src={faSnapchat} alt="scholify" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <Instagram sx={{ fontSize: "20px" }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <div className="footer-menu-left row">
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Degrees</h3>
                    <ul>
                      <li>
                        <Link to="/filter?degree=phd" className="h5">
                          Phd
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=master" className="h5">
                          Masters
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?degree=bachelor" className="h5">
                          Bachelors
                        </Link>
                      </li>
                      <li>
                        <Link to="/filter?attendance=online" className="h5">
                          Online
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <h3 className="h4 footer-menu-title">Pages</h3>
                    <ul>
                      <li>
                        <Link to="/" className="h5">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="h5">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" className="h5">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="h5">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Take Help</h3>
                    <ul>
                      <li>
                        <Link to="/sop" className="h5">
                          Get Your SOP
                        </Link>
                      </li>
                      <li>
                        <Link to="/ielts" className="h5">
                          IELTS
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply" className="h5">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="h5">
                          Faq
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="h4 footer-menu-title">Contact Us</h3>
                    <ul>
                      <li>
                        <p className="h5">Palbari, Jashore</p>
                      </li>
                      <li>
                        <p className="h5">sunnymirza13@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">saikatkr034@gmail.com</p>
                      </li>
                      <li>
                        <p className="h5">+8801682175983</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fotter-divider"></div>
            <div className="footer-bottom app-width">
              <p>
                &copy; 2022 All rights reserved by scholiguide.com
                {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
              </p>
              {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
            </div>
            <div className="fotter-left-shape">
              <img src={footerLeftShape} alt="scholify" />
            </div>
            <div className="fotter-right-shape">
              <img src={footerRightShape} alt="scholify" />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
