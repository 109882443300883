import axios from "axios";

export const searchData = [
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "FULL FUNDING SCHOLARSHIP",
    isFullFunding: true,
    url: "/",
  },
  {
    title: "Masters in Web Engineering",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
  {
    title:
      "Master in Business Administration 80% OFF your Tuition fee - Scholarship",
    university: "Oxford University",
    language: "English",
    country: "United Kingdom",
    languageTest: "IELTS 7 (Not less than 6.5)",
    courseFee: "EUR 720",
    date: "August 03 - August 26, 2022 (Registration Deadline June 15, 2022)",
    remarks: "50% SCHOLARSHIP",
    isFullFunding: false,
    url: "/",
  },
];

export const getAddToMyListApi = async (payload, setter, setLoading) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.post(
      "/api/scholarship/scholarshipListing",
      payload
    );

    if (res?.status === 200) {
      setLoading && setLoading(false);
      setter(res?.data?.data);
    }
  } catch (err) {
    setLoading && setLoading(false);
  }
};
