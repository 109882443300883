import {
  faArrowRightLong,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import FilterSearch from "../../../common/FilterSearch";
import "../../../assets/css/autoSugession.css";
import { useEffect, useState } from "react";
import { gerFilterDistinctData } from "./api";
import {
  filterSearchAction,
  setLocationPathAction,
} from "../../../commonRedux/reduxForLocalStorage/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const initData = {
  search: "",
  language: "",
  porgram: "",
  fName: "",
  lName: "",
  phone: "",
  email: "",
};

export default function AutoSugession() {
  const [allDistinctData, setAllDistinctData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  useEffect(() => {
    const updateProgress = (data) =>
      setAllDistinctData((old) => [...old, ...data]);

    gerFilterDistinctData(updateProgress);
  }, []);

  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      resetForm(initData);
    },
  });

  return (
    <>
      <div className="subscribe-from">
        <div className="auto-sugession-wrapper">
          <FilterSearch
            classes="hero-search-form"
            placeholder={"Enter keyword"}
            searchFontSize="18px"
            value={values?.search}
            setValue={(e) => {
              const filter = allDistinctData?.filter((item) =>
                item?.name?.includes(e.target.value?.toLowerCase())
              );
              setSearchResult(filter);
              setFieldValue("search", e.target.value?.toLowerCase());
            }}
            cancelHandler={() => {
              setSelectedIndex(0);
              setFieldValue("search", "");
            }}
            isSearchIcon={false}
            onKeyDown={(e) => {
              if (e.target.value === "") {
                setSelectedIndex(0);
              }
              setFieldValue("search", e.target.value);

              if (e.keyCode === 13) {
                e.preventDefault();
                dispatch(
                  filterSearchAction({
                    ...filterSearch,
                    search:
                      selectedIndex > 0
                        ? searchResult[selectedIndex]?.name
                        : e.target.value,
                  })
                );
                dispatch(setLocationPathAction("/search"));
                history.push("/search");
              }

              /* Down Key */
              if (e.keyCode === 40) {
                if (searchResult?.length - 1 > selectedIndex) {
                  setSelectedIndex((old) => old + 1);
                  setFieldValue(
                    "search",
                    searchResult[selectedIndex + 1]?.name
                  );
                }
              }

              /* Up Key */
              if (e.keyCode === 38) {
                if (selectedIndex > 0) {
                  setSelectedIndex((old) => old - 1);
                  setFieldValue(
                    "search",
                    searchResult[selectedIndex - 1]?.name
                  );
                }
              }
            }}
          />

          {values?.search?.trim()?.length > 0 ? (
            <>
              <div class="auto-sugession-bar">
                {searchResult?.map((item, index) => (
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(
                        filterSearchAction({
                          ...filterSearch,
                          search: item?.name,
                        })
                      );
                      dispatch(setLocationPathAction("/search"));
                      history.push("/search");
                    }}
                    key={index}
                    className={`result-div ${
                      selectedIndex === index ? "result-div-hover" : ""
                    }`}
                  >
                    <p className="text-capitalize">{item?.name}</p>
                    <span className={`pl-2 text-capitalize `}>
                      {item?.category}
                    </span>
                  </button>
                ))}
              </div>
            </>
          ) : null}
        </div>

        <button
          type="button"
          disabled={!values?.search}
          className="btn btn-large btn-orange hm-hero-btn"
          onClick={(e) => {
            dispatch(
              filterSearchAction({
                ...filterSearch,
                search: values?.search,
              })
            );
            history.push("/search");
          }}
        >
          <span className="text">Search Now</span>
          <span className="arrow-icon">
            <FontAwesomeIcon icon={faArrowRightLong} />
          </span>
          <span className="search-icon">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
        </button>
      </div>
    </>
  );
}
