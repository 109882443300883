import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Instagram, Twitter, YouTube } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../common/SectionTitle";
import { scrollHeightAction } from "../../commonRedux/reduxForLocalStorage/action";
import { midText } from "../../utility/customColor";
import { sopCodeList, sopStep, sopStyle } from "./helper";

// images
import facebookImg from "../../assets/images/facebook.png";
import schoify from "../../assets/images/footer-logo.png";
import heroShape from "../../assets/images/hero-shape.png";
import faFacebook from "../../assets/images/icon/faFacebook.png";
import faSnapchat from "../../assets/images/icon/snapchat.png";
import footerLeftShape from "../../assets/images/leftShape.png";
import linkedinImg from "../../assets/images/linkedin.png";
import footerRightShape from "../../assets/images/rightShape.png";
import purposeSOP from "../../assets/images/sop/01.png";
import aboutSOP from "../../assets/images/sop/02.png";
import sopPerson from "../../assets/images/sop/03.png";
import sopLogo from "../../assets/images/sop/04.png";
import footerLogo from "../../assets/logos/logo.png";

export default function Sop() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const history = useHistory();

  const { filterSearch } = useSelector((state) => {
    return state.localStorage;
  }, shallowEqual);

  // The scroll listener
  const handleScroll = useCallback(() => {
    const scrollTop = scrollRef.current.scrollTop;
    dispatch(scrollHeightAction(scrollTop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = scrollRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    console.log("filterSearch", filterSearch);
  }, [filterSearch]);

  return (
    <>
      <div
        className="layout-body layout-scroll scrollbar-remove"
        ref={scrollRef}
      >
        {/* sop hero section */}
        <div className="hero-section about-hero-section bgOne">
          <div className="hero-section-content">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="hero-section-body about-section-body">
                  <h2 className="h1 darkText">SOP</h2>
                  <p className="para2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Convallis adipiscing consectetur ornare.
                  </p>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <div className="hero-section-img-content">
                  <div className="hero-section-scholership-img">
                    <img src={sopLogo} alt="scholiguide" />
                  </div>
                  <div className="hero-section-shape">
                    <img src={heroShape} alt="scholiguide" />
                  </div>
                  <div className="hero-section-person-img">
                    <img src={sopPerson} alt="scholiguide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* scholarship portal section */}
        <div className="scholarship-portal-section">
          <div className="scholarship-portal-section-content app-width">
            <SectionTitle title="What is a Statement of Purpose (SOP)?" />
            <div className="card-info-body portal-info-body">
              <div className="card-list">
                <div className="row">
                  <div className="col-12">
                    <div className="card-full-img">
                      <img src={purposeSOP} alt="scholiguide" />
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="card-info-txt">
                          <h3 className="h3 h3-bold">Main Thing</h3>
                          <p className="para3">
                            A Statement of Purpose can be referred to as an
                            informative document, containing personal
                            statements, and is essentially required as part of
                            the admission procedure of study abroad programs.
                            Also referred to as an application essay, it
                            comprises the basic details of a candidate along
                            with their professional and personal interests,
                            academic highlights as well as future aspirations.
                          </p>
                          <p className="para3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Convallis adipiscing consectetur ornare
                            pellentesque amet, consectetur diam quis.
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="card-info-txt">
                          <h3 className="h3 h3-bold">Why is SOP important?</h3>
                          <p className="para3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Convallis adipiscing consectetur ornare
                            pellentesque amet.
                          </p>
                          <div className="card-bullet-point">
                            <ul>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Trello, and even Powerpoint
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Trello, and even Powerpoint
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Trello, and even Powerpoint
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Trello, and even Powerpoint
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Trello, and even Powerpoint
                                </p>
                              </li>
                              <li>
                                <div className="bullet">
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: midText,
                                    }}
                                  />
                                </div>
                                <p className="para3">
                                  Trello, and even Powerpoint
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* hm-about-section */}
        <div className="hm-about-section sop-about-section bgOne">
          <div className="hm-about-content app-width">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="timeline-scholership-txt section-text">
                  <h2 className="title">How to Write a SOP?</h2>
                  <h3 className="sub-title">Ponder</h3>
                  <p className="para hm-about-para">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled galley of type and scrambled
                  </p>
                  <h3 className="sub-title">Formulate</h3>
                  <p className="para">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled galley of type and scrambled
                  </p>
                  <h3 className="sub-title">Revise and Modify</h3>
                  <p className="para">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled galley of type and scrambled
                  </p>
                  <div>
                    <div className="d-flex align-items-center">
                      <button type="button" className="btn btn-mid btn-green">
                        Get Help TO Write Your SOP
                        <span>
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="hm-about-content-img">
                  <img src={aboutSOP} alt="scholiguide" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* hm-feature section */}
        <div className="hm-feature-section">
          <div className="hm-feature-content app-width">
            <SectionTitle title="4 steps to Write A SOP" />
            <ul className="service-card-list sop-card-list">
              {sopStep?.length > 0 && (
                <>
                  {sopStep?.map((item, index) => {
                    return (
                      <li
                        className="single-service-list single-sop-list"
                        style={sopStyle[index]}
                        key={index}
                      >
                        <div
                          className="single-service-img single-sop-img"
                          style={{
                            background: `${sopCodeList[index]}`,
                          }}
                        >
                          <h3>{index + 1}</h3>
                        </div>
                        <div className="single-service-body single-sop-body">
                          <h2>{item.title}</h2>
                        </div>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        </div>
        {/* tips-list section */}
        <div className="scholars-list-section bgOne tips-list-section">
          <div className="scholars-list-section-content app-width">
            <SectionTitle title="Our Successful Scholars" />
            <div className="scholars-list">
              <div className="row">
                <div className="col-lg-6">
                  {[1, 2, 3, 4, 5].map((itm, index) => {
                    return (
                      <div key={index} className="single-tips">
                        <h2>Tips {index + 1}</h2>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type.
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className="col-lg-6">
                  {[1, 2, 3, 4, 5].map((itm, index) => {
                    return (
                      <div key={index} className="single-tips">
                        <h2>Tips {index + 6}</h2>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type.
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* faq-card-section */}
        <div className="faq-card-section">
          <div className="faq-card-section-content app-width">
            <div className="card-info-heading">
              <SectionTitle title="Frequently Asked Questions." />
            </div>
            <div className="card-info-body">
              <ul className="faq-list">
                <li>
                  <h2 className="h4">
                    Question: How can I get immigration approval for Vietnam?
                  </h2>
                  <p>
                    Answer: Scholiguide provides complete visa processing
                    service for Vietnam visa including immigration approval.
                  </p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: How can I process the New Zealand visa?
                  </h2>
                  <p>
                    Answer: New Zealand visas can be processed either by lodging
                    paper-based application or by applying online. In both
                    cases, original passports need to be sent in India for
                    verification. Scholiguide provides complete visa processing
                    service for New Zealand including passport verification
                    service.
                  </p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: Is a visa interview mandatory for Brazil visa?
                  </h2>
                  <p>
                    Answer: Requirement of visa interview entirely depends on
                    the discretion of the consular office. However, in most
                    cases, Brazil Embassy in New Delhi calls visa applicants for
                    a visa interview.
                  </p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: What kind of support we provide for Mozambique?
                  </h2>
                  <p>
                    Answer: Mozambique provides an on-arrival visa facility for
                    Bangladeshis. Detailed information can be found on our
                    website-
                    <Link to="/">www.scholiguide.com</Link>
                  </p>
                </li>
                <li>
                  <h2 className="h4">
                    Question: What is the Yellow Fever Vaccine?
                  </h2>
                  <p>
                    Answer: Yellow Fever Vaccine is a mandatory pre-requisite if
                    someone wants to travel to the African or the North American
                    regions that fall under the Yellow Fever affected zones. One
                    must take the vaccine from a nearby hospital and hold the
                    original vaccination card during the travel period.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* contact with counselor section */}
        <div
          style={{ marginTop: "16px" }}
          className="connect-counselor-section app-width"
        >
          <div className="connect-counselor-inner-section">
            <h2 className="h2">
              Discover The Ideal Place For You With The Assist Of Our Counselor
            </h2>
            <div>
              <button
                type="button"
                className="btn btn-large btn-darkOrange"
                style={{ fontSize: "15px !important" }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/contact");
                }}
              >
                Connect With Counselor
                <span>
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* desktop footer */}
        <div className="footer-section bgOne d-xl-block d-none">
          <div className="footer-top app-width">
            <div className="row footer-logo-content align-items-center">
              <div className="col-lg-8">
                <div className="footer-logo">
                  <img src={footerLogo} alt="scholify" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-social-network">
                  <ul>
                    <li>
                      <Link to="/">
                        <img src={faFacebook} alt="scholify" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <Twitter sx={{ fontSize: "20px" }} />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <YouTube sx={{ fontSize: "20px" }} />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src={faSnapchat} alt="scholify" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <Instagram sx={{ fontSize: "20px" }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-menu">
              <div className="footer-menu-left row">
                <div className="col-lg-3">
                  <h3 className="h4 footer-menu-title">Degrees</h3>
                  <ul>
                    <li>
                      <Link to="/filter?degree=phd" className="h5">
                        Phd
                      </Link>
                    </li>
                    <li>
                      <Link to="/filter?degree=master" className="h5">
                        Masters
                      </Link>
                    </li>
                    <li>
                      <Link to="/filter?degree=bachelor" className="h5">
                        Bachelors
                      </Link>
                    </li>
                    <li>
                      <Link to="/filter?attendance=online" className="h5">
                        Online
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2">
                  <h3 className="h4 footer-menu-title">Pages</h3>
                  <ul>
                    <li>
                      <Link to="/" className="h5">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" className="h5">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog" className="h5">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="h5">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h3 className="h4 footer-menu-title">Take Help</h3>
                  <ul>
                    <li>
                      <Link to="/sop" className="h5">
                        Get Your SOP
                      </Link>
                    </li>
                    <li>
                      <Link to="/ielts" className="h5">
                        IELTS
                      </Link>
                    </li>
                    <li>
                      <Link to="/apply" className="h5">
                        Apply
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="h5">
                        Faq
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h3 className="h4 footer-menu-title">Contact Us</h3>
                  <ul>
                    <li>
                      <p className="h5">Palbari, Jashore</p>
                    </li>
                    <li>
                      <p className="h5">sunnymirza13@gmail.com</p>
                    </li>
                    <li>
                      <p className="h5">saikatkr034@gmail.com</p>
                    </li>
                    <li>
                      <p className="h5">+8801682175983</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="fotter-divider"></div>
          <div className="footer-bottom app-width">
            <p>
              &copy; 2022 All rights reserved by scholiguide.com
              {/* <span style={{ margin: "0 10px 0" }}>
                  <FiberManualRecord sx={{ fontSize: "10px" }} />
                </span> */}
            </p>
            {/* <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul> */}
          </div>
          <div className="fotter-left-shape">
            <img src={footerLeftShape} alt="scholify" />
          </div>
          <div className="fotter-right-shape">
            <img src={footerRightShape} alt="scholify" />
          </div>
        </div>
        <div className="layout-body">
          <div className="row">
            <div className="col-12">
              {/* footer */}
              <div className="res-footer-inner d-xl-none d-block">
                <div className="res-footer-menu">
                  <div className="res-footer-menu-img">
                    <Link to="/">
                      <img src={schoify} alt="scholify" />
                    </Link>
                  </div>
                  {/* <div
                      className="input-field-main"
                      style={{
                        margin: "0 auto 20px",
                        maxWidth: "178px",
                      }}
                    >
                      <FormikSelect
                        classes="input-sm"
                        styles={customStyles}
                        name="language"
                        options={[
                          { value: 1, label: "English" },
                          { value: 2, label: "Bangla" },
                        ]}
                        value={values?.language}
                        onChange={(valueOption) => {
                          setFieldValue("language", valueOption);
                        }}
                      />
                    </div> */}
                  <ul className="res-footer-menu-list">
                    {/* <li>
                        <Link to="/">Full Funding Scholarship</Link>
                      </li> */}
                    <li>
                      <Link to="/filter?degree=master">Latest Scholarship</Link>
                    </li>
                    <li>
                      <Link to="/sop">Get Your SOP</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <ul className="footer-social-list">
                  <li>
                    <a
                      href="https://www.facebook.com/people/Scholiguide/100095339405001/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebookImg} alt="scholify" />
                    </a>
                  </li>
                  {/* <li>
                      <Link to="/">
                        <img src={instagramImg} alt="scholify" />
                      </Link>
                    </li> */}
                  {/* <li>
                      <Link to="/">
                        <img src={twitterImg} alt="scholify" />
                      </Link>
                    </li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/scholiguide/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedinImg} alt="scholify" />
                    </a>
                  </li>
                  {/* <li>
                      <Link to="/">
                        <img src={youtubeImg} alt="scholify" />
                      </Link>
                    </li> */}
                </ul>
                <div className="footer-web-duration">
                  &copy; 2022 www.scholiguide.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
