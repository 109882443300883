import React from "react";
import { Link } from "react-router-dom";

export default function Finding({ index, tabIndex, singleCourse }) {
  const openTab = () => {
    window.open(singleCourse?.others?.applyLink);
  };

  return (
    <>
      {index === tabIndex && (
        <div className="single-search-item-body">
          <div className="row single-search-item-inner-body">
            <div className="col-md-6 single-search-item-inner-body-left">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Tuition fees per semester</h3>
                <p>{singleCourse?.others?.tutionValue}</p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Costs of living</h3>
                <p>{singleCourse?.others?.livingCost}</p>
              </div>
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Application deadline</h3>
                <p>{singleCourse?.others?.applyDate}</p>
              </div>
              {/* <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">
                  Funding opportunities within the university
                </h3>
                <p>Yes</p>
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="single-search-item-feature">
                <h3 className="h4 h4-bold">Officical Website Link</h3>
                <p>
                  <Link onClick={openTab}>
                    {singleCourse?.others?.applyLink}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
