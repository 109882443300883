import styled from "@emotion/styled";
import { CheckCircle } from "@mui/icons-material";
import { Box, CircularProgress, Tooltip, tooltipClasses } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { midText } from "../../utility/customColor";
import { getTimeLineData } from "./api";

export function TimeLineComponent() {
  const { data: timelineData, isLoading } = useQuery({
    queryKey: ["timelineData"],
    queryFn: getTimeLineData,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 1000 * 60 * 60 * 24,
  });

  // timeline
  let totalCell = 4;
  const emptyCell = (d, status) => {
    let dArray = [];
    const restDay = totalCell - d;
    for (let i = 1; i <= (status === "f" ? d - 1 : restDay); i++) {
      dArray.push(i);
    }
    return dArray;
  };

  // tooltip
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff !important",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: midText,
      boxShadow: "0px 19px 44px rgba(75, 102, 133, 0.25);",
      fontSize: 12,
    },
  }));

  return (
    <>
      {/* time-line */}
      <div className="card-sm-style card-sm-height">
        <div className="title">
          <h2 className="h4">Top Country Timeline</h2>
        </div>
        <div className="card-sm-body professor-list overflowY">
          <div className="timeline-wrapper">
            <div className="timeline-row">
              <div style={{ width: "55px" }}></div>
              {["Summer", "Spring", "Winter", "Fall"].map((item, index) => (
                <div key={index} className="single-cell">
                  {item}
                </div>
              ))}
            </div>

            {isLoading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : null}

            {timelineData?.map((item, index) => (
              <div className="timeline-row" key={index}>
                <div
                  style={{
                    width: "60px",
                    padding: "10px 5px",
                    marginRight: "0",
                    wordBreak: "break-all",
                    textTransform: "capitalize",
                  }}
                >
                  <p className="title">{item?.name}</p>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      zIndex: 1,
                      backgroundColor: `${item?.color}`,
                      opacity: "0.5",
                      height: "100%",
                      top: 0,
                      left: 0,
                      borderRadius: "50px",
                      width: "94%",
                    },
                  }}
                >
                  {emptyCell(item?.start, "f")?.map((item, index) => (
                    <p key={index} className="empty-cell" />
                  ))}
                  <div
                    style={{
                      position: "relative",
                      right: item?.end === 4 ? "14px" : "0px",
                      width: `66px`,
                      backgroundColor: `${item?.color}`,
                      height: "22px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50px",
                      zIndex: "2",
                    }}
                  >
                    <LightTooltip
                      title={
                        <div
                          style={{
                            padding: "10px 15px",
                          }}
                        >
                          <p
                            style={{
                              padding: "5px 20px",
                              background: `${item?.tooltipColor}`,
                              borderRadius: "3px",
                              marginBottom: "5px",
                            }}
                          >
                            {item?.session}
                          </p>
                          <p
                            style={{
                              maxWidth: "60px",
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontWeight: 500,
                              color: midText,
                              margin: "0 auto",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              {item?.remainingDay} Days Left
                            </span>
                          </p>
                        </div>
                      }
                      arrow
                    >
                      <CheckCircle
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                        }}
                      />
                    </LightTooltip>
                  </div>
                  {emptyCell(item?.end, "b")?.map((item, i) => (
                    <p key={i} className="empty-cell"></p>
                  ))}
                </Box>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
