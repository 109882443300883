import axios from "axios";
import {
  setMyNotifications,
  setNotificationsCountAction,
} from "../commonRedux/reduxForLocalStorage/action";

export const getNotificationData = (profileData, dispatch) => {
  getAllNotifications(
    {
      type: "get",
      userId: profileData?.id,
      skip: 0,
      limit: 100,
    },
    (data) => {
      let obj = {};
      for (let i of data) {
        if (!obj[i?.scholarshipId?._id]) {
          obj[i?.scholarshipId?._id] = true;
        }
      }
      dispatch(setMyNotifications(obj));
    }
  );

  getAllNotificationsCountAndNotification(
    {
      type: "count",
      userId: profileData?.id,
    },
    (data) => {
      dispatch(setNotificationsCountAction(data));
    }
  );
};

export const getAllNotifications = async (payload, setter, setLoading) => {
  setLoading && setLoading(payload?.scholarshipId);
  try {
    const res = await axios.post(
      "/api/notification/generatedNotificationListing",
      payload
    );

    if (res?.status === 200) {
      setLoading && setLoading(false);
      setter(res?.data?.data);
    }
  } catch (err) {
    setLoading && setLoading(false);
  }
};

export const getAllNotificationsCountAndNotification = async (
  payload,
  setter,
  setLoading
) => {
  setLoading && setLoading(true);
  try {
    const res = await axios.post(
      "/api/notification/notificationListing",
      payload
    );

    if (res?.status === 200) {
      setLoading && setLoading(false);
      setter(res?.data?.data || 0);
    }
  } catch (err) {
    setLoading && setLoading(false);
    setter(0);
  }
};
